import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import './CarrierSettings.scss';
import { useEffect, useState } from 'react';
import client from "../../utils/ApiClient";
import './CarrierSettings.scss';
import Scrollbars from 'rc-scrollbars';
import { GetCommonBenefitsForInsuranceCarrier, formatUtcDate } from '../../Constants/AppConstants';

// Carrier settings.
export const CarrierSettings = (props: any) => {
    let formattedEffectiveDate = props.activeInsuranceCarrier.effectiveFromDate ? props.activeInsuranceCarrier.effectiveFromDate && formatUtcDate(props.activeInsuranceCarrier.effectiveFromDate,true) :'';
    let formattedExpirationDate = props.activeInsuranceCarrier.effectiveToDate ? props.activeInsuranceCarrier.effectiveToDate && formatUtcDate(props.activeInsuranceCarrier.effectiveToDate,true):'';
    // State variable for loader.
    const [loader, setLoader] = useState<boolean>(true);

    // State variable for plan level benefits.
    const [carrierLevelBenefits, setCarrierLevelBenefits] = useState();

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            setLoader(true);
            const getCommonBenefitsUrl = `${GetCommonBenefitsForInsuranceCarrier}`;
            client.get(getCommonBenefitsUrl)
                .then((response: any) => {
                    if (response?.data && response?.data?.length > 0) {
                        const groupedDataForCarrierAndBenefitLevel = response?.data?.reduce((groups: any, item: any) => {
                            const key = item?.categoryType;
                            if (key != null) {
                                if (!groups[key]) {
                                    groups[key] = [];
                                }
                                groups[key].push(item);
                            }
                            return groups;
                        }, {});

                        setCarrierLevelBenefits(groupedDataForCarrierAndBenefitLevel);
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                    console.log(error);
                });

            isMounted = false;
        }

        return () => {
            isMounted = false;
        };
    }, []);


    return (
        <div className={`carriersettings-wrapper ${loader ? 'loading-skeleton' : ''}`}>
            <div className="carier-section">
                <Row>
                    <Col xs={12} lg={6}>
                        <div className="carrier-details">
                            <div className="carrier-setion">
                                <h5>Carrier Details</h5>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <FloatingLabel controlId="floatingInput" label="Carrier Name" className="required">
                                            <Form.Control type="text" placeholder=" " value={props.activeInsuranceCarrier?.insuranceCarrierName} readOnly />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <FloatingLabel controlId="floatingInput" label="Benefit Year" className="required">
                                            <Form.Control type="text" placeholder=" " value={props.activeInsuranceCarrier?.benefitYear} readOnly />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </div>
                            <div className="carrier-contact-setion">
                                <h5>Carrier Contract Details (Optional)</h5>
                                <Row>
                                    <Col xs={12} md={6}>
                                        {/* <DatePicker  />*/}
                                        <FloatingLabel controlId="floatingInput" label="Contract Start Date" className="required">
                                            <Form.Control type="text" placeholder=" " value={formattedEffectiveDate} readOnly />
                                            {/*<Image src={DatePickerIcon} className="form-field-icon" />*/}
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatingLabel controlId="floatingInput" label="Contract End Date" className="required">
                                            <Form.Control type="text" placeholder=" " value={formattedExpirationDate} readOnly />
                                            {/*<Image src={DatePickerIcon} className="form-field-icon" />*/}
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatingLabel controlId="floatingInput" label="Contact First Name">
                                            <Form.Control type="text" placeholder=" " readOnly />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatingLabel controlId="floatingInput" label="Contact Last Name">
                                            <Form.Control type="text" placeholder=" " readOnly />
                                        </FloatingLabel>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <FloatingLabel controlId="floatingInput" label="Email Address">
                                            <Form.Control type="email" placeholder=" " readOnly />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FloatingLabel controlId="floatingInput" label="Phone Number">
                                            <Form.Control type="text" placeholder=" " readOnly />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 5, offset: 1 }} xxl={{ span: 4, offset: 1 }}>
                        <div className="d-none choose-benefit">
                            <h5 className="m-0">Choose Benefits</h5>
                            <p className="m-0">Please choose benefits applicable to this carrier.</p>
                        </div>
                        <div className="d-none choose-benefit flex-benefit">
                            <Scrollbars
                                autoHide
                                autoHideTimeout={100}
                                autoHideDuration={200}
                                autoHeight
                                autoHeightMin={100}
                                autoHeightMax={600}>
                                {(Object.keys(carrierLevelBenefits || []))?.map((key: any, index: number) => (
                                    <>
                                        <h5 className="mt-4 mb-0">{key}</h5>
                                        <Row className="align-items-stretch g-3">
                                            {((carrierLevelBenefits && carrierLevelBenefits[key]) || [])?.map((x: any, index: any) => (
                                                <Col xs={6} key={x?.benefitType}>
                                                    <Button className="btn-outline"><Form.Check aria-label="option 1" className="form-check m-0" />
                                                        <span className="button-text text-truncate" title={x?.benefitName}>{x?.benefitName}</span>
                                                    </Button>
                                                </Col>
                                            ))}
                                        </Row>

                                    </>
                                ))}
                            </Scrollbars>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="footer-button d-flex mt-auto sticky d-none">
                <Button className="btn-secondary disabled">Add Carrier</Button>
            </div>
        </div>
    )
}