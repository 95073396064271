// Sets the key to the local storage.
export const SetKeyToLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
}


// Gets the key from the local storage. 
export const GetKeyValueFromLocalStorage = (key: string) : string => {
    return localStorage.getItem(key) ?? '';
}

// Removes the item from the local storage.
export const RemoveKeyFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}

