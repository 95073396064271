import { Login } from "../Login/Login";

export const SiteContentManagement = (props: any) => {
    const SiteRegister = [{
        Heading: '1 a. Register',
        Question: 'Require the member register for the MyBenefits portal prior to logging? ',
        Purpose: 'The registration process on an application is a series of steps that enables individuals to create a personalized account within the application. During registration, users are typically prompted to provide certain details, such as their preferred username, a valid email address, and a secure password. These credentials are used to authenticate the user&rsquo;s identity and ensure the security of their account.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/RegisterButton.png",
        NameOfImagePng1: "Register"
    }];
    const SiteAutoLogin = [{
        Heading: '1 b. Auto-Login upon Completed Registration',
        Question: 'Automatically log the member into the MyBenefits portal in after completing registration?',
        Purpose: 'This feature will automatically log member into the MyBenefits portal after completing the Registration process. This will avoid the member having to navigate to the login page and enter the MyBenefits Portal credentials.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/RegisterButton.png",
    }];
    const SiteLoginTemplate = [{
        Heading: '1 c. Login Template',
        Question: 'Select the login template for how the member will log into the MyBenefits portal',
        Purpose: 'This configuration will determine which type of credentials will be required by the member to access the MyBenefits portal.',
        AvailableLoginTemplates: '<ol><li>Member Id & Password (OTCNationsStandard)</li> <li>Member Id & Date of Birth (OTCWexStandard & OTCNations2FA)</li><li>Member Last Name, Date of Birth & SSN (OTCUAWStandard)</li><li>    Member Card Number (16-Digit), Member Id & Date of Birth (OTCInCommStandard2FA)</li></ol>',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/LoginTemplate.png",
    }];
    const SiteMemberID = [{
        Heading: '1 d. Member ID Login Field - Maximum Characters',
        Question: 'What is the maximum number of characters allowed for the Member ID login field?',
        Purpose: 'This configuration will indicate the maximum number of characters that can be entered as the Member ID field. The configuration should only be set if the login template contains the Member ID',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/memberIdMaxLength.png",
    }];
    const SiteEnable2Factor = [{
        Heading: '1 e. Enable 2-Factor Authentication',
        Question: 'Enable 2-Factor Authentication for the member?',
        Purpose: 'This configuration will allow members to log into the MyBenefits portal using 2-Factor Authentication, which will add an additional layer of security. Two-factor authentication (2FA) is a security system that requires two separate, distinct forms of identification to access the MyBenefits portal.',
        OptIn: 'Members will need to opt-in to 2-Factor Authentication by completing the form on the MyBenefits portal. Members will be required to either provide a mobile phone or email in.',
        Steps: '<ol><li>Members will be sent a one-time pass code to either the mobile phone or email upon login.</li><li>    The member will then need to input the one-time passcode on the MyBenefits portal to access the MyBenefits Portal</li></ol>',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/twoFAConfigis2FAEnabled.png ",
    }];


    const HidePortalBannerThroughPortal = [{
        Heading: '2 a. Hide portal banner through the portal',
        Question: 'Hide portal banner through the portal',
        Purpose: 'This configuration will hide the portal banner throughout the MyBenefits portal after the member has logged into the portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/hidePortalHeaderDesc.png ",
    }];
    const HidePortalBannerLoginPage = [{
        Heading: '2 b. Hide portal banner on login page?',
        Question: 'Hide portal banner through the portal',
        Purpose: 'This configuration will hide the portal banner on the login page of the MyBenefits portal Refer to the example screenshot.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/hidePortalHeaderDesc.png",
    }];
    const DisplayCarrierLogoOnMyBenefitsPortal = [{
        Heading: '2 c. Display Carrier Logo on MyBenefits Portal',
        Question: 'Please provide the carrier logo to display throughout the MyBenefits portal',
        Purpose: 'This configuration will display the specified carrier logo throughout the portal (both Pre-Login and Post-Login).',
        PreRequisites: 'The image filename, of the logo, will be required as part of the configuration',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/clientLogoName.png",
    }];
    const HideCarrierLogoOnLoginPage = [{
        Heading: '2 d. Hide carrier logo on the login page?',
        Question: 'Hide the carrier logo on the login page?',
        Purpose: 'This configuration will hide the carrier logo on the MyBenefits portal login page.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/Hideclientlogo_prelogin.png",
    }];
    const HideCarrierLogoOnMyBenefitsPortal = [{
        Heading: '2 e. Hide carrier logo on the MyBenefits portal?',
        Question: 'Hide the carrier logo on the login page?',
        Purpose: 'This configuration will hide the carrier logo throughout the MyBenefits portal after the member has logged into the portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/Hideclientlogo_postlogin.png",
    }];
    const DisplayCarrierLogoNextToLoginText = [{
        Heading: '2 f. Display Carrier logo next to login text',
        Question: 'Display carrier logo besides the login text?',
        Purpose: 'This configuration will display a custom image, such as a logo, next to the Login text.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/displayClientCustomBranding.png",
    }];
    const CarrierRedirectionURL = [{
        Heading: '2 g. Carrier redirection URL ',
        Question: 'Carrier redirection URL',
        Purpose: '',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const CarrierSubDomainForMyBenefitsPortalURL = [{
        Heading: '2 h. Carrier sub-domain for MyBenefits portal URL',
        Question: 'Please provide the carrier sub-domain for MyBenefits portal URL?',
        Purpose: 'This configuration will allow the carrier to determine what the sub-domain should be included as part of the MyBenefits portal URL',
        URLStructure: '<ol><li>[carrier].nationsbenefits.com</li><li>The carrier will offer a personalized sub-domain that will serve as the starting point of the MyBenefits URL.</li></ol>',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/Subdomain.png",
    }];
    const TollFreeNumber = [{
        Heading: '2 i. TFN (Toll Free Number)',
        Question: 'What is the TFN that should be displayed in the MyBenefits portal?',
        Purpose: 'This configuration will allow the carrier to specify the member assistance toll-free number that should be displayed in the MyBenefits portal',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/Phone.png",
    }];
    const HoursOfOperationPreLogin = [{
        Heading: '2 j. Hours of Operation – Pre-Login ',
        Question: 'Please provide the hours of operations that will be displayed in the MyBenefits portal pre login',
        Purpose: 'This configuration will allow the carrier to specify the hours of operation that will be displayed on the login page of the MyBenefits portal',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/hoursOfOperation.png",
    }];
    const HoursOfOperationPostLogin = [{
        Heading: '2 k. Hours of Operation – Post Login',
        Question: 'Please provide the hours of operations that will be displayed in the MyBenefits portal pre login',
        Purpose: 'This configuration will allow the carrier to specify the hours of operation that will be displayed on the MyBenefits portal after the member has logged in.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/hoursOfOperation.png",
    }];
    const SiteLanguage = [{
        Heading: '2 l. Site Language',
        Question: 'Allow the member to change the site language?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the members to be able to change the site language to the desired language.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowSiteTranslation.JPG",
    }];
    const FAQ = [{
        Heading: '2 m. FAQ',
        Question: 'Displays FAQ in pre and post login?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the FAQs to be displayed in the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const PersonalHealthProfile = [{
        Heading: '2 n. Personal Health Profile',
        Question: 'Disable the Personal Health profile?',
        Purpose: 'This configuration will allow the carriers to indicate if they want to disable the Personal Health profile in the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];


    const AccessToMyBenefitsPortalForHealthRepresentatives = [{
        Heading: '3 a. Access to MyBenefits portal for Health Representatives',
        Question: 'Disable the Personal Health profile?',
        Purpose: 'This configuration will allow the carriers to indicate if they want Health Representatives to have access to the member’s instance of the MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/agentTemplateTitle.jpg",
    }];
    const HealthRepresentativeRole = [{
        Heading: '3 b. Health Representative Role',
        Question: 'What is the role of the Health Representative?',
        Purpose: 'This configuration will allow the carriers to determine the roles of their Health Representatives.<br />2 available roles:<br/><ol><li>Agent</li><li>Care Manager</li></ol>',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/Agentlogintype.PNG",
    }];
    const HealthRepresentativeTitle = [{
        Heading: '3 c. Health Representative Title',
        Question: 'Please provide the title to display on the Health Representative login page',
        Purpose: 'This configuration will allow the carriers to indicate the Health Representative title that will be displayed on the Agent Portal login page',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/agentTemplateTitle.PNG",
    }];
    const BenefitManagement = [{
        Heading: '3 d. Benefit Management',
        Question: 'Allow the Health Representatives to view the member&rsquo;s wallet in the MyBenefits portal?',
        Purpose: 'This configuration will allow the carriers to indicate if the Health Representatives can access to manage member&rsquo;s wallets in the MyBenefits portal. The Health Representative can either enable or disable member wallets',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowAgentManageWallets.png",
    }];
    const GroceryBenefitOptIn = [{
        Heading: '3 e. Grocery Benefit Opt-In',
        Question: 'Allow the Health Representative to opt-in grocery benefit on behalf of a member?',
        Purpose: 'This configuration will allow the carriers to indicate if the Health Representatives can opt-in to the member’s Grocery benefit. ',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowagentoptingrocery.JPG",
    }];
    const FlexCardActivation = [{
        Heading: '3 f. Flex Card Activation',
        Question: 'Allow the Health Representative to activate a Flex Card?',
        Purpose: 'This configuration will allow the carriers to indicate if the Health Representatives can activate a member’s Flex Card on the MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowAgentCardActivation.JPG",
    }];
    const ResetHealthRepresentativePasswordOnAgentPortal = [{
        Heading: '3 g. Reset Health Representative password on Agent Portal',
        Question: 'Allow the Health Representative to reset password on Agent Portal?',
        Purpose: 'This configuration will allow the carriers to indicate if the Health Representatives can reset their password on the Agent Portal',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowAgentForgotPassword.jpg",
    }];


    const Pick3Benefits = [{
        Heading: '4 a. Pick 3 Benefits',
        Question: 'Allow the member to select any 3 benefits?',
        Purpose: 'This configuration will allow the carriers to indicate if the members can choose their desired 3 benefits on the MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowbenefitSelection.png",
    }];
    const Meals = [{
        Heading: '4 b. Meals',
        Question: 'Enable meals purchase on the MyBenefits portal?',
        Purpose: 'This configuration will allow the carriers to indicate if they the members to access Meals on the MyBenefit portal as part of the member’s grocery benefit.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const HideBenefitTracker = [{
        Heading: '4 c. Hide Benefit Tracker',
        Question: 'Hide the Benefit Tracker on the MyBenefits portal?',
        Purpose: 'This configuration will allow the carriers to indicate if they want to hide the Benefit Tracker in the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const RewardsBenefitsForMyBenefitsPortalPurchases = [{
        Heading: '4 d. Rewards benefits for MyBenefits portal purchases',
        Question: 'Allow members to use their Rewards benefits to purchase items on the MyBenefits portal?',
        Purpose: 'This configuration will allow the carriers to indicate if they members to utilize their Rewards benefits on the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const RestrictAppleProductPurchasesWithRewardsBenefits = [{
        Heading: '4 e. Restrict Apple product purchases with Rewards benefits',
        Question: 'Restrict members from using their Rewards for Apple products sold on the MyBenefits portal?',
        Purpose: 'This configuration will allow the carriers to indicate if they want to restrict the members from utilizing the Rewards benefits to purchase any Apple products',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const SelfAttestForRewards = [{
        Heading: '4 f. Self-Attest for Rewards',
        Question: 'Allow members to self-attest for Rewards?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the members to self-attest for reward activities on the MyBenefits portal',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const DisplayOTCLoyaltyCreditInBenefitsTracker = [{
        Heading: '4 g. Display OTC Loyalty Credit in Benefits Tracker',
        Question: 'Display the OTC loyalty credit balance on the MyBenefits tracker?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the OTC loyalty credit to display in the Benefits Tracker on the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const DisplayRolloverBalance = [{
        Heading: '4 h. Display Rollover Balance',
        Question: 'Display the rollover balance to the member?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the rollover balance to be displayed to the member in the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const DisplayBenefitExpirationDate = [{
        Heading: '4 i. Display Benefit Expiration Date',
        Question: 'Display the benefit expiration date?',
        Purpose: 'This configuration will allow the carriers to indicate if they the benefit expiration date for each wallet on the MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/showBenefitExpiration.JPG ",
    }];
    const NumberOfDaysToDisplayBenefitExpirationDate = [{
        Heading: '4 j. Number of days to display benefit expiration date',
        Question: 'Indicate number of days prior to benefit expiration to display the benefit expiration message',
        Purpose: 'This configuration will allow the carriers to indicate how many days, prior to the benefit expiration date, to display the benefit expiration date to the member in the MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/showBenefitExpiration.JPG",
    }];

    const WalletSelectionForItemsEligibleInMultipleWallets = [{
        Heading: '4 k. Wallet selection for items eligible in multiple wallets',
        Question: 'Allow the member to select any wallet while adding a product to the cart if product is eligible for multiple wallets?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the member to be able to select the wallet for item on the MyBenefits portal that is eligible for multiple wallets.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];


    const CustomMessageRegardingOnlineShipping = [{
        Heading: '5 a. Custom message regarding online shipping',
        Question: 'Please provide a custom message to display to the member on the MyBenefits portal login page regarding online shipping',
        Purpose: 'This configuration will allow the carriers to provide a custom message to display on the MyBenefits portal login page regarding online shipping.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowDeliveryDays.png",
    }];
    const CustomBannerMessageOnMyBenefitsPortalLogin = [{
        Heading: '5 b. Custom banner message on MyBenefits portal login',
        Question: 'Please provide a custom banner message to display to the member on the MyBenefits portal login?',
        Purpose: 'This configuration will allow the carriers to provide a custom message to display on the banner on MyBenefits portal login page.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/loginMessage.png",
    }];
    const CustomBannerMessageOnMyBenefitsPortal = [{
        Heading: '5 c. Custom banner message on MyBenefits portal',
        Question: 'Please provide a custom banner message that will be displayed to the user upon log in to the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide a custom message to display on the banner on MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/PostLoginMessage.png",
    }];
    const DisplayVisualTooltipOnMemberIDLoginField = [{
        Heading: '5 d. Display visual tooltip on MemberID login field',
        Question: 'Do you want to display a visual tool tip on the Member ID field on the MyBenefits portal login page?',
        Purpose: 'This configuration will allow the carriers to indicate if they want a visual tooltip to be accessible for members for the MemberID login field on the MyBenefits portal login page.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/cardNumberLoginInfo.JPG",
    }];
    const MessageForMemberLoginAssistance = [{
        Heading: '5 e. Message for member login assistance',
        Question: 'Please provide a member login assistance message to display above the MyBenefits portal login field',
        Purpose: 'This configuration will allow the carriers to provide a custom message to display on the MyBenefits portal regarding login assistance.<br>Example Messages:<br><ol><li>Please enter your Member ID and Password"</li> <li>    Please enter your Username and Password,</li><li>Please enter your Member ID and Date of Birth,</li><li>Please enter your 16-digit or 19-digit member benefit card number,</li><li>Please enter your Last Name, Date of Birth and Last 4 digits of your SSN)</li></ol>',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/loginassistanceheader.png",
    }];
    const MessageForMemberLoginAssistance1 = [{
        Heading: '5 f. Message for member login assistance',
        Question: 'Please provide a member login assistance message to display above the MyBenefits portal login field',
        Purpose: 'This configuration will allow the carriers to provide a custom message to display on the MyBenefits portal what information the member needs to input on the login field.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/loginassistance.png",
    }];
    const DisplayPopupToMemberUponLogin = [{
        Heading: '5 g. Display pop-up to member upon login',
        Question: 'Please provide a member login assistance message to display above the MyBenefits portal login field',
        Purpose: 'This configuration will allow the carriers to indicate if they want a customized pop-up to display to the member upon logging into the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    //const CustomPopup = [{
    //    Heading: '5 h. Custom pop-up',
    //    Question: '',
    //    Purpose: '',
    //    Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    //}];
    const EnablePopupUponLoggingIntoMyBenefitsPortal = [{
        Heading: '5 h i) Enable pop-up upon logging into MyBenefits portal',
        Question: 'Please provide a member login assistance message to display above the MyBenefits portal login field',
        Purpose: 'This configuration will allow the carriers to indicate if they want a customized pop-up to display to the member upon logging into the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];

    const TitleForPopup = [{
        Heading: '5 h ii) Title for pop-up',
        Question: 'Please provide a custom title to display in the pop-up after the member logs in to the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide the title to display in the pop-up',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const MessageForPopup = [{
        Heading: '5 h iii) Message for pop-up',
        Question: 'Please provide a custom image to display in the pop-up after the member logs in to the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide a custom message to display in the pop-up',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const ImageForPopup = [{
        Heading: '5 h iv) Image for pop-up',
        Question: 'Please provide a custom message to display in the pop-up after the member logs in to the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide a custom image to display in the pop-up',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const ButtonTextForPopup = [{
        Heading: '5 h v) Button text for pop-up ',
        Question: 'Please provide a custom text to display in button on the pop-up after the member logs in to the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide custom text to display in the button on the pop-up',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const LoyaltyCreditBanner = [{
        Heading: '5 i. Loyalty Credit Banner ',
        Question: 'Display a message indicating the member has received a loyalty credit?',
        Purpose: 'This configuration will allow the carriers to provide custom message to display regarding any loyalty credits that have been provided to the member',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/loyaltyCreditBannerText.png",
    }];
    const MealsKitInformation = [{
        Heading: '5 j. Meals kit information',
        Question: 'Display a meals kit informational message to the member?',
        Purpose: 'This configuration will allow the carriers to indicate if they want a confirmation message to displayed to the member when the member adds any produce boxes to their shopping cart.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/IsMealKit.png",
    }];
    const GroceryBenefitOptInFundingDays = [{
        Heading: '5 k. Grocery Benefit Opt-In funding days',
        Question: 'Display a meals kit informational message to the member?',
        Purpose: 'This configuration will allow the carriers to indicate how many days the benefit amount will be loaded once the self-attestation is done and display the text as "The funds for the "Healthy Action will be loaded within {fundingDays || "14"} days to your benefit card upon this confirmation.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/FundingDays.png",
    }];
    const PaymentStepCustomMessage = [{
        Heading: '5 l. Payment Step custom message',
        Question: 'Please provide a custom message to display on the payment step of checkout in the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide a custom message to display on the checkout payment step of the MyBenefits portal.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const CustomTrademark = [{
        Heading: '5 m. Custom trademark',
        Question: 'Please provide any custom trademark to display in the footer in the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide any custom trademarks to display on the footer of the MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/appleTrademark.png",
    }];
    const CustomDisclaimer = [{
        Heading: '5 n. Custom disclaimer',
        Question: 'Please provide any custom disclaimer to display in the footer in the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide any custom disclaimer to display on the footer on the MyBenefits portal.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/footerDisclaimer.PNG",
    }];
    const HideBBBCertificate = [{
        Heading: '5 o. Hide BBB certificate',
        Question: 'Hide the BBB certificate in the footer?',
        Purpose: 'This configuration will allow the carriers to indicate they want to hide the BBB certificate on the MyBenefits portal',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/removeBBB.jpg",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/footerDisclaimer.PNG",
        NameOfImagePng1: "Remove BBB",
        NameOfImagePng2: "Footer Disclaimer",
    }];
    const CivilRightsDisclaimer = [{
        Heading: '5 p. Civil Rights Disclaimer',
        Question: 'Please provide a custom civil rights disclaimer to display in the MyBenefits portal',
        Purpose: 'This configuration will allow the carriers to provide a custom civil rights disclaimer to display in the footer of the MyBenefits portal',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/CivilRightsDisclaimerFooterText.png",
    }];

    const EnableFlexFeaturesOnMyBenefitsPortal = [{
        Heading: '6 a. Enable Flex features on MyBenefits portal',
        Question: 'Do you want to enable the Flex features for the member&#39;s on the MyBenefits portal?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the Flex features enable on the MyBenefits portal for the member.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/enableFISFeatures.png",
    }];
    const ImageOnFlexCard = [{
        Heading: '6 b. Image on Flex Card',
        Question: 'What image should be displayed on the Flex Card?',
        Purpose: 'This configuration will allow the carriers to indicate the specific image that should be displayed on the Flex card. Typically, this is carrier logo or branding.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/flexCardImagePathSuffix.png",
    }];
    const MemberIDForFlexCardActivation = [{
        Heading: '6 c. MemberID for Flex Card Activation',
        Question: 'Member ID is required for Flex card activation?',
        Purpose: 'This configuration will allow the carriers to indicate if the member is required to their Member ID for Flex card activation.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const HideActivateCardOnMyBenefitsPortal = [{
        Heading: '6 d. Hide activate card on MyBenefits portal',
        Question: 'Hide activate card on post login page',
        Purpose: 'This configuration will allow the carriers to indicate if they want the Flex Card activation button hidden from the member when they log into the MyBenefits portal',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const ExpressDeliveryForFlexcardReplacements = [{
        Heading: '6 e. Express Delivery for Flex card replacements',
        Question: 'Allow member to request a replace Flex card through Express Delivery?',
        Purpose: 'This configuration will allow the carriers to indicate if they want to allow the member to order Flex card replacements through Express Delivery.',
        //Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide1.png",
    }];
    const SummaryOfFessOnMyTransactionsPage = [{
        Heading: '6 f. Summary of fess on My Transactions page',
        Question: 'Display the summary of fees on the My Transactions page?',
        Purpose: 'This configuration will allow the carriers to indicate if they want the summary of fees displayed to the member on the My Transactions page.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/showfeeSummary.png",
    }];
    const ViewMyTransactionsPage = [{
        Heading: '6 g. View My Transactions page',
        Question: 'Allow members to view the Flex Transactions?',
        Purpose: 'This configuration will allow the carriers to indicate if they want to member to have access to the Flex transactions page',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/allowMyTransactions.png",
    }];
    const DirectDebitOncheckout = [{
        Heading: '6 h. Direct Debit on checkout',
        Question: 'Enable direct debit from the member on checkout?',
        Purpose: 'This configuration will allow the carriers to indicate if they want to allow the direct debit feature on the payment step of checkout. This will avoid the member having to input their Flex card number on the payment step of checkout.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/AllowDirectDebit.png",
    }];

    switch (props.Sitekey) {
        case "LoginRegister":
            return <Login constforlogin={SiteRegister} />

        case "LoginAutoLogin":
           return <Login constforlogin={SiteAutoLogin} />

        case "LoginTemplate":
            return <Login constforlogin={SiteLoginTemplate} />

        case "LoginMemberId":
            return <Login constforlogin={SiteMemberID} />

        case "LoginEnableTwoFA":
            return <Login constforlogin={SiteEnable2Factor} />

        case "AdminHideBannerThroughPortal":
            return <Login constforlogin={HidePortalBannerThroughPortal} />

        case "AdminHideBannerThroughLogin":
            return <Login constforlogin={HidePortalBannerLoginPage} />

        case "AdminDisplayCarrierLogoOnMyBenefits":
            return <Login constforlogin={DisplayCarrierLogoOnMyBenefitsPortal} />

        case "AdminHideCarrierLogoOnLogin":
            return <Login constforlogin={HideCarrierLogoOnLoginPage} />

        case "AdminHideCarrierLogoOnMyBenefits":
            return <Login constforlogin={HideCarrierLogoOnMyBenefitsPortal} />

        case "AdminDisplayCarrierLogoOnMyLogin":
            return <Login constforlogin={DisplayCarrierLogoNextToLoginText} />

        case "AdminCarrierRedirectionURL":
            return <Login constforlogin={CarrierRedirectionURL} />

        case "AdminCarriersubdomain":
            return <Login constforlogin={CarrierSubDomainForMyBenefitsPortalURL} />

        case "AdminTFN":
            return <Login constforlogin={TollFreeNumber} />

        case "AdminHoursofOperationPreLogin":
            return <Login constforlogin={HoursOfOperationPreLogin} />

        case "AdminHoursofOperationPostLogin":
            return <Login constforlogin={HoursOfOperationPostLogin} />

        case "AdminSiteLanguage":
            return <Login constforlogin={SiteLanguage} />

        case "AdminFAQ":
            return <Login constforlogin={FAQ} />

        case "AdminPersonalHealthProfile":
            return <Login constforlogin={PersonalHealthProfile} />

        case "HealthRepMyBenefits":
            return <Login constforlogin={AccessToMyBenefitsPortalForHealthRepresentatives} />

        case "HealthRepRole":
            return <Login constforlogin={HealthRepresentativeRole} />

        case "HealthRepTitle":
            return <Login constforlogin={HealthRepresentativeTitle} />

        case "HealthRepBenefitManagement":
            return <Login constforlogin={BenefitManagement} />

        case "HealthRepGrocery":
            return <Login constforlogin={GroceryBenefitOptIn} />

        case "HealthRepFlexCard":
            return <Login constforlogin={FlexCardActivation} />

        case "HealthRepResetpassword":
            return <Login constforlogin={ResetHealthRepresentativePasswordOnAgentPortal} />

        case "BenefitTrackerPickBenefits":
            return <Login constforlogin={Pick3Benefits} />

        case "BenefitTrackerMeals":
            return <Login constforlogin={Meals} />

        case "BenefitTrackerHideBenefitTracker":
            return <Login constforlogin={HideBenefitTracker} />

        case "BenefitTrackerRewards":
            return <Login constforlogin={RewardsBenefitsForMyBenefitsPortalPurchases} />

        case "BenefitTrackerRestrictAppleproduct":
            return <Login constforlogin={RestrictAppleProductPurchasesWithRewardsBenefits} />

        case "BenefitTrackerSelfAttest":
            return <Login constforlogin={SelfAttestForRewards} />

        case "BenefitTrackerDisplayOTC":
            return <Login constforlogin={DisplayOTCLoyaltyCreditInBenefitsTracker} />

        case "BenefitTrackerDisplayRollover":
            return <Login constforlogin={DisplayRolloverBalance} />

        case "BenefitTrackerDisplayBenefitExpirationDate":
            return <Login constforlogin={DisplayBenefitExpirationDate} />

        case "BenefitTrackerNoOfDaysToDisplayBenefitExpirationDate":
            return <Login constforlogin={NumberOfDaysToDisplayBenefitExpirationDate} />

        case "BenefitTrackerWalletselection":
            return <Login constforlogin={WalletSelectionForItemsEligibleInMultipleWallets} />

        case "ContentManagementCustomMessage":
            return <Login constforlogin={CustomMessageRegardingOnlineShipping} />

        case "ContentManagementCustombannermessageMyBenefitsportallogin":
            return <Login constforlogin={CustomBannerMessageOnMyBenefitsPortalLogin} />

        case "ContentManagementCustombannermessageMyBenefitsportal":
            return <Login constforlogin={CustomBannerMessageOnMyBenefitsPortal} />

        case "ContentManagementDisplayvisualtooltip":
            return <Login constforlogin={DisplayVisualTooltipOnMemberIDLoginField} />

        case "ContentManagementMessageformemberlogin":
            return <Login constforlogin={MessageForMemberLoginAssistance} />

        case "ContentManagementMessageformemberlogin1":
            return <Login constforlogin={MessageForMemberLoginAssistance1} />

        case "ContentManagementDisplaypopup":
            return <Login constforlogin={DisplayPopupToMemberUponLogin} />

        //case "ContentManagementDisplayCustompopup":
        //    return <Login constforlogin={CustomPopup} />

        case "ContentManagementDisplayCustompopupEnablepopup":
            return <Login constforlogin={EnablePopupUponLoggingIntoMyBenefitsPortal} />

        case "ContentManagementDisplayCustompopupTitle":
            return <Login constforlogin={TitleForPopup} />

        case "ContentManagementDisplayCustompopupMessage":
            return <Login constforlogin={MessageForPopup} />

        case "ContentManagementDisplayCustompopupImage":
            return <Login constforlogin={ImageForPopup} />

        case "ContentManagementDisplayCustompopupButton":
            return <Login constforlogin={ButtonTextForPopup} />

        case "ContentManagementLoyaltyCreditBanner":
            return <Login constforlogin={LoyaltyCreditBanner} />

        case "ContentManagementMealskit":
            return <Login constforlogin={MealsKitInformation} />

        case "ContentManagementGroceryBenefit":
            return <Login constforlogin={GroceryBenefitOptInFundingDays} />

        case "ContentManagementPaymentStep":
            return <Login constforlogin={PaymentStepCustomMessage} />

        case "ContentManagementCustomtrademark":
            return <Login constforlogin={CustomTrademark} />

        case "ContentManagementCustomdisclaimer":
            return <Login constforlogin={CustomDisclaimer} />

        case "ContentManagementHideBBBcertificate":
            return <Login constforlogin={HideBBBCertificate} />

        case "ContentManagementCivilRightsDisclaimer":
            return <Login constforlogin={CivilRightsDisclaimer} />

        case "EnableFlexFeaturesOnMyBenefitsPortal":
            return <Login constforlogin={EnableFlexFeaturesOnMyBenefitsPortal} />

        case "ImageOnFlexCard":
            return <Login constforlogin={ImageOnFlexCard} />

        case "MemberIDForFlexCardActivation":
            return <Login constforlogin={MemberIDForFlexCardActivation} />

        case "HideActivateCardOnMyBenefitsPortal":
            return <Login constforlogin={HideActivateCardOnMyBenefitsPortal} />

        case "ExpressDeliveryForFlexcardReplacements":
            return <Login constforlogin={ExpressDeliveryForFlexcardReplacements} />

        case "SummaryOfFessOnMyTransactionsPage":
            return <Login constforlogin={SummaryOfFessOnMyTransactionsPage} />

        case "ViewMyTransactionsPage":
            return <Login constforlogin={ViewMyTransactionsPage} />

        case "DirectDebitOncheckout":
            return <Login constforlogin={DirectDebitOncheckout} />

        default:
            return <Login constforlogin={SiteRegister} />
    }
}