/* eslint-disable jsx-a11y/role-supports-aria-props */
import Scrollbars from 'rc-scrollbars';
import { Link } from "react-router-dom";
import { Accordion, Nav } from 'react-bootstrap';
import FolderPlus from "../../images/folder-plus.svg";
import './AppFeatureList.scss';
import { useEffect, useState } from 'react';

// Interface props.
interface IProps {
    setKeyOfFeatureList: Function;
    keyOfFeatureList: string;
}

export const AppFeatureList = (props: IProps) => {

    // State variable to store the navlink keys.
    const [navKey, setNavKey] = useState('')

    // State variable to store the navlink keys.
    const [navKeyForRegister, setNavKeyFOrRegister] = useState('')

    // Handles Custom pop-up and it's sub features.
    const handlesNavClickForAuthLogin = () => {
        props.setKeyOfFeatureList("AuthLogin");
        setNavKey("CustomAuthLoginNavLink");
        if (navKey == "CustomAuthLoginNavLink") {
            setNavKeyFOrRegister("");
        }
        else {
            setNavKeyFOrRegister("");
        }
    }

    // Handles Custom pop-up and it's sub features.
    const handlesNavClickForAuthRegister = () => {
        props.setKeyOfFeatureList("AuthRegister");
        setNavKeyFOrRegister("CustomAuthAuthRegisterNavLink");
        if (navKeyForRegister == "CustomAuthAuthRegisterNavLink") {
            setNavKey("");
        }
        else {
            setNavKey("");
        }
    }

    useEffect(() => {
        if (props.keyOfFeatureList.toString().toLowerCase() == "authlogin") {
            setNavKey("CustomAuthLoginNavLink");
        }
    },[])


    useEffect(() => {
        const handleClick = (event: any) => {
            const targetElement = event.target;
            const parentElement = targetElement.parentNode.parentNode;
            if (parentElement.className?.toString()?.trim().toLowerCase() == "accordion-header" || parentElement.className?.toString()?.trim().toLowerCase() == "active-accordion accordion-header") {
                setNavKey('');
            }
        };

        document.addEventListener('click', handleClick);
    }, [props.keyOfFeatureList])

    useEffect(() => {
        if (props.keyOfFeatureList.toString().toLowerCase() == "authregister") {
                setNavKey("");
        }
    }, [props])

    return (<>
        <div className='features-accordion'>
            <div className="form-field features-search">
                <input className="form-control search-input" type="search" placeholder="Search Features" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
                    <path d="M12.1846 19C16.8068 19 20.5538 15.4183 20.5538 11C20.5538 6.58172 16.8068 3 12.1846 3C7.56242 3 3.81538 6.58172 3.81538 11C3.81538 15.4183 7.56242 19 12.1846 19Z" stroke="#646F7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.6462 21L18.0954 16.65" stroke="#646F7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            {/*<Scrollbars*/}
            {/*    autoHide*/}
            {/*    autoHideTimeout={100}*/}
            {/*    autoHideDuration={200}*/}
            {/*    autoHeight*/}
            {/*    autoHeightMin={0}*/}
            {/*    autoHeightMax={650}>*/}
            <Accordion defaultActiveKey="1" className="scroll-accordion hide-scroll">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <span className='features'>
                                <span className='accordion-icon'></span>1. Authentication
                            </span>
                            <a href="#">
                                <img className="img-fluid" src={FolderPlus} alt=" " />
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0" className={navKey == "" ? "inner-submenu" : "inner-submenu-active"}>
                                    <Accordion.Header className={props.keyOfFeatureList == "AuthLogin" ? 'inner-submenu-active-title active-accordion inner-submenu-active-title-active' : navKey == "CustomAuthLoginNavLink" ?'inner-submenu-active-title': ''} onClick={handlesNavClickForAuthLogin}><span>a. Login</span></Accordion.Header>
                                <Accordion.Body>
                                    <Nav defaultActiveKey="/Features" className="flex-column" onClick={() => { setNavKey("CustomAuthLoginNavLink") }}>
                                        <Nav.Link className={props.keyOfFeatureList != "LoginNations" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("LoginNations")}><span className="feature-align"><span>i)</span> Login Nations</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "PreLoginPortalDescription" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("PreLoginPortalDescription")}><span className="feature-align"><span>ii)</span> Pre&#45;Login Portal Description</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "CarrierInfoIcon" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("CarrierInfoIcon")}><span className="feature-align"><span>iii)</span> Carrier info icon</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "HideClientLogoBeforeLogin" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("HideClientLogoBeforeLogin")}><span className="feature-align"><span>iv)</span> Hide Client Logo Before Login</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "ClientCustomBranding" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ClientCustomBranding")}><span className="feature-align"><span>v)</span> Client Custom Branding</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "HideActivateCardOptionLogin" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("HideActivateCardOptionLogin")}><span className="feature-align"><span>vi)</span> Hide Activate Card Option on Login</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "PhoneNumber" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("PhoneNumber")}><span className="feature-align"><span>vii)</span> Phone Number</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "MemberAuthentication" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("MemberAuthentication")}><span className="feature-align"><span>viii)</span> Member Authentication</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "DisplayMessageOnLoginTemplate" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("DisplayMessageOnLoginTemplate")}><span className="feature-align"><span>ix)</span> Display message on login template</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "PlaceholderText" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("PlaceholderText")}><span className="feature-align"><span>x)</span> Placeholder Text</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "MaximumLengthOfMemberID" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("MaximumLengthOfMemberID")}><span className="feature-align"><span>xi)</span> Maximum Length of Member ID</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "LoginInformationHelpText" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("LoginInformationHelpText")}><span className="feature-align"><span>xii)</span> Login Information Help Text</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "CustomizableTextForPreLoginInformationIcon" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("CustomizableTextForPreLoginInformationIcon")}><span className="feature-align"><span>xiii)</span> Customizable text for pre-login information icon</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "CustomizableHeroBannerHeadingForPreLogin" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("CustomizableHeroBannerHeadingForPreLogin")}><span className="feature-align"><span>xiv)</span> Customizable Hero Banner Heading for Pre-Login</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "ProviderHelpContact" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ProviderHelpContact")}><span className="feature-align"><span>xv)</span> Provider Help contact</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "DisplayFAQ" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("DisplayFAQ")}><span className="feature-align"><span>xvi)</span> Display FAQ</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "CustomDisclaimerOnDayDelivery" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("CustomDisclaimerOnDayDelivery")}><span className="feature-align"><span>xvii)</span> Custom Disclaimer On 2 Day Delivery</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "UserRegistration" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("UserRegistration")}><span className="feature-align"><span>xviii)</span> User Registration</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "MemberMustBeRegisteredToLogin" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("MemberMustBeRegisteredToLogin")}><span className="feature-align"><span>xix)</span> Member must be registered to login</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "AutoLogin" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("AutoLogin")}><span className="feature-align"><span>xx)</span> Auto Login</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "DisplayTollFreeNumber" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("DisplayTollFreeNumber")}><span className="feature-align"><span>xxi)</span> Display TFN (Toll Free Number)</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "HideTheBBBCertificate" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("HideTheBBBCertificate")}><span className="feature-align"><span>xxii)</span> Hide the BBB Certificate</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "CustomDisclaimer" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("CustomDisclaimer")}><span className="feature-align"><span>xxiii)</span> Custom Disclaimer</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "CustomTrademark" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("CustomTrademark")}><span className="feature-align"><span>xxiv)</span> Custom Trademark</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "CivilRightsDisclaimer" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("CivilRightsDisclaimer")}><span className="feature-align"><span>xxv)</span> Civil Rights Disclaimer</span></Nav.Link>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className={navKeyForRegister == "" ? "inner-submenu" : "inner-submenu-active"}>
                                    <Accordion.Header className={props.keyOfFeatureList == "AuthRegister" ? 'inner-submenu-active-title active-accordion inner-submenu-active-title-active' : navKeyForRegister == "CustomAuthAuthRegisterNavLink" ? 'inner-submenu-active-title' : ''} onClick={handlesNavClickForAuthRegister}><span>b. Register</span></Accordion.Header>
                                    <Accordion.Body>
                                        <Nav defaultActiveKey="/Features" className="flex-column" onClick={() => { setNavKeyFOrRegister("CustomAuthAuthRegisterNavLink") }}>
                                        <Nav.Link className={props.keyOfFeatureList != "AuthAutoLogin" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("AuthAutoLogin")}><span className="feature-align"><span>i)</span> Allow auto Login</span></Nav.Link>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("DisclaimerCivil")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>2. Disclaimers
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "DisclaimerCivil" ? 'inner-menu' : props.keyOfFeatureList == "DisclaimerCivil" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("DisclaimerCivil")}><span className="feature-align"><span>a.</span> Civil Right Disclaimer</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "DisclaimerTradeMark" ? 'inner-menu' : props.keyOfFeatureList == "DisclaimerTradeMark" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("DisclaimerTradeMark")}><span className="feature-align"><span>b.</span> Custom Trademark</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "DisclaimerAcreditation" ? 'inner-menu' : props.keyOfFeatureList == "DisclaimerAcreditation" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("DisclaimerAcreditation")}><span className="feature-align"><span>c.</span> BBB Accreditation</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                <Accordion.Header className={props.keyOfFeatureList != "DisclaimerMetaBank" ? 'inner-menu' : props.keyOfFeatureList == "DisclaimerMetaBank" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("DisclaimerMetaBank")}><span className="feature-align"><span>d.</span> MetaBank Privacy Policy</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("NotificationsSMS")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>3. Notifications
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "NotificationsSMS" ? 'inner-menu' : props.keyOfFeatureList == "NotificationsSMS" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("NotificationsSMS")}><span className="feature-align"><span>a.</span> SMS Notifications</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "NotificationsEmail" ? 'inner-menu' : props.keyOfFeatureList == "NotificationsEmail" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("NotificationsEmail")}><span className="feature-align"><span>b.</span> Email Notification</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "NotificationsSurvey" ? 'inner-menu' : props.keyOfFeatureList == "NotificationsSurvey" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("NotificationsSurvey")}><span className="feature-align"><span>c.</span> Survey Enabled</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("ProductCatalog")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>4. Product Management
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "ProductCatalog" ? 'inner-menu' : props.keyOfFeatureList == "ProductCatalog" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ProductCatalog")}><span className="feature-align"><span>a.</span> Product Catalog</span></Accordion.Header>
                                    <Accordion.Body>
                                        <Nav defaultActiveKey="/Features" className="flex-column">
                                        <Nav.Link className={props.keyOfFeatureList != "ProductDownload" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ProductDownload")}><span className="feature-align"><span>i)</span> Download Catalog</span></Nav.Link>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            {/*</Scrollbars>*/}
            {/*<div className="add-category">*/}
            {/*    {<a className="custom-link cursor-pointer" title="Add New Category" href="#">Add New Category</a>}*/}
            {/*</div>*/}
        </div>
    </>)
}