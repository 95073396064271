import Scrollbars from 'rc-scrollbars';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';

interface AddBenefitModalProps {
    showAddBenefit: boolean;
    commonBenefitsforCarrier: any;
    benefitsDictionary: any;
    handleActivityClose: () => void;
    addBenefitToCollection: (x: any) => void;
    AddBenefitsAtPlanLevel: () => void;
    benefitsDictionaryItems: any
}

const AddBenefitModal: React.FC<AddBenefitModalProps> = ({
    showAddBenefit,
    commonBenefitsforCarrier,
    benefitsDictionary,
    handleActivityClose,
    addBenefitToCollection,
    AddBenefitsAtPlanLevel,
    benefitsDictionaryItems
}) => {

    return (
        <Modal show={showAddBenefit} backdrop="static" keyboard={false} size="lg" centered>
            <Modal.Header closeButton onClick={handleActivityClose}>
                <Modal.Title>Add New Benefit</Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-carrier p-0">
                <Scrollbars
                    autoHide
                    autoHideTimeout={100}
                    autoHideDuration={200}
                    thumbMinSize={80}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={500}
                >
                    <Row className="align-content-stretch g-3">
                        {Object.keys(commonBenefitsforCarrier || []).map((key, index) => (
                            <React.Fragment key={key}>
                                <h5>{key}</h5>
                                {(commonBenefitsforCarrier && commonBenefitsforCarrier[key] || []).map((x:any, index:any) => (
                                    <Col xs={4} key={x?.benefitType}>
                                        <Button
                                            className={`btn-outline ${benefitsDictionary && benefitsDictionary.hasOwnProperty(x?.templateSheetBenefitId) ? 'active' : ''}`}
                                            onClick={() => { addBenefitToCollection(x) }}
                                        >
                                            {benefitsDictionaryItems[x?.benefitType]?.BenefitIcon !== undefined ? (
                                                <img src={benefitsDictionaryItems[x?.benefitType]?.BenefitIcon} className="img-fluid" alt=" " />
                                            ) : (
                                                <img src="" className="img-fluid" alt=" " />
                                            )}
                                            <span className="text-truncate" key={x?.benefitName} title={x?.benefitName}>{x?.benefitName}</span>
                                        </Button>
                                    </Col>
                                ))}
                            </React.Fragment>
                        ))}
                    </Row>
                </Scrollbars>
            </Modal.Body>
            <Modal.Footer className="justify-content-start">
                <Button
                    className={`btn-secondary ${Object.keys(benefitsDictionary || []).length === 0 ? 'disabled' : ''}`}
                    onClick={AddBenefitsAtPlanLevel}
                >
                    Add Benefit
                </Button>
                <Button className="btn-outline-primary" onClick={handleActivityClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddBenefitModal;
