import Scrollbars from 'rc-scrollbars';
import { Link } from "react-router-dom";
import { Accordion, Nav } from 'react-bootstrap';
import FolderPlus from "../../images/folder-plus.svg";
import './AppFeatureList.scss';
import { useEffect, useState } from 'react';

// Interface props.
interface IProps {
    setKeyOfFeatureList: Function;
    keyOfFeatureList: string;
}

export const SiteConfiguration = (props: IProps) => {

    // State variable to store the navlink keys.
    const [navKey, setNavKey] = useState('')

    // To rerender the component whenever the key is changed.
    useEffect(() => {
        const handleClick = (event:any) => {
            const targetElement = event.target;
            const parentElement = targetElement.parentNode.parentNode;
            if (parentElement.className?.toString()?.trim().toLowerCase() == "accordion-header" || parentElement.className?.toString()?.trim().toLowerCase() == "active-accordion accordion-header") {
                setNavKey('');
            }

        };

        document.addEventListener('click', handleClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClick);
        };
       
    }, [props.keyOfFeatureList, navKey])

   // Handles Custom pop-up and it's sub features.
    const handlesNavClickForPopUpAndKeyOfFeatures = () => {
        props.setKeyOfFeatureList("ContentManagementDisplayCustompopupEnablepopup");
        if (navKey == "CustompopupNavLink") {
            setNavKey("");
        }
        else {
            setNavKey("CustompopupNavLink");
        }
    }

    return (<>
        <div className='features-accordion'>
            <div className="form-field features-search">
                <input className="form-control search-input" type="search" placeholder="Search Features" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
                    <path d="M12.1846 19C16.8068 19 20.5538 15.4183 20.5538 11C20.5538 6.58172 16.8068 3 12.1846 3C7.56242 3 3.81538 6.58172 3.81538 11C3.81538 15.4183 7.56242 19 12.1846 19Z" stroke="#646F7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22.6462 21L18.0954 16.65" stroke="#646F7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            {/*<Scrollbars*/}
            {/*    autoHide*/}
            {/*    autoHideTimeout={100}*/}
            {/*    autoHideDuration={200}*/}
            {/*    autoHeight*/}
            {/*    autoHeightMin={0}*/}
            {/*    autoHeightMax={650}>*/}
            <Accordion defaultActiveKey="1" className="scroll-accordion hide-scroll">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("LoginRegister")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>1. Login
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "LoginRegister" ? 'inner-menu' : props.keyOfFeatureList == "LoginRegister" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("LoginRegister")}><span className="feature-align"><span>a.</span> Register</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "LoginAutoLogin" ? 'inner-menu' : props.keyOfFeatureList == "LoginAutoLogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("LoginAutoLogin")}><span className="feature-align"><span>b.</span> Auto-Login upon Completed Registration</span></Accordion.Header>
                                </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "LoginTemplate" ? 'inner-menu' : props.keyOfFeatureList == "LoginTemplate" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("LoginTemplate")}><span className="feature-align"><span>c.</span> Login Template</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                <Accordion.Header className={props.keyOfFeatureList != "LoginMemberId" ? 'inner-menu' : props.keyOfFeatureList == "LoginMemberId" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("LoginMemberId")}><span className="feature-align"><span>d.</span> Member ID Login Field &ndash; Maximum Characters</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                <Accordion.Header className={props.keyOfFeatureList != "LoginEnableTwoFA" ? 'inner-menu' : props.keyOfFeatureList == "LoginEnableTwoFA" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("LoginEnableTwoFA")}><span className="feature-align"><span>e.</span> Enable 2-Factor Authentication</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("AdminHideBannerThroughPortal")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>2. Administration
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminHideBannerThroughPortal" ? 'inner-menu' : props.keyOfFeatureList == "AdminHideBannerThroughPortal" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminHideBannerThroughPortal")}><span className="feature-align"><span>a.</span> Hide portal banner through the portal</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminHideBannerThroughLogin" ? 'inner-menu' : props.keyOfFeatureList == "AdminHideBannerThroughLogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminHideBannerThroughLogin")}><span className="feature-align"><span>b.</span> Hide portal banner on login page?</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminDisplayCarrierLogoOnMyBenefits" ? 'inner-menu' : props.keyOfFeatureList == "AdminDisplayCarrierLogoOnMyBenefits" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminDisplayCarrierLogoOnMyBenefits")}><span className="feature-align"><span>c.</span> Display Carrier Logo on MyBenefits Portal</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminHideCarrierLogoOnLogin" ? 'inner-menu' : props.keyOfFeatureList == "AdminHideCarrierLogoOnLogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminHideCarrierLogoOnLogin")}><span className="feature-align"><span>d.</span> Hide carrier logo on the login page?</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminHideCarrierLogoOnMyBenefits" ? 'inner-menu' : props.keyOfFeatureList == "AdminHideCarrierLogoOnMyBenefits" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminHideCarrierLogoOnMyBenefits")}><span className="feature-align"><span>e.</span> Hide carrier logo on the MyBenefits portal?</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminDisplayCarrierLogoOnMyLogin" ? 'inner-menu' : props.keyOfFeatureList == "AdminDisplayCarrierLogoOnMyLogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminDisplayCarrierLogoOnMyLogin")}><span className="feature-align"><span>f.</span> Display Carrier logo next to login text</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminCarrierRedirectionURL" ? 'inner-menu' : props.keyOfFeatureList == "AdminCarrierRedirectionURL" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminCarrierRedirectionURL")}><span className="feature-align"><span>g.</span> Carrier redirection URL</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminCarriersubdomain" ? 'inner-menu' : props.keyOfFeatureList == "AdminCarriersubdomain" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminCarriersubdomain")}><span className="feature-align"><span>h.</span> Carrier sub-domain for MyBenefits portal URL</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminTFN" ? 'inner-menu' : props.keyOfFeatureList == "AdminTFN" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminTFN")}><span className="feature-align"><span>i.</span> TFN (Toll Free Number)</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminHoursofOperationPreLogin" ? 'inner-menu' : props.keyOfFeatureList == "AdminHoursofOperationPreLogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminHoursofOperationPreLogin")}><span className="feature-align"><span>j.</span> Hours of Operation - Pre-Login</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminHoursofOperationPostLogin" ? 'inner-menu' : props.keyOfFeatureList == "AdminHoursofOperationPostLogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminHoursofOperationPostLogin")}><span className="feature-align"><span>k.</span> Hours of Operation - Post Login</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminSiteLanguage" ? 'inner-menu' : props.keyOfFeatureList == "AdminSiteLanguage" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminSiteLanguage")}><span className="feature-align"><span>l.</span> Site Language</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminFAQ" ? 'inner-menu' : props.keyOfFeatureList == "AdminFAQ" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminFAQ")}><span className="feature-align"><span>m.</span> FAQ</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                <Accordion.Header className={props.keyOfFeatureList != "AdminPersonalHealthProfile" ? 'inner-menu' : props.keyOfFeatureList == "AdminPersonalHealthProfile" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("AdminPersonalHealthProfile")}><span className="feature-align"><span>n.</span> Personal Health Profile</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("HealthRepMyBenefits")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>3. Health Representative
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "HealthRepMyBenefits" ? 'inner-menu' : props.keyOfFeatureList == "HealthRepMyBenefits" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HealthRepMyBenefits")}><span className="feature-align"><span>a.</span> Access to MyBenefits portal for Health Representatives</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "HealthRepRole" ? 'inner-menu' : props.keyOfFeatureList == "HealthRepRole" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HealthRepRole")}><span className="feature-align"><span>b.</span> Health Representative Role</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "HealthRepTitle" ? 'inner-menu' : props.keyOfFeatureList == "HealthRepTitle" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HealthRepTitle")}><span className="feature-align"><span>c.</span> Health Representative Title</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                <Accordion.Header className={props.keyOfFeatureList != "HealthRepBenefitManagement" ? 'inner-menu' : props.keyOfFeatureList == "HealthRepBenefitManagement" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HealthRepBenefitManagement")}><span className="feature-align"><span>d.</span> Benefit Management</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                <Accordion.Header className={props.keyOfFeatureList != "HealthRepGrocery" ? 'inner-menu' : props.keyOfFeatureList == "HealthRepGrocery" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HealthRepGrocery")}><span className="feature-align"><span>e.</span> Grocery Benefit Opt-In</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                <Accordion.Header className={props.keyOfFeatureList != "HealthRepFlexCard" ? 'inner-menu' : props.keyOfFeatureList == "HealthRepFlexCard" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HealthRepFlexCard")}><span className="feature-align"><span>f.</span> Flex Card Activation</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                <Accordion.Header className={props.keyOfFeatureList != "HealthRepResetpassword" ? 'inner-menu' : props.keyOfFeatureList == "HealthRepResetpassword" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HealthRepResetpassword")}><span className="feature-align"><span>g.</span> Reset Health Representative password on Agent Portal</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("BenefitTrackerPickBenefits")}>
                            <span className='features' >
                                <span className='accordion-icon'></span>4. Benefit Tracker
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerPickBenefits" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerPickBenefits" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerPickBenefits")}><span className="feature-align"><span>a.</span> Pick 3 Benefits</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerMeals" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerMeals" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerMeals")}><span className="feature-align"><span>b.</span> Meals</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerHideBenefitTracker" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerHideBenefitTracker" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerHideBenefitTracker")}><span className="feature-align"><span>c.</span> Hide Benefit Tracker</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerRewards" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerRewards" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerRewards")}><span className="feature-align"><span>d.</span> Rewards benefits for MyBenefits portal purchases</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerRestrictAppleproduct" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerRestrictAppleproduct" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerRestrictAppleproduct")}><span className="feature-align"><span>e.</span> Restrict Apple product purchases with Rewards benefits</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerSelfAttest" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerSelfAttest" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerSelfAttest")}><span className="feature-align"><span>f.</span> Self-Attest for Rewards</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerDisplayOTC" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerDisplayOTC" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerDisplayOTC")}><span className="feature-align"><span>g.</span> Display OTC Loyalty Credit in Benefits Tracker</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerDisplayRollover" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerDisplayRollover" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerDisplayRollover")}><span className="feature-align"><span>h.</span> Display Rollover Balance</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerDisplayBenefitExpirationDate" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerDisplayBenefitExpirationDate" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerDisplayBenefitExpirationDate")}><span className="feature-align"><span>i.</span> Display Benefit Expiration Date</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerNoOfDaysToDisplayBenefitExpirationDate" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerNoOfDaysToDisplayBenefitExpirationDate" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerNoOfDaysToDisplayBenefitExpirationDate")}><span className="feature-align"><span>j.</span> Number of days to display benefit expiration date</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                <Accordion.Header className={props.keyOfFeatureList != "BenefitTrackerWalletselection" ? 'inner-menu' : props.keyOfFeatureList == "BenefitTrackerWalletselection" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("BenefitTrackerWalletselection")}><span className="feature-align"><span>k.</span> Wallet selection for items eligible in multiple wallets</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("ContentManagementCustomMessage")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>5. Content Management
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementCustomMessage" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementCustomMessage" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementCustomMessage")}><span className="feature-align"><span>a.</span> Custom message regarding online shipping</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementCustombannermessageMyBenefitsportallogin" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementCustombannermessageMyBenefitsportallogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementCustombannermessageMyBenefitsportallogin")}><span className="feature-align"><span>b.</span> Custom banner message on MyBenefits portal login</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementCustombannermessageMyBenefitsportal" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementCustombannermessageMyBenefitsportal" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementCustombannermessageMyBenefitsportal")}><span className="feature-align"><span>c.</span> Custom banner message on MyBenefits portal</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementDisplayvisualtooltip" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementDisplayvisualtooltip" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementDisplayvisualtooltip")}><span className="feature-align"><span>d.</span> Display visual tooltip on MemberID login field</span></Accordion.Header>
                                </Accordion.Item>
                                {/*We have to modify these because these two are same.*/}
                                <Accordion.Item eventKey="4">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementMessageformemberlogin" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementMessageformemberlogin" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementMessageformemberlogin")}><span className="feature-align"><span>e.</span> Message for member login assistance</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementMessageformemberlogin1" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementMessageformemberlogin1" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementMessageformemberlogin1")}><span className="feature-align"><span>f.</span> Message for member login assistance</span></Accordion.Header>
                                </Accordion.Item>
                                {/*We have to modify these because these two are same.*/}
                                <Accordion.Item eventKey="6">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementDisplaypopup" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementDisplaypopup" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementDisplaypopup")}><span className="feature-align"><span>g.</span> Display pop-up to member upon login</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7" className={navKey == "" ? "inner-submenu" : "inner-submenu-active"}>
                                    <Accordion.Header className={navKey == "CustompopupNavLink" ? 'inner-submenu-active-title' : ''} onClick={handlesNavClickForPopUpAndKeyOfFeatures}><span>h. Custom pop-up</span></Accordion.Header>
                                    <Accordion.Body>
                                        <Nav defaultActiveKey="/Features" className="flex-column" onClick={() => { setNavKey("CustompopupNavLink") }}>
                                        <Nav.Link className={props.keyOfFeatureList != "ContentManagementDisplayCustompopupEnablepopup" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ContentManagementDisplayCustompopupEnablepopup")}><span className="feature-align"><span>i)</span> Enable pop-up upon logging into MyBenefits portal</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "ContentManagementDisplayCustompopupTitle" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ContentManagementDisplayCustompopupTitle")}><span className="feature-align"><span>ii)</span> Title for pop-up</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "ContentManagementDisplayCustompopupMessage" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ContentManagementDisplayCustompopupMessage")}><span className="feature-align"><span>iii)</span> Message for pop-up</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "ContentManagementDisplayCustompopupImage" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ContentManagementDisplayCustompopupImage")}><span className="feature-align"><span>iv)</span> Image for pop-up</span></Nav.Link>
                                        <Nav.Link className={props.keyOfFeatureList != "ContentManagementDisplayCustompopupButton" ? ' ' : 'nav-link active'} onClick={() => props.setKeyOfFeatureList("ContentManagementDisplayCustompopupButton")}><span className="feature-align"><span>v)</span> Button text for pop-up</span></Nav.Link>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementLoyaltyCreditBanner" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementLoyaltyCreditBanner" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementLoyaltyCreditBanner")}><span className="feature-align"><span>i.</span> Loyalty Credit Banner</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementMealskit" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementMealskit" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementMealskit")}><span className="feature-align"><span>j.</span> Meals kit information</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementGroceryBenefit" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementGroceryBenefit" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementGroceryBenefit")}><span className="feature-align"><span>k.</span> Grocery Benefit Opt-In funding days</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementPaymentStep" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementPaymentStep" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementPaymentStep")}><span className="feature-align"><span>l.</span> Payment Step custom message</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementCustomtrademark" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementCustomtrademark" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementCustomtrademark")}><span className="feature-align"><span>m.</span> Custom trademark</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementCustomdisclaimer" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementCustomdisclaimer" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementCustomdisclaimer")}><span className="feature-align"><span>n.</span> Custom disclaimer</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementHideBBBcertificate" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementHideBBBcertificate" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementHideBBBcertificate")}><span className="feature-align"><span>o.</span> Hide BBB certificate</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                <Accordion.Header className={props.keyOfFeatureList != "ContentManagementCivilRightsDisclaimer" ? 'inner-menu' : props.keyOfFeatureList == "ContentManagementCivilRightsDisclaimer" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ContentManagementCivilRightsDisclaimer")}><span className="feature-align"><span>p.</span> Civil Rights Disclaimer</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header onClick={() => props.setKeyOfFeatureList("EnableFlexFeaturesOnMyBenefitsPortal")}>
                            <span className='features'>
                                <span className='accordion-icon'></span>6. Flex
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey="0" className='inner-list'>
                                <Accordion.Item eventKey="0">
                                <Accordion.Header className={props.keyOfFeatureList != "EnableFlexFeaturesOnMyBenefitsPortal" ? 'inner-menu' : props.keyOfFeatureList == "EnableFlexFeaturesOnMyBenefitsPortal" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("EnableFlexFeaturesOnMyBenefitsPortal")}><span className="feature-align"><span>a.</span> Enable Flex features on MyBenefits portal</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header className={props.keyOfFeatureList != "ImageOnFlexCard" ? 'inner-menu' : props.keyOfFeatureList == "ImageOnFlexCard" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ImageOnFlexCard")}><span className="feature-align"><span>b.</span> Image on Flex Card</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                <Accordion.Header className={props.keyOfFeatureList != "MemberIDForFlexCardActivation" ? 'inner-menu' : props.keyOfFeatureList == "MemberIDForFlexCardActivation" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("MemberIDForFlexCardActivation")}><span className="feature-align"><span>c.</span> MemberID for Flex Card Activation</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                <Accordion.Header className={props.keyOfFeatureList != "HideActivateCardOnMyBenefitsPortal" ? 'inner-menu' : props.keyOfFeatureList == "HideActivateCardOnMyBenefitsPortal" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("HideActivateCardOnMyBenefitsPortal")}><span className="feature-align"><span>d.</span> Hide activate card on MyBenefits portal</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                <Accordion.Header className={props.keyOfFeatureList != "ExpressDeliveryForFlexcardReplacements" ? 'inner-menu' : props.keyOfFeatureList == "ExpressDeliveryForFlexcardReplacements" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ExpressDeliveryForFlexcardReplacements")}><span className="feature-align"><span>e.</span> Express Delivery for Flex card replacements</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                <Accordion.Header className={props.keyOfFeatureList != "SummaryOfFessOnMyTransactionsPage" ? 'inner-menu' : props.keyOfFeatureList == "SummaryOfFessOnMyTransactionsPage" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("SummaryOfFessOnMyTransactionsPage")}><span className="feature-align"><span>f.</span> Summary of fess on My Transactions page</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                <Accordion.Header className={props.keyOfFeatureList != "ViewMyTransactionsPage" ? 'inner-menu' : props.keyOfFeatureList == "ViewMyTransactionsPage" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("ViewMyTransactionsPage")}><span className="feature-align"><span>g.</span> View My Transactions page</span></Accordion.Header>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                <Accordion.Header className={props.keyOfFeatureList != "DirectDebitOncheckout" ? 'inner-menu' : props.keyOfFeatureList == "DirectDebitOncheckout" ? 'active-accordion' : ' '} onClick={() => props.setKeyOfFeatureList("DirectDebitOncheckout")}><span className="feature-align"><span>h.</span> Direct Debit on checkout</span></Accordion.Header>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            {/*</Scrollbars>*/}
            {/*<div className="add-category">*/}
            {/*    <a className="custom-link cursor-pointer" title="Add New Category">Add New Category</a>*/}
            {/*</div>*/}
        </div>
    </>)
}
