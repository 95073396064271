import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './Login.scss';
import ShowPassword from "../../images/show-password.svg";
import ManagerIcon from "../../images/manager.svg";
import AdminIcon from "../../images/admin.svg";
import {Admin, getSelectedRole, Manager, UserRoleKey } from '../../Constants/AppConstants';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { useEffect, useState } from 'react';

// Interface
interface IProps {
    handleRoleSelection: Function,
    userRoles: string[];
}

// Login component.
export const Login = (props: IProps) => {

    // Uselocation from react router dom.
    const location = useLocation();

    // Active insurance carrier.
    const { userRoles } = location.state ?? 0;

    // State variable for all user roles.
    const { handleRoleSelection }: any = useAppContext();

    // State variable for all filtered user roles.
    const [allFilteredUserRoles, setAllFilteredUserRoles] = useState<string[]>([]);

    useEffect(() => {
        let userDefinedRoles = props.handleRoleSelection.length == 1 ? props.userRoles : userRoles;
        if ((sessionStorage.getItem(UserRoleKey)?.length ?? 0) <= 0) {
            setAllFilteredUserRoles(userDefinedRoles?.filter((x: string) => x != getSelectedRole()));
        }
        else {
            setAllFilteredUserRoles(userDefinedRoles);
        }
    }, [])

    // Handles UserRole.
    const handleUserRole = (role: string) => {
        props.handleRoleSelection.length == 1 ? props.handleRoleSelection(role) : handleRoleSelection(role);
    }

    return (
        <div className="login-wrapper text-center">
            <img className="img-fluid logo" src="https://nationscdn.azureedge.net/nb-container/NationsLogos/NB-logo-horiz-rev.png" alt=" " />
            <div className="login-form-sec d-none">
                <div className="login-head">
                    <h2>Login</h2>
                    <p>Login to the portal using your credentials.</p>
                </div>
                <FloatingLabel controlId="emailAddress" label="Email">
                    <Form.Control type="email" placeholder=" " />
                </FloatingLabel>
                <FloatingLabel controlId="password" label="Password">
                    <Form.Control type="password" placeholder=" " />
                    <img className="img-fluid form-field-icon" src={ShowPassword} alt=" " />
                </FloatingLabel>
                <Button className="btn-secondary w-100 mb-3 mt-5">Login</Button>
                <a href="#login">Forgot Password?</a>
            </div>

            <div className="login-form-sec login-user">
                <div className="login-head">
                    <h2>Continue as</h2>
                </div>
                {allFilteredUserRoles?.map((role: string) => (
                  <Button key={role}  className={`btn-primary btn-outline-primary ${role === Manager ? "manager" : "admin" }`} onClick={() => { handleUserRole(role) }}><img className="img-fluid form-field-icon" src={role === Manager ? ManagerIcon : AdminIcon } alt=" " />
                        {role}</Button>
                ))}
            </div>
        </div>
    )
}