import { useState } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import NationsLogo from "../images/Nations-Logo.svg";
import Portal from "../images/Portal.svg";
import Home from "../images/home.svg";
import Carriers from "../images/carriers.svg";
import MenuIcon from "../images/menu_icon.svg";
import Logout from "../images/logout.svg";
import ToggleArrow from "../images/down-white.svg";
import SwitchAdmin from "../images/swich-admin.svg";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import "../scss/sidebar.scss";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { CapitalizeFirstLetter, CarrierDetailsActiveTab, getSelectedRole, CarrierNameSearchText, PlanDetailsActiveTab, showSignature, UserRoleKey, SelectedYear, BenefitYears, BenefitYear } from "../Constants/AppConstants";
import { useAppContext } from '../context/AppContext';
import { RemoveKeyFromLocalStorage } from '../Constants/LocalStorage';

export const SideBar = () => {

    // Location.
    const location = useLocation();

    // Path name.
    const { pathname } = location;

    // Split Location.
    const splitLocation = pathname.split("/");

    // Navigate object.
    let navigate = useNavigate();

    // Varibles from use app context.
    const { accounts, instance, userName, rolesCount, isAuthenticated, updateazureLoginDetails, allUserRoles }: any = useAppContext();

    // State variable for show variable.
    const [show, setShow] = useState(false);

    // Close the modal popup.
    const handleClose = () => setShow(false);

    // Shows the popup.
    const handleShow = () => setShow(true);

    // Logout the user.
    const logOut = () => {
        localStorage.removeItem(UserRoleKey);
        localStorage.removeItem("accesstoken");
        localStorage.removeItem(SelectedYear);
        localStorage.removeItem(BenefitYears);
        RemoveKeyFromLocalStorage(CarrierDetailsActiveTab);
        RemoveKeyFromLocalStorage(PlanDetailsActiveTab);
        RemoveKeyFromLocalStorage(CarrierNameSearchText);
        instance.logout();
        window.location.href = "/";
    };

    // Gets the user role.
    const getUserRole = () => {
        return allUserRoles.filter((x: any) => x != getSelectedRole()).toString();
    }

    // Handles the switch role.
    const handleSwitchRole = (userRole: string) => {
        if (rolesCount == 2) {
            let userAccountDetails: any = accounts[0];
            // Update the userAccountDetails with the selected role
            const updatedUserAccountDetails = {
                ...userAccountDetails,
                userRole: userRole,
            };
            localStorage.setItem(UserRoleKey, userRole);
            updateazureLoginDetails(updatedUserAccountDetails);
            window.location.reload();
        }
        else if (rolesCount > 2 && userRole == '') {
            sessionStorage.setItem(UserRoleKey, userRole);
           window.location.reload();
        }
        else if (rolesCount > 2 && userRole != '') {
            let userAccountDetails: any = accounts[0];
            // Update the userAccountDetails with the selected role
            const updatedUserAccountDetails = {
                ...userAccountDetails,
                userRole: userRole,
            };
            localStorage.setItem(UserRoleKey, userRole);
            sessionStorage.setItem(UserRoleKey, userRole);
            updateazureLoginDetails(updatedUserAccountDetails);
            window.location.reload();
        }
    }

    // Navigate to multiple role selection.
    const NavigateToMultipleRolesSelection = () => {
        if (rolesCount > 2 && (sessionStorage.getItem(UserRoleKey)?.length ?? 0) <= 0) {
            navigate("/login", { state: { userRoles: allUserRoles } });
        }
    }

    // Removes the keys in the local storage on carriers click/navigation.
    const RemoveKeysFromLocalStorage = () => {
        RemoveKeyFromLocalStorage(CarrierDetailsActiveTab);
        RemoveKeyFromLocalStorage(PlanDetailsActiveTab);
        RemoveKeyFromLocalStorage(SelectedYear);
        RemoveKeyFromLocalStorage(BenefitYear);
    }

    return (
        <>
            <div className="header d-lg-none">
                <img className="d-lg-none menu-icon" onClick={handleShow} src={MenuIcon} alt=" " />
                <a href="/" className="logo">
                    <img className="img-fluid" src={NationsLogo} alt=" " />
                </a>
            </div>
            <Offcanvas show={show} onHide={handleClose} responsive="lg" className="sidebar position-fixed h-100 start-0 top-0 pt-3">
                <Offcanvas.Header closeButton className="btn-close-white"></Offcanvas.Header>
                <Offcanvas.Body className="h-100">
                    <div className="sidebar-inner d-flex h-100 w-100 flex-column">
                        <a href="/" className="logo">
                            <img className="img-fluid" src={NationsLogo} alt=" " />
                            <img className="ms-2 portal-logo" src={Portal} alt=" " />
                        </a>
                        <Nav defaultActiveKey="/Features" className="flex-column">
                            <Link className={splitLocation[1] === "" ? "active" : ""} to="/">
                                <img className="img-fluid" src={Home} alt=" " />{" "}
                                <span className="link-text">Home</span>
                            </Link>
                            {isAuthenticated && (
                                <Link onClick={RemoveKeysFromLocalStorage} 
                                    className={splitLocation[1] === "Carriers" ? "active" : ""}
                                    to="/Carriers"
                                >
                                    <img className="img-fluid" src={Carriers} alt=" " />{" "}
                                    <span className="link-text">Carriers</span>
                                </Link>
                            )}
                        </Nav>
                        {isAuthenticated && (
                            <Dropdown className="user-details active d-flex align-items-center mt-auto mb-3">
                                <Dropdown.Toggle id="dropdown-button-dark-example1">
                                    <span className="user-circle rounded-circle">
                                        {userName && showSignature(userName)}
                                    </span>
                                    <div className="user-info">
                                        <span><span className="text-truncate username-truncate">{CapitalizeFirstLetter(userName)}</span> <span className="d-block role">{getSelectedRole()}</span></span>
                                        <img className="img-fluid arrow" src={ToggleArrow} alt=" " />
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="mt-auto" >
                                    {
                                        rolesCount > 1 ?
                                            <Dropdown.Item className="flex-nowrap justify-content-start" onClick={rolesCount == 2 ?
                                                () => { handleSwitchRole(getUserRole()) } :
                                                () => { NavigateToMultipleRolesSelection() }}>
                                                <span className="user-icon"><img className="img-fluid m-0" src={SwitchAdmin} alt=" " /></span>
                                                <span className="link-text">{rolesCount == 2 ? "Switch to " + getUserRole() : "Switch Role"}</span>
                                            </Dropdown.Item> : <></>
                                    }
                                    <Dropdown.Item onClick={logOut} className="flex-nowrap justify-content-start">
                                        <span className="user-icon"><img className="img-fluid m-0" src={Logout} alt=" " /></span>
                                        <span className="link-text">Log Out</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
