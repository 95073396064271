import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import AuthenticationProcess from "../../images/authentication-process.svg";
import ProductManagement from "../../images/product-management.svg";
import OrderManagement from "../../images/order-management.svg";
import BenefitCardManagement from "../../images/benefitcard-management.svg";
import AgentServices from "../../images/agent-services.svg";
import arrowPrimary from "../../images/arrow-primary.svg";
import './OurFeatures.scss';


export const OurFeatures = () => {
    return (<>
        <section className="our-features">
            <h2>Our Features</h2>
            <Row className="g-3 g-xxl-0">
                <Col>
                    <ListGroup.Item action href="#" role="link">
                        <Card>
                            <Card.Body>
                                <div className="card-title">
                                    <h3>Authentication Process</h3>
                                    <img className="img-fluid" src={arrowPrimary} alt="Authentication Process" />
                                </div>
                                <Card.Text>Secure and authorized access to your products</Card.Text>
                                <div className="card-img">
                                    <img className="img-fluid" src={AuthenticationProcess} alt="Authentication Process" />
                                </div>
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>

                </Col>

                <Col>
                    <ListGroup.Item action href="#" role="link">
                        <Card>
                            <Card.Body>
                                <div className="card-title">
                                    <h3>Product Management</h3>
                                    <img className="img-fluid" src={arrowPrimary} alt="Product Management" />
                                </div>
                                <Card.Text>Effectively manage products to optimize business outcomes</Card.Text>
                                <div className="card-img">
                                    <img className="img-fluid" src={ProductManagement} alt="Product Management" />
                                </div>
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>
                </Col>

                <Col>
                    <ListGroup.Item action href="#" role="link">
                        <Card>
                            <Card.Body>
                                <div className="card-title">
                                    <h3>Order Management</h3>
                                    <img className="img-fluid" src={arrowPrimary} alt="Order Management" />
                                </div>
                                <Card.Text>Optimize order management</Card.Text>
                                <div className="card-img">
                                    <img className="img-fluid" src={OrderManagement} alt="Order Management" />
                                </div>
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>
                </Col>

                <Col>
                    <ListGroup.Item action href="#" role="link">
                        <Card>
                            <Card.Body>
                                <div className="card-title">
                                    <h3>Benefit Card Management</h3>
                                    <img className="img-fluid" src={arrowPrimary} alt="Benefit Card Management" />
                                </div>
                                <Card.Text>Drive sales success with efficient card management solutions.</Card.Text>
                                <div className="card-img">
                                    <img className="img-fluid" src={BenefitCardManagement} alt="Benefit Card Management" />
                                </div>
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>
                </Col>

                <Col>
                    <ListGroup.Item action href="#" role="link">
                        <Card>
                            <Card.Body>
                                <div className="card-title">
                                    <h3>Agent Services</h3>
                                    <img className="img-fluid" src={arrowPrimary} alt="Agent Services" />
                                </div>
                                <Card.Text>Secure and authorized access to your products</Card.Text>
                                <div className="card-img">
                                    <img className="img-fluid" src={AgentServices} alt="Agent Services" />
                                </div>
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>
                </Col>
            </Row>
        </section>
    </>
    )
}