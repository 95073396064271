export const ConfigServerURL = process.env.REACT_APP_CONFIG_SERVER_URL;
export const MemberServiceUrl =process.env.REACT_APP_REFRESHSERVICE_UI_URL
export const SaveSiteConfiguration = ConfigServerURL + "/Carrier/SaveSiteConfigDataAnswers";
export const NationsMepURL = process.env.REACT_APP_MEP_UI_NATIONS_URL;
export const ElevanceMepURL = process.env.REACT_APP_MEP_UI_ELEVANCE_URL;
export const MyBenefitsURL =process.env.REACT_APP_MYBENEFITS_URL;
export const BenefitsProURL = process.env.REACT_APP_BENEFITS_PRO_URL;
export const EH_MyBenefitsURL = process.env.REACT_APP_EH_MYBENEFITS_URL;
export const MEPNationsServiceURL = process.env.REACT_APP_NATIONS_MEP_URL;
export const MEPElevanceServiceURL = process.env.REACT_APP_ELEVANCE_MEP_URL;
export const TokenURL = process.env.REACT_APP_TOKEN_URL;
export const PricingmanagerURL = process.env.REACT_APP_PRICINGMANAGER_URL;
export const PricingmanagerTemplateView = PricingmanagerURL + "external/viewitems?requestFrom=";
export const HAPMTemplates = ConfigServerURL + "/Carrier/GetAllTemplates?EffectiveDate=";

export const GetExcelByteArray =
  ConfigServerURL + "/Excel/GetExcelByteArrayOfTemplate?template=";
export const GetInsuranceCarriers =
  ConfigServerURL + "/Carrier/GetInsuranceCarriers";
export const GetHealthPlanData = ConfigServerURL + "/Carrier/GetHealthPlans/";
export const GetHealthPlanNameData = ConfigServerURL + "/Carrier/GetHealthPlanData/";
export const GetSheetsWithQuestions =
ConfigServerURL + "/Carrier/GetSheetsWithQuestions";
export const GetQuestionsWithAnswers =
    ConfigServerURL + "/Carrier/GetQuestionsWithAnswers";
export const GetSubDomain = ConfigServerURL + "/Carrier/GetIsSubDomainAlreadyExists/";
export const GetAnswersBasedOnCarrierIdAndBenefitYearBenefit =
   ConfigServerURL + "/Carrier/GetAnswersBasedOnCarrierIdAndBenefitYear";
export const GetCommonBenefitsForInsuranceCarrier =
  ConfigServerURL + "/Carrier/GetCommonBenefitsForInsuranceCarrier/";
export const GetBenefitsOnCarrierAndPlanLevel =
  ConfigServerURL +
  "/Carrier/GetBenefitsOnCarrierAndPlanLevel?insuranceCarrierId=";
export const AddBenefitURL =
    ConfigServerURL + "/Carrier/AddBenefitOnCarrierAndPlanLevel";
export const SubmitOrApproveSiteConfigurationsURL =
    ConfigServerURL + "/Carrier/SubmitOrApproveSiteConfigurations";
export const SupplementalBenefitsFlex = "Supplemental Benefits - Flex";
export const GetAnswersBasedOnCarrierIdAndBenefitYear =
    ConfigServerURL + "/Carrier/GetAnswersBasedOnCarrierIdAndBenefitYear/";
export const CopySiteConfigData =
    ConfigServerURL + "/Carrier/CopySiteConfigData";
export const InsertQuestionsData = ConfigServerURL+"/Carrier/InsertDataAnswers";

export const GetInsertRedirectEnvlope = ConfigServerURL +"/Carrier/InsertRedirectEnvlope";
export const GetMemberInformation = ConfigServerURL +"/Carrier/GetMemberInformation";
export const GetMemberInfoWithCarrierID = ConfigServerURL +"/Carrier/GetMemberInfowithCarrierID";
export const GetEmulatorStatus = ConfigServerURL+ "/Carrier/GetEmulatorStatusDataWithCarrierID/";
export const GetEmulatorStatusDataWithPlanID =ConfigServerURL+"/Carrier/GetEmulatorStatusDataWithPlanID";
export const GetCarrierSubdomain =ConfigServerURL+"/Carrier/GetCarrierSubdomain";
export const GetMemberuserInfo = MemberServiceUrl + "/Members/GetUserProfileInfo";
export const GetBenefitsForCarrier = ConfigServerURL + "/Carrier/GetBenefitsForCarrier";
export const OverrideCatchallDataPlanAnswers = ConfigServerURL + "/Carrier/OverrideCatchallDataPlanAnswers";
export const CheckPlanOverrideExists = ConfigServerURL + "/Carrier/CheckPlanOverrideExists";
export const InsertIsPlanOverrides = ConfigServerURL + "/Carrier/InsertIsPlanOverrides";
export const GetOverRidePreference = ConfigServerURL + "/Carrier/GetOverRidePreference";
export const GetImageCDNUrl = process.env.REACT_APP_BENEFITS_ICONS_URL
export const InsertRule =ConfigServerURL+"/Carrier/InsertDataPlanAndAnswers"
export const UpdateRule =ConfigServerURL+"/Carrier/UpdateDataAnswers";
export const MemberSearchNationsUrl = MEPNationsServiceURL + "Members/MemberSearch";
export const MemberSearchElevanceUrl = MEPElevanceServiceURL + "Members/MemberSearch";
export const GetBenefitYearsForACarrier = ConfigServerURL + "/Carrier/GetBenefitYearsStatusForACarrier";
export const GetMultiTenantToken = TokenURL + "auth/token";
export const GetEmulatoryAgentDetailsWithCarrierID = ConfigServerURL + "/Carrier/GetEmulatorAgentDetails";
//User roles
export const Manager = "Manager";
export const Admin = "Admin";
export const SystemAdmin = "System Admin";
export const AzureLoginIDKey = "username"
export const resolution = 991;
export const version ="V1";
export const fisSource = "FIS";
export const fisNationsSource ="FIS, Nations";
export const validBenefitSources = ["Nations", "FIS", "FIS, Nations"];

// Current year,current date
export const year = new Date().getFullYear();
export function getCurrentFormattedDate(effectiveDate = new Date()) {
    const day = effectiveDate.getDate();
    const month = effectiveDate.getMonth() + 1;
    const currentYear = effectiveDate.getFullYear() ; 
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    return `${formattedDay}/${formattedMonth}/${currentYear}`;
}

export function formatDate(effDate = new Date()) {
    const year = effDate.getFullYear();
    const month = String(effDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(effDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function formatUtcDate(effDate = new Date(),isReturnTime:boolean=false) {
    // Ensure effDate is a Date object
    const dateObj = effDate instanceof Date ? effDate : new Date(effDate);

    // To match "Oct 31, 2023 - 5:42 PM" format:
    const month: string = dateObj.toLocaleString('en-US', { month: 'short' });
    const day: number = dateObj.getDate();
    const year: number = dateObj.getFullYear();
    const hours: number = dateObj.getHours();
    const minutes: number = dateObj.getMinutes();
    const ampm: string = hours >= 12 ? 'PM' : 'AM';
    const formattedHours: number = hours % 12 || 12; // Convert 24-hour time to 12-hour format
    if (isReturnTime)
        return `${month} ${day}, ${year}`;
   else
    return `${month} ${day}, ${year} - ${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
}

// Questions
export const OnlyPublishForCY = "only publish for current year";
export const SubDomainQuestion = "Please provide the domain of the MyBenefits portal";

// Button titles
export const Approve = 'Approve';
export const Submit = 'Submit';
export const UserRoleKey = "SelectedRole";

// Status 
export const ApprovedStatus = "Approved";
export const SubmittedStatus = "Submitted";
export const DraftStatus = "Draft";
export const ReviewedStatus = "Reviewed";
export const PublishedStatus = "Published";
export const FailedStatus = "Published";
export const NewStatus = "New";


// Message
export const MandatoryMessage = "In order to submit the site configurations for approval, please answer all mandatory questions that are denoted by a red asterisk."
export const NonMandatoryMessage = "In order to submit the site configurations for approval, please answer atleast one question."
export const SaveSiteConfig = "Site answers saved successfully."
export const SaveBenefitConfig = "Benefit answers saved successfully for HA2."
export const SaveErrorMessageForHA2 = "Error occured while saving the data"
export const EditErrorMessageForHA2 = "Error occured while saving the data"

// Exports the active tabs.
export const CarrierDetailsActiveTab = "CarrierDetailsActiveTab";
export const PlanDetailsActiveTab = "PlanDetailsActiveTab";
export const CarrierNameSearchText = "CarrierNameSearchText";

//Export the Environments.
export const prodceus2 = "prodceus2";
export const prodcentus = "prodcentus";
export const prod = "prod";
export const stage = "stage";

// Session timeout
export const SESSION_TIMEOUT = 30 * 60 * 1000;

//For Future Year
export const futureYear = new Date().getFullYear() + 1;

//Benefit ans selected years
export const SelectedYear = "Selectedyear";
export const BenefitYears = "BenefitYears";
export const BenefitYear = "BenefitYear";

export const getSelectedRole = () => {
    return localStorage.getItem(UserRoleKey) ?? '';
}


export const groupNames ={
  itemRule:"Item Rules",
  catalogRule:"Catalog Details",
  catalog:"Catalog"
}


export const questionModalConstants={
   sourceTemplateId:3,
   dropdown:"dropdown",
   no:"no",
   questionnairedown:"questionnaire-down benefit-content-section mt-3 p-2",
   questionnaireup :"questionnaire-up",
   questionnairedownsubchild :"questionnaire-down-subchild",
   questionnaireupsubchild :"questionnaire-up-subchild",
    yes: "yes",
    questionNameforpurseslot: "could you please provide the purse slots that have been applied for this specific benefit or wallet?"
} 
export const DateRelated ={
  benefitDetails:"Benefit Details",
  benefitEffectiveDate : "Benefit Effective Date",
  benefitExpirationDate:"Benefit Expiration Date"
}
export  const mappingData =[{
  "label":"Benefit Configuration",
   "condition":"isBenefitConfigurationCompleted",
   "comments":"benefitConfigurationComments"
},
{
  "label":"Publisher",  
  "condition":"isPublishingCompleted",
  "comments":"isPublishingComments"
},{
  "label":"ACC Configuration",  
  "condition":"isACCConfigurationCompleted",
  "comments":"accConfigurationComments"
},
{
"label":"Member Creation",
"condition":"isMemberCreated",
"comments":"memberCreationComments"
},
{
"label":"Card Creation",
"condition":"isCardCreated",
"comments":"isCardCreatedComments"
},
{
"label":"Funds Loading",
"condition":"isFundsLoadingCompleted",
"comments":"fundsLoadingComments"
}]
export const HA2BenDetails = "HA Benefit Details"
export const HA2Benefit = "HA2"
export const PairPrice = "Pair Price"
export const BenefitSourceTemplateId = 1
export const ObjectType = "object"
export const SheetName = "sheetName"
export const TemplateName = "Template Name"
export const RequestFrom ="OBT" 

export const colourMapping =[
  {
     label:"New",
     colourStyles:"new",
     value :"New"
  },
  {
     label:"Draft",
     colourStyles:"draft",
     value:"Draft"
  },
  {
      label:"Pending Approval",
      colourStyles:"review",
      value:"Pending Approval"
  },
  {
      label:"Pending Publish",
      colourStyles:"review",
      value:"Pending Publish"
  },
  {
      label:"Published",
      colourStyles:"published",
      value:"Published"
  },
  {
      label:"Pending Publish",
      colourStyles:"failed",
      value:"Failed"
  }

]

export const statusList ={
  new:"New",
  published:"Published",
  draft: "Draft",
  pendingApproval: "Pending Approval",
  pendingPublish: "Pending Publish",
  failed: "Failed",
  inProgress : "InProgress"
}

export const ActiveInactiveList = {
    active: "Active",
    inactive: "InActive",
    all: "All",
    futureActive: "FutureActive",
    futureInactive: "FutureInActive"
}

export const CapitalizeFirstLetter = (str: any) => {
  return str
    ?.toLowerCase()
    .split(" ")
    .map((word: any) => word.charAt(0)?.toUpperCase() + word.slice(1))
    .join(" ");
};

// Shows the AD signature.
export const showSignature = (userName: any) => {
    const words = userName.split(" ");
    const firstTwoLetters = words
        .slice(0, 2)
        .map((word: any) => word.charAt(0).toUpperCase())
        .join("");

    return firstTwoLetters;
}

export const getStorageValue = (key: string, status?: "no-json") => {
  try {
    const saved = localStorage.getItem(key);

    const initial = saved && (status ? saved : JSON.parse(saved));

    return initial || null;
  } catch (error) {
    return null;
  }
};

export const getEnvironment =(environmentId:number)=>{
  return environmentId === 2 ?'EH':'NH'
}

export const getEnvironmentUrl =(environmentId:number)=>{
  return environmentId === 2 ?MemberSearchElevanceUrl:MemberSearchNationsUrl
}

export const getMEPUIEnvironmentUrl =(environmentId:number)=>{
  return environmentId === 2 ?ElevanceMepURL:NationsMepURL
}

export const textTypes =["textbox","text","numeric"];

export const statuses =["approved","failed","published"];

export const undefinedConst = "undefined";

export const VersionNumber = "VS1";

// Export the question names

export const DisplayName = "Display Name";

export const Pair = "Pair";

export const Unit = "Unit";

export const CardImage = "Card Image";

export const ClientLogo = "Provide client/plan logo name to display in MyBenefits pro";

export const CDNCardImagePath = "https://function806dd5a792ed.blob.core.windows.net/nb-container/NBBenefits/flex-cards/";

export const CDNLogo = "https://function806dd5a792ed.blob.core.windows.net/otc-container/Client_Logos/";

export const OverrideIcon = "https://nationscdn.azureedge.net/nb-container/OnBoarding/override.svg";

export const OverrideButton = "AllPlans";
export const WithOutOverrideButton = "plansWithoutOverrides";











