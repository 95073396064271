import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AlertSuccess from "../../images/alert-success.svg";
import Add from "../../images/add.svg";
import Revert from "../../images/revert.svg";
import EditIcon from "../../images/edit.svg";
import Close from "../../images/close.svg";
import Upload from "../../images/upload.svg";
import CardImage from "../../images/card-img.svg";
import Accordion from "react-bootstrap/Accordion";
import Scrollbars from "rc-scrollbars";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import "./Features.scss";
import { ContentManagement } from "./Authentication/Utility/ContentManagements";
import { SiteContentManagement } from "./Authentication/Utility/SiteContentManagements";
import { SideBar } from "../SideBar";
import { AppFeatureList } from "./AppFeatureList";
import { SiteConfiguration } from "./SiteConfiguration";
import { Modal } from "react-bootstrap";
import "../../scss/tabs.scss";
import "./AddCategory.scss";
import { useIsAuthenticated } from "@azure/msal-react";

export const Features = () => {
  // State variable for holding key.
  const [key, setKey] = useState("AuthLogin");

  // Function to receive and update the key from props.
  const setKeyOfFeatureList = (key: any) => {
    setKey(key);
  };

  // State variable for holding key.
  const [Sitekey, setSiteKey] = useState("LoginRegister");

  // Function to receive and update the key from props.
  const setKeyOfSite = (key: any) => {
    setSiteKey(key);
  };

  // Add category Section Const.
  const [ShowCatagory, setShowCatagory] = useState(false);
  const handleActivityClose = () => {
    setShowCatagory(false);
  };

  return (
    <div className="d-flex overflow-hidden">
      <Alert className="d-none" variant="success" dismissible>
        <span className="alert-left d-flex align-items-center">
          <img
            className="img-fluid alert-icon me-2"
            src={AlertSuccess}
            width={18}
            height={18}
            alt=" "
          />
          <span>Feature was created successfully</span>
        </span>
      </Alert>
      <SideBar />
      <div className="page-content">
        <div className="page-title-section">
          <h3>Features List</h3>
          <div className="page-top-right text-end">
            <a
              className="custom-link cursor-pointer"
              title="View Clients Features"
              href="#"
            >
              View Clients Features
            </a>
            <p className="mb-0">
              <span>Last Updated:</span> <span>Apr 12, 2023</span>
            </p>
          </div>
        </div>
        <div className="features-list">
          <Tabs
            defaultActiveKey="AppFeatures"
            id="uncontrolled-tab-example"
            className="tabs-list features-tabs"
          >
            <Tab eventKey="AppFeatures" title="App Features">
              <div className="tabs-container">
                <div className="content-left">
                  <AppFeatureList
                    setKeyOfFeatureList={setKeyOfFeatureList}
                    keyOfFeatureList={key}
                  />
                  {/* Add Category Section HTML Start */}
                  <div className="add-catagory-section">
                    <a
                      href="#"
                      className="add-catagory-btn"
                      onClick={() => {
                        setShowCatagory(true);
                      }}
                    >
                      +
                    </a>
                  </div>
                  <Modal
                    show={ShowCatagory}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton onClick={handleActivityClose}>
                      <Modal.Title>Add Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="form-content">
                        Please enter the category name
                      </p>
                      <Row>
                        <Col lg={6}>
                          <FloatingLabel
                            controlId="CatagoryName"
                            label="Catagory Name *"
                          >
                            <Form.Control type="ext" placeholder=" " />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-start">
                      <Button className="btn-secondary">Add catagory</Button>
                      <Button
                        className="btn-outline-primary"
                        onClick={handleActivityClose}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Add Category Section HTML End */}
                </div>
                <div className="content-right">
                  <div className="features-page hide-scroll">
                    {/*<Scrollbars style={{ height: '100%' }}>*/}
                    <div className="features-content">
                      <div className="features-info">
                        <ContentManagement AppKey={key} />
                      </div>
                      <div className="add-subfeature d-none">
                        <Row>
                          <Col xs={8} xxl={6}>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Sub Feature Name"
                              className="feature-head"
                            >
                              <Form.Control type="text" placeholder=" " />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <div className="psudo-buttons">
                          <h6>Is this covers under Medicaid?</h6>
                          <Button className="btn-outline active">Yes</Button>
                          <Button className="btn-outline">No</Button>
                        </div>
                        <Row>
                          <Col>
                            <FloatingLabel controlId="Purpose" label="Purpose">
                              <Form.Control
                                as="textarea"
                                placeholder=" "
                                style={{ height: "90px" }}
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h6>Default Behaviour of this Feature</h6>
                            <FloatingLabel
                              controlId="Behaviour"
                              label="Write Behaviour of the feature"
                            >
                              <Form.Control
                                as="textarea"
                                placeholder=" "
                                style={{ height: "100px" }}
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={8} xxl={6}>
                            <h6>Is feature has video link?</h6>
                            <FloatingLabel
                              controlId="VideoURL"
                              label="Video URL"
                            >
                              <Form.Control type="text" placeholder=" " />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col xs={12}>
                            <h6>This feature is available in</h6>
                            <Button className="btn-outline">
                              <div className="form-check m-0">
                                <Form.Check aria-label="option 1" />
                              </div>
                              Essential
                            </Button>
                            <Button className="btn-outline active">
                              <div className="form-check m-0">
                                <Form.Check aria-label="option 1" checked />
                              </div>
                              Premium
                            </Button>
                            <Button className="btn-outline">
                              <div className="form-check m-0">
                                <Form.Check aria-label="option 1" />
                              </div>
                              Enterprise
                            </Button>
                          </Col>
                        </Row>
                        <div className="upload-feature-img">
                          <Row className="mb-3">
                            <Col xs={10} xxl={6}>
                              <h6>Add Images link</h6>
                              <div className="input-group">
                                <FloatingLabel
                                  controlId="Imagelink"
                                  label="Image link"
                                  className="mb-0"
                                >
                                  <Form.Control type="text" placeholder=" " />
                                </FloatingLabel>
                                <button
                                  type="button"
                                  className="btn-outline btn btn-primary ms-3"
                                >
                                  Add Image
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Card className="upload-section">
                            <Row>
                              <Col xs={3}>
                                <div className="upload-process"></div>
                                <Card.Img
                                  variant="left"
                                  className="img-fluid"
                                  src={CardImage}
                                />
                              </Col>
                              <Col xs={9}>
                                <Card.Body>
                                  <Card.Text>
                                    <div className="upload-info">
                                      <Link
                                        className="custom-link text-truncate"
                                        to="https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf"
                                      >
                                        https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf
                                      </Link>
                                      <div className="uplad-actions">
                                        <Link className="loading" to="/">
                                          <img
                                            className="img-fluid"
                                            src={Upload}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Close}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <FloatingLabel
                                      controlId="ImgDes"
                                      label="Image Description (Max. 80 Characters)"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder=" "
                                        value={""}
                                      />
                                    </FloatingLabel>
                                  </Card.Text>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                          <Card className="upload-section">
                            <Row>
                              <Col xs={3}>
                                <div className="upload-process"></div>
                                <Card.Img
                                  variant="left"
                                  className="img-fluid"
                                  src={CardImage}
                                />
                              </Col>
                              <Col xs={9}>
                                <Card.Body>
                                  <Card.Text>
                                    <div className="upload-info">
                                      <Link
                                        className="custom-link text-truncate"
                                        to="https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf"
                                      >
                                        https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf
                                      </Link>
                                      <div className="uplad-actions">
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Upload}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Close}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <FloatingLabel
                                      controlId="ImgDes"
                                      label="Image Description (Max. 80 Characters)"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder=" "
                                        value={""}
                                      />
                                    </FloatingLabel>
                                  </Card.Text>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                          <Card className="upload-section">
                            <Row>
                              <Col xs={3}>
                                <div className="upload-process"></div>
                                <Card.Img
                                  variant="left"
                                  className="img-fluid"
                                  src={CardImage}
                                />
                              </Col>
                              <Col xs={9}>
                                <Card.Body>
                                  <Card.Text>
                                    <div className="upload-info">
                                      <Link
                                        className="custom-link text-truncate"
                                        to="https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf"
                                      >
                                        https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf
                                      </Link>
                                      <div className="uplad-actions">
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Upload}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Close}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <FloatingLabel
                                      controlId="ImgDes"
                                      label="Image Description (Max. 80 Characters)"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder=" "
                                        value={""}
                                      />
                                    </FloatingLabel>
                                  </Card.Text>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                        </div>
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-secondary rounded me-3"
                          >
                            Save New Feature
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded"
                          >
                            Discard
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*</Scrollbars>*/}
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="BenefitFeatures" title="Benefit Features">
              Benefit Features
            </Tab>
            <Tab eventKey="SiteConfiguration" title="Site Configuration">
              <div className="tabs-container">
                <div className="content-left">
                  <SiteConfiguration
                    setKeyOfFeatureList={setKeyOfSite}
                    keyOfFeatureList={Sitekey}
                  />
                  {/* Add Category Section HTML Start */}
                  <div className="add-catagory-section">
                    <a
                      href="#"
                      className="add-catagory-btn"
                      onClick={() => {
                        setShowCatagory(true);
                      }}
                    >
                      +
                    </a>
                  </div>
                  <Modal
                    show={ShowCatagory}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton onClick={handleActivityClose}>
                      <Modal.Title>Add Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="form-content">
                        Please enter the category name
                      </p>
                      <Row>
                        <Col lg={6}>
                          <FloatingLabel
                            controlId="CatagoryName"
                            label="Catagory Name *"
                          >
                            <Form.Control type="ext" placeholder=" " />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-start">
                      <Button className="btn-secondary">Add catagory</Button>
                      <Button
                        className="btn-outline-primary"
                        onClick={handleActivityClose}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Add Category Section HTML End */}
                </div>
                <div className="content-right">
                  <div className="features-page hide-scroll">
                    {/*<Scrollbars style={{ height: '100%' }}>*/}
                    <div className="features-content">
                      <div className="features-info">
                        <SiteContentManagement Sitekey={Sitekey} />
                      </div>

                      <div className="add-subfeature d-none">
                        <Row>
                          <Col xs={8} xxl={6}>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Sub Feature Name"
                              className="feature-head"
                            >
                              <Form.Control type="text" placeholder=" " />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <div className="psudo-buttons">
                          <h6>Is this covers under Medicaid?</h6>
                          <Button className="btn-outline active">Yes</Button>
                          <Button className="btn-outline">No</Button>
                        </div>
                        <Row>
                          <Col>
                            <FloatingLabel controlId="Purpose" label="Purpose">
                              <Form.Control
                                as="textarea"
                                placeholder=" "
                                style={{ height: "90px" }}
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h6>Default Behaviour of this Feature</h6>
                            <FloatingLabel
                              controlId="Behaviour"
                              label="Write Behaviour of the feature"
                            >
                              <Form.Control
                                as="textarea"
                                placeholder=" "
                                style={{ height: "100px" }}
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={8} xxl={6}>
                            <h6>Is feature has video link?</h6>
                            <FloatingLabel
                              controlId="VideoURL"
                              label="Video URL"
                            >
                              <Form.Control type="text" placeholder=" " />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col xs={12}>
                            <h6>This feature is available in</h6>
                            <Button className="btn-outline">
                              <div className="form-check m-0">
                                <Form.Check aria-label="option 1" />
                              </div>
                              Essential
                            </Button>
                            <Button className="btn-outline active">
                              <div className="form-check m-0">
                                <Form.Check aria-label="option 1" checked />
                              </div>
                              Premium
                            </Button>
                            <Button className="btn-outline">
                              <div className="form-check m-0">
                                <Form.Check aria-label="option 1" />
                              </div>
                              Enterprise
                            </Button>
                          </Col>
                        </Row>
                        <div className="upload-feature-img">
                          <Row className="mb-3">
                            <Col xs={10} xxl={6}>
                              <h6>Add Images link</h6>
                              <div className="input-group">
                                <FloatingLabel
                                  controlId="Imagelink"
                                  label="Image link"
                                  className="mb-0"
                                >
                                  <Form.Control type="text" placeholder=" " />
                                </FloatingLabel>
                                <button
                                  type="button"
                                  className="btn-outline btn btn-primary ms-3"
                                >
                                  Add Image
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Card className="upload-section">
                            <Row>
                              <Col xs={3}>
                                <div className="upload-process"></div>
                                <Card.Img
                                  variant="left"
                                  className="img-fluid"
                                  src={CardImage}
                                />
                              </Col>
                              <Col xs={9}>
                                <Card.Body>
                                  <Card.Text>
                                    <div className="upload-info">
                                      <Link
                                        className="custom-link text-truncate"
                                        to="https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf"
                                      >
                                        https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf
                                      </Link>
                                      <div className="uplad-actions">
                                        <Link className="loading" to="/">
                                          <img
                                            className="img-fluid"
                                            src={Upload}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Close}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <FloatingLabel
                                      controlId="ImgDes"
                                      label="Image Description (Max. 80 Characters)"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder=" "
                                        value={""}
                                      />
                                    </FloatingLabel>
                                  </Card.Text>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                          <Card className="upload-section">
                            <Row>
                              <Col xs={3}>
                                <div className="upload-process"></div>
                                <Card.Img
                                  variant="left"
                                  className="img-fluid"
                                  src={CardImage}
                                />
                              </Col>
                              <Col xs={9}>
                                <Card.Body>
                                  <Card.Text>
                                    <div className="upload-info">
                                      <Link
                                        className="custom-link text-truncate"
                                        to="https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf"
                                      >
                                        https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf
                                      </Link>
                                      <div className="uplad-actions">
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Upload}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Close}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <FloatingLabel
                                      controlId="ImgDes"
                                      label="Image Description (Max. 80 Characters)"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder=" "
                                        value={""}
                                      />
                                    </FloatingLabel>
                                  </Card.Text>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                          <Card className="upload-section">
                            <Row>
                              <Col xs={3}>
                                <div className="upload-process"></div>
                                <Card.Img
                                  variant="left"
                                  className="img-fluid"
                                  src={CardImage}
                                />
                              </Col>
                              <Col xs={9}>
                                <Card.Body>
                                  <Card.Text>
                                    <div className="upload-info">
                                      <Link
                                        className="custom-link text-truncate"
                                        to="https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf"
                                      >
                                        https://nb.cdn.com/xyz21ooqwa89457jfhtyuhf
                                      </Link>
                                      <div className="uplad-actions">
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Upload}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                        <Link to="/">
                                          <img
                                            className="img-fluid"
                                            src={Close}
                                            width={15}
                                            height={15}
                                            alt=" "
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <FloatingLabel
                                      controlId="ImgDes"
                                      label="Image Description (Max. 80 Characters)"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder=" "
                                        value={""}
                                      />
                                    </FloatingLabel>
                                  </Card.Text>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                        </div>
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-secondary rounded me-3"
                          >
                            Save New Feature
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded"
                          >
                            Discard
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*</Scrollbars>*/}
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
