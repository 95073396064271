import { Breadcrumb, Button, Col, FloatingLabel, Form, Modal, Row, Tabs } from "react-bootstrap";
import BackArrow from "../../images/back-arrow.svg";
import CheckCircle from "../../images/check-circle.svg";
import { CardImage, CarrierDetailsActiveTab, CDNCardImagePath, CDNLogo, ClientLogo, formatUtcDate, getCurrentFormattedDate, SaveSiteConfig, year } from '../../Constants/AppConstants';
import Info from "../../images/info.svg";
import Plus from "../../images/plus-blue.svg";
import { useEffect, useState } from "react";
import { Question } from "../../Interfaces/Question";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallbackContext } from "../../context/CallbackContext";
import { SideBar } from "../SideBar";
import { AlertPopup } from "../Common/CommonComponents";
import { SetKeyToLocalStorage } from "../../Constants/LocalStorage";
import Time from "../../images/time.svg";


export const FutureSiteConfig = () => {

    // Uselocation from react router dom.
    const location = useLocation();
    const navigate = useNavigate();
    const { saveFutureConfig } = useCallbackContext();

    //Active insurance carrier or Active Insurance Plan
    const { activeInsuranceCarrier, planDetails, selectedQuestion, futureSelectedQuestion} = location.state ?? 0;

    // For question with initial value from props
    const [questionState, setQuestionState] = useState<Question>(selectedQuestion);

    // For initial answer value, could be undefined initially based on props
    const [initialAnswerValue, setInitialAnswerValue] = useState<string | undefined>(selectedQuestion.answerValue);

    // For Save button enable/disable, initially set to false
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);

    // To enable/disable the popup.
    const [isPopUpEnabled, setIsPopUpEnabled] = useState<boolean>(false);

    // To store isremove config.
    const [isRemoved, setIsRemoved] = useState<boolean>(false);

    // To show or hide future section.
    const [isEnableAddConfig, setIsEnableAddConfig] = useState<boolean>(false);

    // To show or hide future section.
    const [isQuestionDisabled, setIsQuestionDisabled] = useState<boolean>(futureSelectedQuestion.answerValue === null ? true : false);

    // State variable to activate the tabs.
    const [isCurrentTabActive, setIsCurrentTabActive] = useState<boolean>(true);

    let formattedDate = questionState.modifyDate && formatUtcDate(questionState.modifyDate);

    // Handles remove config button functionality.
    const handleRemoveConfig = () => {
        if (questionState.answerValue !== null) {
            setIsQuestionDisabled(true)
            Save(true);
            setIsRemoved(true);
        } 
    }

    // Handle cancel and remove config functionality.
    const handleCancelOrRemoveConfig = (event:any) => {
        const buttonTitle = event.target.innerText;
        if (buttonTitle === "Remove Config")
            handleRemoveConfig();
        futureSelectedQuestion.answerValue === null || buttonTitle === "Remove Config"  ? setIsQuestionDisabled(true) : setIsQuestionDisabled(false);
        setIsCurrentTabActive(true);
        setIsEnableAddConfig(false);
        setIsSaveEnabled(false);
        handleValueChange(null, false, '');
   }

    const handleFutureConfig = (ishandleChange: boolean) => {
        if (futureSelectedQuestion && futureSelectedQuestion.answerValue !== null) {
            setIsEnableAddConfig(true);
        }
        if (ishandleChange)
            handleValueChange(null, true, '');
    }

    // Update initial answer value when question changes
    useEffect(() => {
        setInitialAnswerValue(selectedQuestion.answerValue);
        if(!isRemoved)
            handleFutureConfig(false);
    }, [selectedQuestion, questionState]);

    // Checks if the passed value matched the regex pattern.
    const isValueMatchedToRegexPattern = (regexPattern: string, value: string): boolean => {
        return new RegExp(regexPattern).test(value?.toLowerCase());
    }

    //For value change 
    const handleValueChange = (value: string | null, isFutureConfig: boolean, question: any) => {
        if (question) {
            let isValidRegexAnswerValue: boolean = false;
            if (question.questiontype === "Dropdown") {
                if (question?.options?.split(',')?.includes(value)) {
                    isValidRegexAnswerValue = true;
                }
            }
            else {
                if (isValueMatchedToRegexPattern(question?.regexValidationPattern ?? '', value !== null ? value : '')) {
                    isValidRegexAnswerValue = true;
                }
            }
            question.isValidRegexValue = isValidRegexAnswerValue;
        }
        const targetQuestion = question !== null && question !== '' ? question : isFutureConfig ? futureSelectedQuestion : selectedQuestion;
        let answerValue = value !== null ? value : targetQuestion.answerValue;
        let previousAnswerValue = question !== null && question !== '' ? selectedQuestion.answerValue : answerValue;
        value !== null  ? setIsSaveEnabled(true) : setIsSaveEnabled(false);
        let effectiveDate = targetQuestion.effectiveFrom;
        if (question !== null && question !== '') {
            effectiveDate = getCurrentFormattedDate();
            if (question.benefitYear === year + 1) {
                previousAnswerValue = futureSelectedQuestion.answerValue;
                effectiveDate = getCurrentFormattedDate(new Date(2025, 0, 1));
            }
        }
        else {
            targetQuestion.isValidRegexValue = null;
        }
        const updatedState = {
            ...targetQuestion,
            effectiveFrom: effectiveDate,
            answerValue: answerValue
        };
        setQuestionState(updatedState);
    };



    //Save Functionality in Future Config
    const Save = (IsRemoved: boolean) => {
        localStorage.setItem("RemoveMessage", "Site answers removed successfully.");
        let isFutureConfig = questionState.effectiveFrom.includes((year + 1).toString());
        if (questionState.answerValue === initialAnswerValue && !IsRemoved && isFutureConfig) {
            setIsPopUpEnabled(true);

        }
        else {
            setIsPopUpEnabled(false);
            if (saveFutureConfig)
                questionState.effectiveFrom.includes((year + 1).toString()) ? saveFutureConfig(questionState, false, { activeInsuranceCarrier, planDetails, selectedQuestion, futureSelectedQuestion }, IsRemoved) : saveFutureConfig(questionState, true, { activeInsuranceCarrier, planDetails, selectedQuestion, futureSelectedQuestion }, false);
        }
    };

    // Handles the Add future config click.
    const addFutureConfigClick = () => {
        setIsQuestionDisabled(false);
        setIsEnableAddConfig(true);
        setIsCurrentTabActive(false);
        questionSection();
        handleValueChange(null, true, '');
    }

    // Handles future site config section.
    const handleFutureSiteConfigSection = () => {
        setIsQuestionDisabled(false);
        setIsEnableAddConfig(true);
        setIsCurrentTabActive(false);
        handleFutureConfig(true);
    }

    // Handles current site config section.
    const handleSiteConfigSection = () => {
        setIsQuestionDisabled(futureSelectedQuestion.answerValue === null ? true : false);
        setIsCurrentTabActive(true);
        questionSection()
        //if (initialAnswerValue && initialAnswerValue !== null) {
            handleValueChange(null, false, '');
        //}
    }

    // Navigates back to the SiteConfig page.
    const handleNavigateBack = () => {
        if (planDetails) {
            SetKeyToLocalStorage(CarrierDetailsActiveTab, "SiteConfigurations");
            navigate("/PlanDetails", { state: { insuranceCarrier: activeInsuranceCarrier, planDetails: planDetails } });
        }
        else {
            SetKeyToLocalStorage(CarrierDetailsActiveTab, "SiteConfigurations");
            navigate("/CarrierDetails", { state: { activeInsuranceCarrier: activeInsuranceCarrier } });
        }
    }


    const questionSection = () => {
        return <>
            <AlertPopup message={ SaveSiteConfig} />
            <Col xl={9}>
                <div className="benefit-title-section position-sticky">
                    <h4>Question Selected</h4>
                    <p className="last-updated position-static m-0">
                        {questionState.modifyUser !== null && questionState.modifyDate !== null ? (
                            <>
                                Last updated by {questionState.modifyUser} on {formattedDate}&nbsp;
                                <span className="time-zone show-popup show-tooltip plan-icon">
                                    <img className="img-fluid" src={Time} alt=" " /><span>EST</span>
                                    <span className="hover-popup left">The timings shown in the website are in Eastern Time Zone.</span>
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                    </p>
                </div>
                <div className="benefit-content-section">
                    <div className="question-main">
                        <div className="question">
                            <h6>
                                {questionState.question}
                                {questionState.isMandate ? <sup>*</sup> : <></>}
                                {questionState?.toolTip?.length > 0 ? <span className="show-popup ms-1">
                                    <img className="img-fluid" src={Info} alt=" " />
                                    <span className="hover-popup bottom">{questionState?.toolTip}</span>
                                </span>
                                    : <></>}
                            </h6>
                            {questionState?.questionType?.toString()?.toLowerCase() === 'dropdown' ? (
                                <div className="question-content">
                                    {questionState?.options?.toString()?.split(',')?.map((option: any, index: any) => (
                                        <Button
                                            key={index}
                                            className={`btn-outline ${questionState.answerValue === option ? 'active' : ''}`}
                                            onClick={() => handleValueChange(option, false, questionState)}
                                            disabled={isQuestionDisabled}
                                        >
                                            {option}
                                        </Button>
                                    ))}
                                </div>
                            ) : (
                                renderQuestionContent(questionState)
                            )}
                        </div>
                    </div>
                    
                </div>
                <div className="question shadow-none border-0">
                        <h6>Effective From {questionState.effectiveFrom}</h6>
                        <p>Date from which this configuration came into effect</p>
                    </div>
                {isCurrentTabActive && futureSelectedQuestion && futureSelectedQuestion.answerValue === null ?
                        <></> :
                        <div className="question shadow-none border-0">
                            <h6>Select Effective From<sup>*</sup></h6>
                        <p>Date on which this configuration should come into effect. Must be after today</p>
                        <Row>
                            <Col xs={4}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Select Effective From"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder=" " value={questionState.effectiveFrom} readOnly />
                                </FloatingLabel>
                            </Col>
                        </Row>                          
                        <Button className="btn-md btn-secondary" onClick={() => Save(false)} disabled={!isSaveEnabled || !questionState.isValidRegexValue}>Save</Button>
                        {questionState.benefitYear && questionState.benefitYear === year+1 ? <Button className="btn-md btn-outline-primary" onClick={handleCancelOrRemoveConfig}>{futureSelectedQuestion.answerValue !== null ? 'Remove Config' : 'Cancel'}</Button> : <></>}
                        </div>
                    }
                
            </Col>
        </>
    }

    // Render the question content.
    const renderQuestionContent = (question: any) => {
        return <Row>
            <Col xs={7} xxl={6}>
                <FloatingLabel label=" ">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        value={question.answerValue !== null ? question.answerValue : ''}
                        onChange={(event) => handleValueChange(event.target.value, false, questionState)}
                        style={getIsValidRegexValueForTextInput(questionState) ? { borderColor: '#dc3545' } : {}}
                        disabled={isQuestionDisabled}
                    />
                    {questionState.isValidRegexValue != null && !questionState.isValidRegexValue ? (
                        <span className="error-message">{questionState?.errorMessage}</span>
                    ) : null}
                </FloatingLabel>
            </Col>
            <Col xs={5} xxl={6}>
                {(question.question?.toLowerCase() === CardImage?.toLowerCase() && question.answerValue != null && question.answerValue !== '') ? <a className="preview custom-link" target="_blank" href={`${CDNCardImagePath}${question.answerValue}`}>Preview card image</a> : <></>}
                {(question.question?.toLowerCase() === ClientLogo?.toLowerCase() && question.answerValue != null && question.answerValue !== '') ? <a className="preview custom-link" target="_blank" href={`${CDNLogo}${question.answerValue}.png`}>Preview logo</a> : <></>}
            </Col>
        </Row>
    };

    // Gets the valid regex value for a text input.
    const getIsValidRegexValueForTextInput = (question: any) => {
        return question?.isValidRegexValue != null && question?.isMandate && !question?.isValidRegexValue
    }

    return (
        <>
        <SideBar/>
            <div className="page-content futuresite-page">
                <div className='page-title-section'>
                    <div className="page-top-left">
                        <Breadcrumb className="breadcrumbs d-none d-lg-block">
                            <Breadcrumb.Item active> </Breadcrumb.Item>
                        </Breadcrumb>
                        <h3><a onClick={handleNavigateBack}><img className="img-fluid cursor-pointer" src={BackArrow} alt=" " /></a>
                       <span>Future Site Configuration - {activeInsuranceCarrier !== undefined && planDetails !== undefined ? planDetails?.healthPlanName : activeInsuranceCarrier?.insuranceCarrierName} </span></h3>
                    </div>
                </div>
                <div className="tabslist-main">
                    <Tabs id="uncontrolled-tab-example"
                        className="tabs-list">
                        <div className="benefit-config main-content tab-pane active show">
                            <Row>
                                <Col xl={3}>
                                    <ul className="benefit-details future-site-list">
                                        <li>
                                            <a className={isCurrentTabActive ? "active" : ""} onClick={handleSiteConfigSection}>
                                                <span className="future-site-icon">
                                                    <img className="img-fluid" src={CheckCircle} alt=" " />
                                                </span>
                                                <span className="future-site-details">
                                                    <span className="future-site-type">Currently Active</span>
                                                    <span className="future-site-duration">{`Year - ${year}` }</span>
                                                </span>
                                            </a>
                                        </li>
                                        {isEnableAddConfig ?
                                            <li>
                                                <a className={!isCurrentTabActive ? "active" : ""} onClick={handleFutureSiteConfigSection}>
                                                    <span className="future-site-icon">
                                                        <img className="img-fluid" src={CheckCircle} alt=" " />
                                                    </span>
                                                    <span className="future-site-details">
                                                        <span className="future-site-type">
                                                            {futureSelectedQuestion && futureSelectedQuestion.answerValue !== null ? `From Jan 1, ${year + 1}` : 'New Site Configuration'}
                                                        </span>
                                                        <span className="future-site-duration">{futureSelectedQuestion && futureSelectedQuestion.answerValue !== null ? 'Next Year' : 'Not Configured Yet'}</span>
                                                    </span>
                                                </a>
                                            </li> : <></>}
                                    </ul>
                                    {/*{!isEnableAddConfig && futureQuestion && futureQuestion.answerValue === null  ?*/}
                                    {!isEnableAddConfig ?
                                        <a className='custom-link add-config text-decoration-none mt-4' onClick={addFutureConfigClick}>
                                            <img className="img-fluid me-2" src={Plus} alt=" " />
                                            <span>Add {year + 1} Config</span>
                                        </a> : <></>}
                                </Col>
                                {questionSection()}
                            </Row>
                        </div>
                    </Tabs>
                </div>
            </div>
            <Modal show={isPopUpEnabled} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton onClick={() => { setIsPopUpEnabled(false) }}>
                    <Modal.Title className="justify-content-center">Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>The answer provided is the same for both {year} and { year+1}. Please provide different answer to continue.</h5>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button className="btn-secondary" onClick={() => { setIsPopUpEnabled(false) }}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}