import NoSearchResults from "../../images/no-search-results.png";

export const NotFound = (props: any) => {
    return (<>
        <tr>
            <td colSpan={props.colSpan && props.colSpan} className="pe-none">
                <div className="no-results">
                    <img className="img-fluid" src={NoSearchResults} alt=" " />
                    <h6>{props.message && props?.message}</h6>
                    <p>We couldn't find what you are looking for</p>
                </div>
            </td>
        </tr>
    </>)
}