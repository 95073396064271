import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of the context values
interface UpdateAlertPopup {
    showAlertPopup: boolean;
    updateAlertPopup: (showPopup: boolean) => void;
}


// Create contexts with default values
const DataContext = createContext<UpdateAlertPopup | undefined>(undefined);

// Custom hook to use the context
export const useDataContext = (): UpdateAlertPopup => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

// Context Provider Component
interface DataProviderProps {
    children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    // State variable to show or hide alert popup
    const [showAlertPopup, setShowAlertPopup] = useState<boolean>(false);

    // Update functions for alert popup
    const updateAlertPopup = (showPopup: boolean) => {
        setShowAlertPopup(showPopup);
    };

    return (
        <DataContext.Provider value={{ showAlertPopup, updateAlertPopup }}>
            {children}
        </DataContext.Provider>
    );
};
