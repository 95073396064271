import React, { useEffect, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import { IEditData, IEditQuestionData, IQuestionsData } from '../../../Interfaces/ItemRules'
import {  InsertRule, UpdateRule, questionModalConstants, statuses, textTypes } from '../../../Constants/AppConstants'
import client from '../../../utils/ApiClient'
import { useMsal } from '@azure/msal-react'
import moment from 'moment'
import Scrollbars from 'rc-scrollbars';
interface  IModal{
    addItemRule:boolean,
    setAddItemRule:any,
    finalQuestions:IQuestionsData[]|null,
    inusuranceCarrierID:number,
    insuranceHealthPlanID:number,
    benefitYear:number,
    dataPlanId:number,
    isEdit:boolean,
    fetchSheetQuestionsAndBenefits:any,
    editData:IEditQuestionData|undefined,
    templateBenefitSheetId:number,
    effectiveStartDate:string|undefined;
    effectiveEndDate:string|undefined;
    getQuestionAndAnswers:any;
    groupName:any;
    approvedResponse:ISaveResponse[]|null;
    setApprovedResponse:any;
    itemOrCatalogData:ISaveResponse[]|null
}
interface ISaveResponse{
    questionID:number,
    answerValue:string,
    dataPlanID:number,
    isActive: boolean,
    createUser: string|undefined,
    createDate: string,
    modifyUser: string|undefined,
    modifyDate: string,
    versionNumber: string,
    dataAnswerID:number

}

interface IErrorResponse{
  questionID:number,
  errorMessage:string,
  validText:boolean
}
const QuestionsModal: React.FC<IModal>=(props)=>{

  // Get the current date and time using moment
  const currentDate = moment();

  // Format the current date in the desired format
  const formattedDate = currentDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');

  
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const userName = accounts[0]?.name;
  

 const parentQuestions =props.finalQuestions && props.isEdit? props.finalQuestions.filter((e:IQuestionsData)=>{
  return e.parentQuestionID == 0 ;
}): props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
  return e.parentQuestionID == 0 && e.answerValue == null;
});




 const [dataPlanId, setDataPlanId] = useState<IQuestionsData[]|null>();
 const [errorMessage,setErrorMessage] =useState<IErrorResponse[]|null>(null);

 const [childQuestions, setChildQuestions] = useState<IQuestionsData[]|null>();
 const [selectedParentQuestion,setSelectedParentQuestion] =useState<IQuestionsData|null>();

 const [saveResponse, setSaveResponse] = useState<ISaveResponse[]|null>(null);
 

     // State variable for parent QuestionId 
  const [parentQuestionsId, setParentQuestionsId] = useState<any>("select");

  const [stylingState,setStylingState] = useState("");
 
  const [subStylingState,setSubStylingState] = useState("");

  const [isApproved, setIsApproved] = useState(false);

 const handleParentQuestionChange = (value:number,isEdit:boolean)=>{
     const childQUestion = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
        return e.parentQuestionID == value
     });
     const selectedParentQuestion = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
        return e.questionID == value
     })[0];
     selectedParentQuestion && setSelectedParentQuestion(selectedParentQuestion)
     setParentQuestionsId(selectedParentQuestion?.questionID)
     childQUestion && setChildQuestions(childQUestion);
     if(!isEdit){
      setSaveResponse(null);
      setIsButtonDisabled(false);
     }
     setErrorMessage(null);
     setStylingState('');
     setSubStylingState('');
 }
  const subChild=(value:number)=>{
    return   props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
      return e.parentQuestionID == value
   });

 
  }

 useEffect(() => {
  if(props.isEdit && props.editData){
    
    parentQuestions && handleParentQuestionChange(Number(props.editData?.parentQuestionID),true)
   }
    
 }, [props])


 const setApprovedResponses =()=>{
  if(props.finalQuestions){
    const dpId = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
      return e.sourceTemplateID == questionModalConstants.sourceTemplateId ;
    });
    
    if(statuses.includes(dpId[0]?.versionStatus?.toLowerCase())){
      setIsApproved(true);
    
      props?.setApprovedResponse((prevResponses: ISaveResponse[] | null) => {
          if(prevResponses == null){
            prevResponses =[];
          }
       
            dpId.forEach((e:IQuestionsData)=>{
              const existingIndex = prevResponses && prevResponses.findIndex(
                response => response.questionID === e.questionID
              );
              if (existingIndex === -1) {
                if(!!e.answerValue){
                  const newResponse: ISaveResponse = {
                    dataAnswerID:0,
                    dataPlanID: 0,
                    questionID: e.questionID,
                    answerValue: e.answerValue,
                    isActive: true,
                    createUser: userName,
                    createDate: formattedDate,
                    modifyUser: userName,
                    modifyDate: formattedDate,
                    versionNumber: "VS1"
                  };
                  prevResponses && prevResponses.push(newResponse);
                }
     

              }
          
            });
            return prevResponses;
        });
        if(dpId.length == 0){
          setDataPlanId(null);
        }
        else{
          dpId[0].dataPlanID =0;
          setDataPlanId(dpId);
        }

    }
    else{
      if(dpId.length == 0){
        setDataPlanId(null);
      }
      else{
        setDataPlanId(dpId);
      }
     
    }
   

  }
 }

 useEffect(() => {
  setApprovedResponses();

}, [props.finalQuestions])
  useEffect(() => {
  
   if(props.isEdit && props.editData && dataPlanId){
      let saveResponse:ISaveResponse[]=[];
      props.editData?.editData?.forEach((e:IEditData)=>{
        if(e.answerValue){
        saveResponse.push({
            dataPlanID:dataPlanId ? dataPlanId[0]?.dataPlanID:0,
            questionID: e.questionID,
            answerValue: e.answerValue,
            isActive: true,
            createUser: userName,
            createDate: formattedDate,
            modifyUser: userName,
            modifyDate: formattedDate,
            versionNumber: "VS1",
            dataAnswerID:e.dataAnswerId
          })
        }
      });
      let questionIds= saveResponse.map(item => item.questionID);

      props?.setApprovedResponse((prevResponses: ISaveResponse[] | null)=>{
        if(prevResponses == null){
          return prevResponses
        }
        prevResponses = prevResponses.filter((prev:ISaveResponse)=>{
            return  !questionIds.includes(prev.questionID)
        })
        return prevResponses
      });
      setSaveResponse(saveResponse);
    }
    }, [props.editData,dataPlanId,props.isEdit])
 

 const saveRule =()=>{
 
  let previousResponses :ISaveResponse[] =[];
  let appRes:ISaveResponse[]=[];
  let itemCat:ISaveResponse[]=[];
  if(props?.approvedResponse && props?.approvedResponse?.length>0 && isApproved){
    appRes =  props?.approvedResponse;
    
    
  }
  if(props?.itemOrCatalogData && props?.itemOrCatalogData?.length>0 && isApproved){
    itemCat = props?.itemOrCatalogData;
  }


  const mergedArray = previousResponses.concat(appRes, itemCat);

  mergedArray.forEach(obj=>{
    obj.dataAnswerID =0;
  });
  saveResponse && saveResponse.forEach(obj=>{
    obj.dataAnswerID =0;
  });
   let insertData ={
    InsuranceCarrierID:props.inusuranceCarrierID,
    InsuranceHealthPlanID:props.insuranceHealthPlanID,
    TemplateSheetBenefitID:props.templateBenefitSheetId,
    EffectiveFrom: moment(props.effectiveStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
    EffectiveTo:moment(props.effectiveEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD') ,
    CreateUser:userName,
    BenefitYear: props.benefitYear,
    SourceTemplateId: questionModalConstants.sourceTemplateId,
    DataAnswersTypes:(mergedArray && mergedArray?.length>0) ? saveResponse?.concat(mergedArray) :saveResponse
   }

  client.post(InsertRule, insertData)
  .then((response) => {
      
    })
    .catch((error) => {
    })
    .finally(()=>{
      props.fetchSheetQuestionsAndBenefits();
      props.getQuestionAndAnswers();
      props.setAddItemRule(false);
      setSaveResponse(null);
      //props?.setApprovedResponse(null);
      setStylingState('');
      setSubStylingState('');
      setIsButtonDisabled(false)
      setErrorMessage(null)
    })

 }

 const editRule =()=>{
  client.post(UpdateRule, (props?.approvedResponse && props?.approvedResponse?.length>0) ? saveResponse?.concat(props?.approvedResponse) :saveResponse)
  .then((response) => {
      
    })
    .catch((error) => {
    })
    .finally(()=>{
      props.fetchSheetQuestionsAndBenefits();
      props.setAddItemRule(false);
      setSaveResponse(null);
      setStylingState('');
      setSubStylingState('')
      setIsButtonDisabled(false)
      setErrorMessage(null)
    })
   
 }
 
     // Gets the valid regex value for a text input.
     const isValueMatchedToRegexPattern = (regexPattern: string, value: string): boolean => {
      //If Regex is null, then input can be anything
      if(!!regexPattern){
        return new RegExp(regexPattern).test(value?.toLowerCase());
      }
      else{
        return true;
      }
  }

 const handleValueChange = (data: IQuestionsData, value: string,isParent:boolean,isChild:boolean) => {
  if((data?.questionType?.toLowerCase() == questionModalConstants.dropdown) || isValueMatchedToRegexPattern(data.regexValidationPattern,value)|| value == ""){
    setErrorMessage((prev:IErrorResponse[]|null)=>{
      if (prev === null) {
        prev = [];
      }
      if(value?.toLowerCase() == questionModalConstants.no){
        let childQuestionIds = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
          return e.parentQuestionID ==  data.questionID
        }).map((e:IQuestionsData)=>{
          return e.questionID;
        });
        let subChildQuestionIds = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
          return childQuestionIds?.includes(e.parentQuestionID);
        }).map((e:IQuestionsData)=>{
          return e.questionID;
        });
        let mergedChildIds = (childQuestionIds || [])?.concat(subChildQuestionIds || []);
        const updatedResponses = prev.filter(
          response => !mergedChildIds.includes(response.questionID) 
        );
        return updatedResponses;

      }
      const existingIndex = prev.findIndex(
        response => response.questionID === data.questionID
      );
      if (existingIndex !== -1) {
        const updatedResponses = prev.filter(
          response => response.questionID !== data.questionID
        );
        return updatedResponses;
      }else{
        return prev;
      }
      
    });
  }
  else{
    setErrorMessage((prev:IErrorResponse[]|null)=>{
      if (prev === null) {
        prev = [];
      }
      let data1 ={
        questionID:data.questionID,
        errorMessage:data.errorMessage,
        validText:false
      }
      const existingIndex = prev.findIndex(
        response => response.questionID === data.questionID
      );
       if (existingIndex !== -1) {
        return prev;
      }else{
        return prev.concat(data1);
      }
      
    });

  }

    setSaveResponse((prevResponses: ISaveResponse[] | null) => {
      if (prevResponses === null) {
        prevResponses = [];
      }
        const existingIndex = prevResponses.findIndex(
            response => response.questionID === data.questionID
          );
          let childQuestionsCount = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
            return e.parentQuestionID ==  data.questionID
           });

           //---
           //--clearing approved Responses
           props?.setApprovedResponse((prevResponses1: ISaveResponse[] | null)=>{
            if(prevResponses1 == null){
              return prevResponses1
            }
            prevResponses1 = prevResponses1.filter((prev:ISaveResponse)=>{
                return prev.questionID !== data.questionID
            })
            return prevResponses1
          });
           //---
          if (existingIndex !== -1) {
            // If the response exists, update the answerValue property
            let updatedResponses = [...prevResponses];
            updatedResponses[existingIndex].answerValue = value;
            //----
            if(value?.toLowerCase() == questionModalConstants.no){
              let childQuestionIds = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
                  return e.parentQuestionID ==  data.questionID
              }).map((e:IQuestionsData)=>{
                return e.questionID;
              });
              let subChildQuestionIds = props.finalQuestions && props.finalQuestions.filter((e:IQuestionsData)=>{
                return childQuestionIds?.includes(e.parentQuestionID);
              }).map((e:IQuestionsData)=>{
                return e.questionID;
              });
              let mergedChildIds = (childQuestionIds || [])?.concat(subChildQuestionIds || []);
              updatedResponses = updatedResponses.map(response => {
                if (mergedChildIds.includes(response.questionID)) {
                  return { ...response, answerValue: '' };
                }
                return response;
              });
              if(stylingState == questionModalConstants.questionnairedown && isParent && childQuestionsCount && childQuestionsCount?.length>0){
                setStylingState(questionModalConstants.questionnaireup);
              }
              if(subStylingState == questionModalConstants.questionnairedownsubchild&& isChild && childQuestionsCount && childQuestionsCount?.length>0){
                setSubStylingState(questionModalConstants.questionnaireupsubchild);
              }
           }
            else if(value?.toLowerCase() == questionModalConstants.yes && isParent && childQuestionsCount && childQuestionsCount?.length>0){
              
                setStylingState(questionModalConstants.questionnairedown);
            }
            else if(value?.toLowerCase() ==  questionModalConstants.yes && isChild && childQuestionsCount && childQuestionsCount?.length>0){
              setSubStylingState(questionModalConstants.questionnairedownsubchild);
            }
         
          return updatedResponses;
          } else {
            // If the response doesn't exist, add a new response
            const newResponse: ISaveResponse = {
              dataAnswerID:0,
              dataPlanID:dataPlanId ? dataPlanId[0]?.dataPlanID:0,
              questionID: data.questionID,
              answerValue: value,
              isActive: true,
              createUser: userName,
              createDate: formattedDate,
              modifyUser: userName,
              modifyDate: formattedDate,
              versionNumber: "VS1"
            };
             if(value?.toLowerCase() == questionModalConstants.yes && isParent && childQuestionsCount && childQuestionsCount?.length>0){
                 setStylingState(questionModalConstants.questionnairedown);
              
             }
             if(value?.toLowerCase() == questionModalConstants.yes && isChild && childQuestionsCount && childQuestionsCount?.length>0){
              setSubStylingState(questionModalConstants.questionnairedownsubchild);
           
             }
            return prevResponses.concat(newResponse);
          }    
    });
 
 
  };

  const isToDisplayChild =(questionId:number|undefined)=>{
      let childData =  saveResponse && saveResponse?.filter((e:ISaveResponse)=>{
        return e.questionID == questionId;
      });
           if(childData && childData[0]?.answerValue?.toLowerCase() == questionModalConstants.no){
          return false;
      }
      else{
        return childData && !!childData[0]?.answerValue
      }
      
  }
  const isErrorMessage =(questionId:number)=>{
    let error = errorMessage?.filter((e:IErrorResponse)=>{
       return e.questionID == questionId
    });
    if(error && error?.length>0){
      return error[0]?.validText
    }

    else{
      return true;
    }
    
  }

  const getChildQuestionCount=(data: IQuestionsData[]|null, selectedParentQuestionId: number): number =>{
   
    let count =0;
    if(data){
      const children = data.filter(item => item.parentQuestionID === selectedParentQuestionId);
      count =count+children.length;
      for(const child of children){
          let childs = saveResponse?.filter((save:ISaveResponse)=>{
            return save.questionID == child.questionID 
          });
          let answerValue = childs && childs[0]?.answerValue
         
          if(answerValue?.toLowerCase() != questionModalConstants.no){
            const subChildren = data.filter(item => item.parentQuestionID === child.questionID);
            count =count + subChildren.length
          }
      }

    }
  
    return count;
  }
 const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const buttonDisabled =()=>{
    let saveRes = saveResponse?.filter((e:ISaveResponse,index:number)=>{
      return e.questionID == selectedParentQuestion?.questionID
    });
    let value = saveRes && saveRes[0]?.answerValue
    if(value?.toLowerCase() == questionModalConstants.no){

      setIsButtonDisabled(true);

    }else{
      let questionsCount = getChildQuestionCount(props.finalQuestions,selectedParentQuestion?.questionID?selectedParentQuestion?.questionID:0);
      let saveResponseCount = saveResponse?.filter((save:ISaveResponse)=>{
           return  save?.answerValue?.toLowerCase()?.trim()!='';
      });
      let isValid =  (questionsCount+1) == saveResponseCount?.length;
      setIsButtonDisabled(isValid);
    }
}
useEffect(() => {
  if(saveResponse){
    buttonDisabled();
  }
     
}, [saveResponse])

  return (
    <>
      <Modal  show={props.addItemRule}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        className="add-catalog">
        <Modal.Header
          closeButton
          onClick={() => {
            props.setAddItemRule(false);
            setSaveResponse(null);
            setStylingState('');
            setSubStylingState('')
            setIsButtonDisabled(false)
            setErrorMessage(null)
            setSelectedParentQuestion(null);
            setParentQuestionsId("select")
             setChildQuestions(null);
          }}
              >
                  <h3 className="modal-title">{props.isEdit && props.editData && dataPlanId ? "Edit" : "Add"} {props?.groupName}</h3>     
       </Modal.Header>
        <Modal.Body className="add-carrier">
        {<><Row>
              <Col xs={10}>
                <FloatingLabel controlId="floatingSelect" label={"Select Rule"}>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(event) =>
                      handleParentQuestionChange(Number(event.target.value),false)
                    }
                    disabled={props.isEdit}
                    value={parentQuestionsId}
                  >
                    <option className={`${parentQuestionsId=="select" ? '' : "d-none"}`} value="select">Please select your {props.groupName}</option>
                    {parentQuestions ?
                      parentQuestions.map(
                        (key: IQuestionsData, index: number) => {
                          return (
                            <option value={key.questionID}>{key.question}</option>
                          );
                        }
                      ):null}
                  </Form.Select>
                </FloatingLabel>
                <div className="question-content">
                       {selectedParentQuestion?.questionType && !(selectedParentQuestion?.questionType?.toLowerCase() == "dropdown") ?
                            <Row>
                            <Col xs={7} xxl={6}>
                                <FloatingLabel label={selectedParentQuestion?.questionInlineHelp}>
                                    <Form.Control type="text"  placeholder={selectedParentQuestion?.questionInlineHelp} value={saveResponse ? saveResponse?.filter((e:ISaveResponse)=>{
                                        return e.questionID == selectedParentQuestion?.questionID
                                    })[0]?.answerValue:''}
                                    onChange={(event) => handleValueChange(selectedParentQuestion, event.target.value,false,false)} />
                                </FloatingLabel>
                               <span className="error-message">{isErrorMessage(selectedParentQuestion?.questionID)?'':selectedParentQuestion?.errorMessage}</span>
                            </Col>
                            </Row>:<>
                            {
                                selectedParentQuestion?.options && selectedParentQuestion?.options?.toString().length > 0 &&
                                selectedParentQuestion?.options?.toString()?.split(',')?.map((value: any, indexParent: any) => {

                                    let data = saveResponse?.filter((save:ISaveResponse)=>{
                                        return save.questionID == selectedParentQuestion.questionID
                                     })[0];

                                    return <Button key={indexParent} className={`btn-outline ${data?.answerValue?.toLowerCase() === value.toLowerCase()?'active':''}`}
                                    onClick={() => handleValueChange(selectedParentQuestion, value,true,false)}
                                    >{value}</Button>
                            })
                            }
                          </>}
                </div>
              </Col>
            </Row>
            
                
           
                      <div className={`questionnaire-container ${stylingState} `}>
                          <Scrollbars
                              autoHide
                              autoHideTimeout={100}
                              autoHideDuration={200}
                              thumbMinSize={80}
                              autoHeight
                              autoHeightMin={0}
                              autoHeightMax={400}
                          >
                {isToDisplayChild(selectedParentQuestion?.questionID) && childQuestions ?childQuestions.map((e:IQuestionsData,indexChild:number)=>{
                    // let subChildQuestions =
                    return <div key ={indexChild} className="question">
                         <h6>{e.question}<sup>*</sup></h6>
                        
                         {
                              !(e?.questionType?.toLowerCase() == questionModalConstants.dropdown)  ?
                            <Row>
                            <Col xs={7} xxl={6}>
                                <FloatingLabel label={e?.questionInlineHelp}>
                                    <Form.Control type="text" id={"textbox"+indexChild} placeholder="" value={saveResponse ? saveResponse?.filter((el:ISaveResponse)=>{
                                        return el.questionID == e?.questionID
                                    })[0]?.answerValue:''}
                                      onChange={(event) => handleValueChange(e, event.target.value,false,false)}/>
                                       <span className="error-message">{isErrorMessage(e?.questionID)?'':e?.errorMessage}</span>
                                </FloatingLabel>
                            </Col>
                          </Row>:<div className="question-content">
                            {
                                e?.options && e?.options?.toString().length > 0 &&
                                e?.options?.toString()?.split(',')?.map((value: any, index: any) => {

                                    let data = saveResponse?.filter((save:ISaveResponse)=>{
                                        return save.questionID == e.questionID
                                     })[0];
                                     
                                   return  <Button key={index} className={`btn-outline ${data?.answerValue?.toLowerCase() === value.toLowerCase()?'active':''}`} onClick={()=>{ handleValueChange(e,value,false,true)}}>{value}</Button>
                                })
                            }
                          </div>
                         }
                          <div className={` ${subStylingState} `}>
                         {isToDisplayChild(e.questionID) && subChild(e.questionID)?.map((eChild:IQuestionsData,indexSubChild:number)=>{
                              return <div className={`question`} >
                                <h6>{eChild.question}<sup>*</sup></h6>
                                {
                                         !(eChild?.questionType?.toLowerCase() == questionModalConstants.dropdown) ?
                                        <Row>
                                        <Col xs={7} xxl={6}>
                                            <FloatingLabel label={eChild?.questionInlineHelp}>
                                                <Form.Control type="text" id={"textbox"+indexSubChild} placeholder="" value={saveResponse ? saveResponse?.filter((el:ISaveResponse)=>{
                                                    return el.questionID == eChild?.questionID
                                                })[0]?.answerValue:''}
                                                  onChange={(event) => handleValueChange(eChild, event.target.value,false,false)}/>
                                                   <span className="error-message">{isErrorMessage(eChild?.questionID)?'':eChild?.errorMessage}</span>
                                            </FloatingLabel>
                                        </Col>
                                      </Row>:<div className="question-content">
                                        {
                                            eChild?.options && eChild?.options?.toString().length > 0 &&
                                            eChild?.options?.toString()?.split(',')?.map((value: any) => {

                                                let data = saveResponse?.filter((save:ISaveResponse)=>{
                                                    return save.questionID ==eChild.questionID
                                                })[0];
                                                
                                              return  <Button key={indexSubChild} className={`btn-outline ${data?.answerValue?.toLowerCase() === value.toLowerCase()?'active':''}`} onClick={()=>{ handleValueChange(eChild,value,false,false)}}>{value}</Button>
                                            })
                                        }
                                      </div>
                                    }
                                </div>
                         })}
                         </div>
                    
                        </div>

                }) : ''}
             </Scrollbars>
            </div>
          </>}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button
            className={`btn-secondary ${isButtonDisabled?'':'disabled'}`}
            onClick={() => {
              
              //saveRule()
              props.isEdit
                ? isApproved?saveRule():editRule()
                : saveRule();
            }}
            disabled={(errorMessage && errorMessage?.length>0)?true:false}
          >
            { props.isEdit?"Edit Rule":"Save Rule"}
          </Button>
          <Button
            className="btn-outline-primary"
            onClick={ ()=>{
              setSaveResponse(null);
              setStylingState('');
              setSubStylingState('')
              props.setAddItemRule(false);
              setSelectedParentQuestion(null);
              setParentQuestionsId("select");
              setIsButtonDisabled(false)
              setChildQuestions(null);
              setErrorMessage(null)}}
          >
            Cancel
          </Button>
        </Modal.Footer>
        
    </Modal>
    </>
  )
}

export default QuestionsModal