import ListGroup from 'react-bootstrap/ListGroup';
import ReactHtmlParser from 'html-react-parser';
import Facebook from "../../images/Facebook.svg";
import LinkedIn from "../../images/LinkedIn.svg";
import YouTube from "../../images/YouTube.svg";
import BBB from "../../images/BBB.svg";
import NCQA from "../../images/NCQA.png";
import './Footer.scss';

export const Footer = () => {
    return (<>
        <footer>
            <div className="footer-left">
                <img className="img-fluid mb-5" src="https://nationscdn.azureedge.net/nb-container/NationsLogos/NB-logo-horiz-rev.svg" width={224} height={34} alt=" " />
                <div className="footer-left-inner">
                    <div className="operations">
                        <div className="questions">
                            <h5>Questions?</h5>
                            <p>Call: <u className="nobr"><a className="text-white" href="tel:877-439-2665">877-439-2665</a></u> (TTY: 711)</p>
                        </div>
                        <p className="mb-4"><span>Hours of operation:</span>24 hours per day, 7 days per week,<br />365 days a year</p>
                        <p><span>NationsBenefits, LLC.<br />1700 North University Drive,<br />Plantation, FL 33322</span></p>
                        <ListGroup className="social-media">
                            <ListGroup.Item action href="https://www.facebook.com/nbhealthtech" target="_blank" className="facebook" aria-label="Facebook"><img className="img-fluid" src={Facebook} alt="Facebook" /></ListGroup.Item>
                            <ListGroup.Item action href="https://www.linkedin.com/company/nbhealthtech/" target="_blank" className="inkdin" aria-label="LinkedIn"><img className="img-fluid" src={LinkedIn} alt="LinkedIn" /></ListGroup.Item>
                            <ListGroup.Item action href="https://www.youtube.com/channel/UCnGoD5x6_1efnPd7wrSWZFQh" target="_blank" className="youtube" aria-label="YouTube"><img className="img-fluid" src={YouTube} alt="Youtube" /></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="footer-links">
                        <div className="footer-links-inner">
                            <div className="features">
                                <h5>Features</h5>
                                <div className="links-list">
                                    <ListGroup>
                                        <ListGroup.Item action href="#" role="link">Authentication</ListGroup.Item>
                                        <ListGroup.Item action href="#" role="link">Product Management</ListGroup.Item>
                                        <ListGroup.Item action href="#" role="link">Order Management</ListGroup.Item>
                                        <ListGroup.Item action href="#" role="link">Benefit Card</ListGroup.Item>
                                        <ListGroup.Item action href="#" role="link">Management Disclaimer</ListGroup.Item>
                                    </ListGroup>
                                    <ListGroup>
                                        <ListGroup.Item action href="#" role="link">Agent Services</ListGroup.Item>
                                        <ListGroup.Item action href="#" role="link">Application Services</ListGroup.Item>
                                        <ListGroup.Item action href="#" role="link">Rewards</ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                            <div className="legal">
                                <h5>Legal</h5>
                                <ListGroup>
                                    <ListGroup.Item action href="#" role="link">Terms & Conditions</ListGroup.Item>
                                    <ListGroup.Item action href="#" role="link">Privacy Policy</ListGroup.Item>
                                    <ListGroup.Item action href="#" role="link">HIPAA Rights</ListGroup.Item>
                                    <ListGroup.Item action href="#" role="link">Compliance</ListGroup.Item>
                                    <ListGroup.Item action href="#" role="link">Non Discrimination Notice</ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                        <p className="copyrights d-none d-md-block">{ReactHtmlParser('&copy;')}{(new Date().getFullYear())} NationsBenefits, LLC. and NationsOTC, LLC. All rights reserved.<br />
                            NationsOTC is a registered trademark of NationsOTC, LLC. Other marks are the property of their respective owners.  </p>
                    </div>
                </div>
            </div>
            <div className="footer-right">
                <div className="footer-logos">
                    <img className="img-fluid me-3" src={BBB} alt="BBB" />
                    <img className="img-fluid" src={NCQA} alt="NCQA" />
                </div>
                <div className="inquiries benefit-related">
                    <p>For benefit-related inquiries:</p>
                    <h6>Call: <u className="nobr"><a className="text-white" href="tel:877-284-0931">877-284-0931</a></u> <span>(TTY: 711)</span></h6>
                </div>
                <div className="inquiries card-related">
                    <p>For card-related inquiries:</p>
                    <h6>Call: <u className="nobr"><a className="text-white" href="tel:877-284-0931">877-284-0931</a></u> <span>(TTY: 711)</span></h6>
                </div>
            </div>
            <p className="copyrights d-md-none">{ReactHtmlParser('&copy;')}{(new Date().getFullYear())} NationsBenefits, LLC. and NationsOTC, LLC. All rights reserved.<br />
                NationsOTC is a registered trademark of NationsOTC, LLC. Other marks are the property of their respective owners.  </p>
        </footer>
    </>)
}

