import axios, { AxiosError } from "axios";
import { getStorageValue } from "../Constants/AppConstants";

const client = axios.create({
  headers: { "Content-Type": "application/json" },
});

client.interceptors.request.use(
  (request: any) => {
    const accessToken = getStorageValue("accesstoken", "no-json");
    if (accessToken && request.headers) {
      const token = `Bearer ${accessToken}`;
      request.headers.Authorization = `${token}`;
    }

    return request;
  },

  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default client;
