import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the callback function
type SaveFutureConfig = (data: any, isFutureConfig: boolean, requestData: any, IsRemoved: boolean) => void;
// Define the type for the context value
interface CallbackContextType {
    saveFutureConfig: SaveFutureConfig | null;
    setSaveFutureConfig: React.Dispatch<React.SetStateAction<SaveFutureConfig | null>>;
}

// Create a default value for the context
const defaultContextValue: CallbackContextType = {
    saveFutureConfig: null,
    setSaveFutureConfig: () => { }  
};

const CallbackContext = createContext<CallbackContextType>(defaultContextValue);

interface CallbackProviderProps {
    children: ReactNode;
}

export const CallbackProvider: React.FC<CallbackProviderProps> = ({ children }) => {
    const [saveFutureConfig, setSaveFutureConfig] = useState<SaveFutureConfig | null>(null);

    return (
        <CallbackContext.Provider value={{ saveFutureConfig, setSaveFutureConfig }}>
            {children}
        </CallbackContext.Provider>
    );
};

export const useCallbackContext = () => useContext(CallbackContext);
