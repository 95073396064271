/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { SideBar } from '../SideBar';
import Filter from "../../images/filters-primary.svg";
import Search from "../../images/search-small.svg";
import TimeLeft from "../../images/time-left.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import './Review.scss';
import DownArrow from "../../images/down-arrow.svg";

export const Review = () => {

    // State variable for holding key.
    const [key, setKey] = useState(0);

    // Function to receive and update the key from props.
    const setKeyOfFeatureList = (key: any) => {
        setKey(key)
    }

    // State variable for holding key.
    const [Sitekey, setSiteKey] = useState(101);

    // Function to receive and update the key from props.
    const setKeyOfSite = (key: any) => {
        setSiteKey(key)
    }


    // Add category Section Const.
    const [ShowCatagory, setShowCatagory] = useState(false);
    const handleActivityClose = () => {
        setShowCatagory(false);
    }

    return (
        <div className="d-flex overflow-hidden review-page">
            <SideBar />
            <div className="page-content">
                <div className='page-title-section'>
                    <h3>Review Submissions (20)</h3>
                </div>
                <div className="page-content-bottom">
                    <div className="table-actions">
                        <div className="activity-buttons">
                            <Button className="btn-outline active">All</Button>
                            <Button className="btn-outline">Draft</Button>
                            <Button className="btn-outline">In Review</Button>
                            <div className="filter-section">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="filters">
                                        <img className="img-fluid me-2" src={Filter} alt=" " />
                                        <span>Filters</span>
                                        <div className="filter-notification"></div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="filter-dropdown">
                                        <h5 className="mb-3">Filter by</h5>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="Status" className="form-field w-100">
                                                <input className="form-control search-input" type="search" placeholder="Status" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                                                <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />New
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Draft
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Approved
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Published
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" className="disabled">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Rejected
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="CreatedBy" className="form-field w-100">
                                                <input className="form-control search-input" type="search" placeholder="Created By" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                                                <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Aaron Philips
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />David Warner
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Edward May
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Finn Lambert
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />Joel Perry
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="BenefitYear" className="form-field w-100">
                                                <input className="form-control search-input" type="search" placeholder="Benefit year" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                                                <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />2023
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    <div className="input-box">
                                                        <Form.Check aria-label="option 1" className="form-check" />2024
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="filter-buttons">
                                            <Button className="btn-secondary btn-sm" >Approve</Button>
                                            <Button className="btn-sm btn-outline-primary">Reject</Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <span className="filter-result">Showing 0 carriers</span>
                        </div>
                        <div className="form-field table-search features-search">
                            <input className="form-control search-input" type="search" placeholder="Search Carriers" aria-label="Search" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
                            <img className="img-fluid me-2" src={Search} alt=" " />
                        </div>
                    </div>

                    <div className="table-responsive table-radius">
                        <Table className='table-hover'>
                            <thead>
                                <tr>
                                    <th>For Review</th>
                                    <th>Review Item</th>
                                    <th>Submitted By</th>
                                    <th>Submitted On</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Network PlatinumPlus Pharmacy (PPO)</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>BMC Health System, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Aware Integrated, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-orange">P</span> <span>Network PlatinumPlus Pharmacy (PPO)</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Resubmitted Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-orange">P</span> <span>BMC Health System, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Resubmitted Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Aware Integrated, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-orange">P</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Resubmitted Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status approved">Approved</span><span><img className="img-fluid" src={TimeLeft} alt=" " />2 days</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>BMC Health System, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-orange">P</span> <span>Aware Integrated, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Resubmitted Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-orange">P</span> <span>Network PlatinumPlus Pharmacy (PPO)</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Resubmitted Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>BMC Health System, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Aware Integrated, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status approved">Approved</span><span><img className="img-fluid" src={TimeLeft} alt=" " />2 days</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>BMC Health System, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Aware Integrated, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Network PlatinumPlus Pharmacy (PPO)</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>BMC Health System, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>H3653-806 Paramount EGWP- STRS Elite B Only OH</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status review">Resubmitted Approval Pending</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="action action-grey">C</span> <span>Cambia Health Solutions, Inc.</span>
                                    </td>
                                    <td>Site Configuration </td>
                                    <td>David Warner</td>
                                    <td>Apr 22, 2023 |  12:50 AM </td>
                                    <td><span className="status approved">Approved</span><span><img className="img-fluid" src={TimeLeft} alt=" " />2 days</span></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}