import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import './WhyNations.scss';
import WhyNationsImg from "../../images/why-nations.jpg";
import Growth from "../../images/growth.svg";
import MemberSatisfaction from "../../images/member-satisfaction.svg";
import HealthOutcomes from "../../images/health-outcomes.svg";
import Cost from "../../images/cost.svg";

export const WhyNations = () => {
    return (<>
        <section className="why-nations">
            <Row>
                <Col xl={5}>
                    <div className="nations-img">
                        <img className="img-fluid" src={WhyNationsImg} alt=" " />
                    </div>
                </Col>
                <Col xl={7}>
                    <div className="why-nations-content">
                        <h2>Why NationsBenefits?</h2>
                        <p>Partnering with a leading supplemental benefits administrator, such as NationsBenefits, is a key component for successfully:</p>
                        <ListGroup as="ul" horizontal>
                            <ListGroup.Item as="li">
                                <span className="img-section">
                                    <img className="img-fluid" src={Growth} alt="Enabling Growth and Retention" />
                                </span>
                                <p>Enabling Growth and Retention</p>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <span className="img-section">
                                    <img className="img-fluid" src={MemberSatisfaction} alt="Ensuring Member Satisfaction" />
                                </span>
                                <p>Ensuring Member Satisfaction</p>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <span className="img-section">
                                    <img className="img-fluid" src={HealthOutcomes} alt="Improving Health Outcomes" />
                                </span>
                                <p>Improving Health Outcomes</p>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <span className="img-section">
                                    <img className="img-fluid" src={Cost} alt="Reducing Costs" />
                                </span>
                                <p>Reducing Costs</p>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </section>
    </>
    )
}