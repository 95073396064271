import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { GetKeyValueFromLocalStorage } from '../../Constants/LocalStorage';
import "./AccessDenied.scss";

// Access denied component.
export const AccessDenied = () => {

	// Use history.
	const navigate = useNavigate();

	return (
		<div className="access-denied-sec">
			<div className="access-denied-inner">
				<div className="logo text-center">
					<img className="img-fluid" src="https://nationscdn.azureedge.net/nb-container/Nations_logos/NationsBenefits-logo-stack-rev.svg" alt=" " />
				</div>
				<div className="access-denied-box text-center">
					<h2>Hi {GetKeyValueFromLocalStorage("username")}</h2>
					<p>Access denied. <br />Please contact administrator.</p>
					<Button className="btn-secondary" onClick={() => navigate("/")} >Ok</Button>
				</div>
			</div>
		</div>
	);
};
