import FutureSite from "../../images/future-site.svg";
import { Alert } from "react-bootstrap";
import AlertSuccess from "../../images/alert-success.svg";
import { useEffect } from "react";
import { useDataContext } from "../../context/DataContext";
import Approved from "../../images/approved.svg";
import InProgress from "../../images/inprogress.svg";
import { futureYear, year } from "../../Constants/AppConstants"
import moment from "moment";

export const FutureConfigIcon = (props:any) => {
    return (<>
        <span className='show-popup'>
            <img className="img-fluid ms-2 cursor-pointer" src={FutureSite} width={20} height={20} />
            <span className={`hover-popup ${props.isHoverAlignedLeft ?'left':'bottom'}` } >{futureYear} Configs Available</span>
        </span>
    </>)
}

export const AlertPopup = (props:any) => {

    const { showAlertPopup, updateAlertPopup } = useDataContext();

    // Handles the banner/alert popup.
    useEffect(() => {
        setTimeout(() => {
            updateAlertPopup(false);
        }, 3000)
    }, [showAlertPopup])

    let message = localStorage.getItem("RemoveMessage") !== null && localStorage.getItem("RemoveMessage") !== undefined && localStorage.getItem("RemoveMessage") !== '' ? localStorage.getItem("RemoveMessage") :props.message;
    return (<>
        <Alert className={showAlertPopup ? '' : "d-none"} variant="success" dismissible>
            <span className="alert-left d-flex align-items-center">
                <img
                    className="img-fluid alert-icon me-2"
                    src={AlertSuccess}
                    width={18}
                    height={18}
                    alt=" "
                />
                <span>{message}</span>
            </span>
        </Alert>
    </>)
}

export const InProgressIcon = () => {
    return (<>
        <img className="img-fluid me-2" src={InProgress} alt=" " />
    </>)
}

export const ApprovedIcon = () => {
    return (<>
        <img className="img-fluid me-2" src={Approved} alt=" " />
    </>)
}

// Get the current date and time using moment
const currentDate = moment();

// Function to get the date based on the benefit year
export const getBenefitDate = (benefitYear:number=year) => {
    let formattedDate = currentDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    if (benefitYear === year) {
        formattedDate= currentDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    } else if (benefitYear === year+1) {
        const futureDate = moment(`${year + 1}-01-01`, 'YYYY-MM-DD');
        formattedDate= futureDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }
    return formattedDate;
};