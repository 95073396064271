import { SideBar } from '../SideBar';
import { Footer } from './Footer';
import { HomeInfo } from './HomeInfo';
import { OurFeatures } from './OurFeatures';
import { WhyNations } from './WhyNations';

export const Home = () => {
    return (<>
        <SideBar />
        <section className="page-content home-page">
            <HomeInfo />
            <OurFeatures />
            <WhyNations />
            <Footer />
        </section>
    </>
    )
}