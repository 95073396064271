import { Alert, Breadcrumb, Button, Dropdown, Form, ListGroup, Modal, Tab, Tabs } from "react-bootstrap"
import { SideBar } from "../SideBar"
import RedCross from "../../images/red-cross.svg";
import GreenTick from "../../images/green-tick.svg";
import Time from "../../images/time.svg";
import AlertSuccess from "../../images/alert-success.svg";
import { BenefitConfig } from "./BenefitConfig"
import { PlanInformation } from "./PlanInformation"
import { SiteConfig } from "./SiteConfig"
import BackArrow from "../../images/back-arrow.svg";
import View from "../../images/view.svg";
import SubmitIcon from "../../images/send-white.svg";
import DownArrow from "../../images/down-white.svg";
import { useLocation, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { IGroupNames, ReviewStatus, StatusModel } from "../../Interfaces/ReviewStatus";
import { Admin, Approve, ApprovedStatus, DraftStatus, getSelectedRole, Manager, PlanDetailsActiveTab, ReviewedStatus, SaveBenefitConfig, Submit, SubmitOrApproveSiteConfigurationsURL, SubmittedStatus, SystemAdmin, year } from "../../Constants/AppConstants";
import client from "../../utils/ApiClient";
import { GetKeyValueFromLocalStorage, SetKeyToLocalStorage } from "../../Constants/LocalStorage";
import { ApprovedIcon, FutureConfigIcon, InProgressIcon } from '../../components/Common/CommonComponents';

// Plan details.
export const PlanDetails = () => {


    // Uselocation from react router dom.
    const location = useLocation();

    // Active insurance carrier.
    const { insuranceCarrier, planDetails } = location.state ?? 0;

    // Use navigation.
    const navigate = useNavigate();

    // State variable for is  approved or published.
    const [isApprovedOrPublished, setIsApprovedOrPublished] = useState<boolean>(false);

    // State variable for submitted status.
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    // State variable for draft status.
    const [isDraft, setIsDraft] = useState<boolean>(false);

    // State variable for draft status.
    const [isAtleastOneSubmitted, setAtleastOneSubmitted] = useState<boolean>(false);


    // State variable for button title.
    const btnTitle: string =
        getSelectedRole()?.toString()?.toLowerCase() === Admin?.toString()?.toLowerCase()
            || getSelectedRole()?.toString()?.toLowerCase() === SystemAdmin?.toString()?.toLowerCase() ?
            Approve :
            getSelectedRole()?.toString()?.toLowerCase() === Manager?.toString()?.toLowerCase() ?
                Submit : Submit;

    // Gets the button title.
    const getBtnTitle = () => {
        return btnTitle === Approve ? ApprovedStatus : btnTitle === Submit ? SubmittedStatus : DraftStatus
    }

    // State varible for successful configuration alert.
    const [saveSiteConfigurationAlert, setSaveSiteConfigurationAlert] = useState<boolean>(false);

    // State variable for is acknowledged.
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);

    //State vraible for Benefit acknowledgement
    const [isBenefitAcknowledged, setIsBenefitAcknowledged] = useState<boolean>(false);

    // State variable for review statuses.
    const [reviewStatuses, setReviewStatuses] = useState<ReviewStatus>({});

    // State variable for refreshing site configurations component.
    const [refreshSiteConfigurations, setRefreshSiteConfigurations] = useState<boolean>(false);

    // State variable for site configuration alert message.
    const [saveSiteConfigurationAlertMessage, setSaveSiteConfigurationAlertMessage] = useState<string>('Saved successfully');

    // State variable for is save site submitted.
    const [isSaveSiteSubmitted, setIsSaveSiteSubmitted] = useState<boolean>(false);

    // State variable to show benefit configuration
    const [isSaveBenefitSubmitted, setIsSaveBenefitSubmitted] = useState<boolean>(false);

    // State variable for nav array.
    const [navArry, setNavArray] = useState<IGroupNames[]>();

    // State variable for templateSheetBenefitId
    const [templateBenefitSheetId, setTemplateBenefitSheetId] = useState<number>(0);

    // State variable for nav array is benefit submitted.
    const [isBenefitSubmitted, setIsBenefitSubmitted] = useState(false);

    // State variable to enable or disable submit or approve button.
    const [isbuttonEnabled, setIsbuttonEnabled] = useState<boolean>(false);

    // State variable to enable or disable submit or approve button.
    const [isFutureConfig, setIsFutureConfig] = useState<boolean>(false);

    // Function to navigate to a new location
    const navigateToCarrierDetails = () => {
        navigate("/CarrierDetails", { state: { activeInsuranceCarrier: insuranceCarrier } });
    };

    // State variable for active tab.
    const [activeTab, setActiveTab] = useState('BenefitConfigurations');

    // Handles the tab selection.
    const handleTabSelect = (selectedTab: any) => {
        setActiveTab(selectedTab);
        SetKeyToLocalStorage(PlanDetailsActiveTab, selectedTab);
    };

    // Navigates to benefit tab.
    const navigateToBenefitTab = () => {
        setActiveTab('BenefitConfigurations');
    }

    // Navigates to carrier page.
    const navigateToCarrierPage = () => {
        navigate("/Carriers");
    }

    // Stores the selected year based on the toggle.
    const selectedyear = isbuttonEnabled ? year + 1 : year;

    // Submit or approve the site config data answers.
    const submitOrApproveSiteConfigurations = async (status: string, templateSheetBenefitIds: string, alertMessage: string, isClickedFromSbtBtnPopUp: boolean, selectedYear: number = year, isSiteConfig: boolean): Promise<boolean> => {
        let IsDraft: boolean = status === DraftStatus;
        const submitOrApproveSiteConfigModel = {
            insuranceCarrierId: insuranceCarrier?.insuranceCarrierId,
            insuranceHealthPlanID: planDetails?.insuranceHealthPlanID?.toString() ?? '',
            templateSheetBenefitIDs: templateSheetBenefitIds,
            benefitYear: selectedYear,
            status: status,
            actionUser: GetKeyValueFromLocalStorage("username") ?? '',
            isSiteConfig: isSiteConfig
        };
        try {
            let response=await client.post(SubmitOrApproveSiteConfigurationsURL, submitOrApproveSiteConfigModel);
            if (response.status === 200) 
            {
                    if (!IsDraft) {
                        setSaveSiteConfigurationAlert(true);
                        setSaveSiteConfigurationAlertMessage(alertMessage);
                        setTimeout(() => { setSaveSiteConfigurationAlert(false); }, 3000);
                    }

                    setRefreshSiteConfigurations(!refreshSiteConfigurations);
            }
            return true;
        } catch (error) {
            setSaveSiteConfigurationAlert(false);
            return false;
        }
    };

    // Handles the submit/approve site config dropdown.
    const handleDropdownClick = () => {
        setIsAcknowledged(false);
        setIsSaveSiteSubmitted(!isSaveSiteSubmitted);
    };

    // Change status to reviewed or draft for benefit config.
    const changeStatusToDraftForBenefitConfig = async (status: string, templateSheetBenefitIds: string, benefitYear: number = year): Promise<boolean> => {
        let alertMessage: string = SaveBenefitConfig;
        let isSuccess: boolean = await submitOrApproveSiteConfigurations(status, templateSheetBenefitIds, alertMessage, false, benefitYear, false);
        return isSuccess;
    }

    // Change status to reviewed or draft.
    const changeStatusToReviewedOrDraft = async (groupName: string, status: string, templateSheetBenefitIds: string, benefitYear: number = year): Promise<boolean> => {
        let alertMessage: string = status === ReviewedStatus ? `Reviewed ${year} ${groupName} successfully` : `${status} ${year} ${groupName}  created successfully`;
        let isSuccess: boolean = await submitOrApproveSiteConfigurations(status, templateSheetBenefitIds, alertMessage, false, selectedyear, true);
        return isSuccess;
    }

    // Submit or approves the site configuration group.
    const onSubmitOrApproveBtnClick = (status: string, templateSheetBenefitIds: string, isSiteConfig: boolean, futureYear?: number) => {
        isSiteConfig ? setIsSaveSiteSubmitted(true) : setIsSaveBenefitSubmitted(true);
        let alertMessage: string = status === DraftStatus ? 'Saved successfully' : status === SubmittedStatus ? `${isSiteConfig ? 'Site' : 'Benefit'} Configuration Submitted for approval successfully.` : status + ` ${isSiteConfig ? 'Site' : 'Benefit'} configuration successfully`;
        let benefitYear = isSiteConfig ? selectedyear : insuranceCarrier.benefitYear;
        submitOrApproveSiteConfigurations(status, templateSheetBenefitIds, alertMessage, true, benefitYear, isSiteConfig);
        isSiteConfig ? setIsSaveSiteSubmitted(false) : setIsSaveBenefitSubmitted(false);
    }

    // Adds or updates the review statuses.
    const addOrUpdateReviewStatuses = (groupName: string, reviewStatus: StatusModel) => {
        let selectedBenefitYear = isbuttonEnabled ? year + 1 : year;
        setReviewStatuses((prevState) => ({
            ...prevState,
            [groupName]: {
                templateSheetBenefitId: reviewStatus?.templateSheetBenefitId,
                isReviewed: reviewStatus?.isReviewed,
                benefitYear: selectedBenefitYear
            }
        }));
        setIsAcknowledged(false);
    }


    // Component mount and unmount of page life cycle.
    useEffect(() => {
        var currentActiveTab = GetKeyValueFromLocalStorage(PlanDetailsActiveTab);
        if (currentActiveTab?.toString()?.length > 0) {
            setActiveTab(currentActiveTab);
        }
        else {
            setActiveTab("BenefitConfigurations");
        }
    })


    return <div className="d-flex overflow-hidden">
        <Alert className={saveSiteConfigurationAlert ? '' : 'd-none'} variant="success" dismissible>
            <span className="alert-left d-flex align-items-center">
                <img
                    className="img-fluid alert-icon me-2"
                    src={AlertSuccess}
                    width={18}
                    height={18}
                    alt=" "
                />
                <span>{saveSiteConfigurationAlertMessage}</span>
            </span>
        </Alert>
        <SideBar />
        <div className="page-content">
            <div className='page-title-section'>
                <div className="page-top-left">
                    <Breadcrumb className="breadcrumbs d-none d-lg-block">
                        <Breadcrumb.Item onClick={navigateToCarrierPage} className="cursor-pointer" href="/Carriers">Carriers</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={navigateToCarrierDetails}>{insuranceCarrier?.insuranceCarrierName} {insuranceCarrier?.benefitYear}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{planDetails?.healthPlanName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h3><img onClick={navigateToCarrierDetails} className="img-fluid cursor-pointer" src={BackArrow} alt=" " /><span title={planDetails?.healthPlanName}>{planDetails?.healthPlanName}</span><span className={`status ms-2 ${planDetails?.colourStyles} d-none d-lg-inline-block`}>{planDetails?.label}</span></h3>
                </div>
                <div className='page-top-right'>
                    <div className="page-top-actions">
                        <Button className="btn-outline-primary view d-none" href="#">
                            <img className="img-fluid" src={View} alt=" " />
                        </Button>
                        <Dropdown className="ms-3 d-none d-lg-inline-block" >
                            <Dropdown.Toggle variant="secondary" id="dropdown-menu-align-start" className="m-0">
                                <img className="img-fluid me-2" src={SubmitIcon} alt=" " /><span>{btnTitle}</span><img className="img-fluid arrow ms-1" src={DownArrow} alt=" " />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {!isbuttonEnabled ?
                                    <Dropdown.Item disabled={btnTitle === Submit ? isSubmitted : btnTitle === Approve ? isApprovedOrPublished : true}
                                        onClick={handleDropdownClick}>
                                        {btnTitle === Approve ? `Approve ${year} Site Configuration` : `Submit  ${year} Site Configuration`}
                                    </Dropdown.Item>
                                    :
                                    <Dropdown.Item disabled={btnTitle === Submit ? isSubmitted : btnTitle === Approve ? isApprovedOrPublished : true}
                                        onClick={handleDropdownClick}>
                                        {btnTitle === Approve ? `Approve ${year + 1} Site Configuration` : `Submit  ${year + 1} Site Configuration`}
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item disabled={isBenefitSubmitted} onClick={() => { setIsSaveBenefitSubmitted(!isSaveBenefitSubmitted) }}>
                                    <span>{btnTitle === Approve ? 'Approve Benefit Configuration' : 'Submit Benefit Configuration'}</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="last-updated">
                    <p><span>Last Updated:</span> <span>Apr 12, 2023</span></p>
                    <span className="time-zone show-popup show-tooltip plan-icon">
                        <img className="img-fluid" src={Time} alt=" " /><span>EST</span>
                        <span className="hover-popup left">The timings shown in the website are in Eastern Time Zone.</span>
                    </span>
                </div>
            </div>
            <div className="tabslist-main">
                <Tabs
                    id="uncontrolled-tab-example"
                    className="tabs-list"
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                >
                    <Tab eventKey="BenefitConfigurations" title={
                        <>
                            {isBenefitSubmitted ?
                                btnTitle === Submit ? <InProgressIcon/>:
                                    btnTitle === Approve ? <ApprovedIcon/> : <></>
                                : <></> }
                            <div> Benefit Configurations</div>
                        </> }>

                        {activeTab === "BenefitConfigurations" && (
                            <BenefitConfig activeTab={activeTab} activeInsuranceCarrier={insuranceCarrier}
                                planDetails={planDetails} navArry={navArry}
                                setNavArray={setNavArray}
                                templateBenefitSheetId={templateBenefitSheetId}
                                setTemplateBenefitSheetId ={setTemplateBenefitSheetId}
                                getBtnTitle={getBtnTitle}
                                setIsBenefitSubmitted={setIsBenefitSubmitted}
                                refreshSiteConfigurations={refreshSiteConfigurations}
                                btnTitle={btnTitle}
                                changeStatusToDraft={(status: string, templateSheetBenefitIds: string, benefitYear: number) =>
                                { changeStatusToDraftForBenefitConfig(status, templateSheetBenefitIds, benefitYear) }}
                            />
                        )}
                    </Tab>
                    <Tab eventKey="PlanInformation" title="Plan Information">
                        {activeTab === "PlanInformation" && (
                            <PlanInformation activeTab={activeTab} navigateToBenefitTab={navigateToBenefitTab} activeInsuranceCarrier={insuranceCarrier} planDetails={planDetails} />
                        )}
                    </Tab>
                    <Tab eventKey="SiteConfigurations" title={
                        <>
                            {isDraft ? <></>
                                : (isAtleastOneSubmitted || isSubmitted) && !isApprovedOrPublished ? <InProgressIcon/>
                                    : isApprovedOrPublished ? <ApprovedIcon/>
                                        : <></>
                            }
                            <div>Site Configurations</div>
                            {isFutureConfig ? <FutureConfigIcon isHoverAlignedLeft={false} /> : <></>}
                        </>
                    }>
                        <SiteConfig
                            setReviewStatuses={(groupName: string, reviewStatus: StatusModel) => {
                                addOrUpdateReviewStatuses(groupName, reviewStatus);
                                changeStatusToReviewedOrDraft(groupName, reviewStatus?.isReviewed ? ReviewedStatus : DraftStatus, [reviewStatus.templateSheetBenefitId].join(','), reviewStatus.benefitYear);
                            }}
                            changeStatusToDraft={async (groupName: string, templateSheetBenefitId: string,benefitYear:number): Promise<boolean> => {
                                return changeStatusToReviewedOrDraft(groupName, DraftStatus, templateSheetBenefitId, benefitYear);
                            }}
                            updateReviewStatuses={(groupName: string, reviewStatus: StatusModel) => {
                                addOrUpdateReviewStatuses(groupName, reviewStatus)
                            }}
                            setIsSaveSubmitted={(isSubmit: boolean) => { setIsSaveSiteSubmitted(isSubmit) }}
                            setIsApprovedOrPublished={(isApprovedOrPublished: boolean) => { setIsApprovedOrPublished(isApprovedOrPublished) }}
                            setIsDraft={(isDraft: boolean) => { setIsDraft(isDraft) }}
                            setIsSubmitted={(isSubmitted: boolean) => { setIsSubmitted(isSubmitted) }}
                            setIsAtleastOneSubmitted={((isAtleastOneSubmitted: boolean) => { setAtleastOneSubmitted(isAtleastOneSubmitted) })}
                            setIsButtonEnabled={(isButtonEnabled: boolean) => { setIsbuttonEnabled(isButtonEnabled) }}
                            setIsFutureSiteConfigAvailable={(isfutureConfig: boolean) => { setIsFutureConfig(isfutureConfig) }}
                            btnTitle={getBtnTitle()}
                            refreshSiteConfigurations={refreshSiteConfigurations}
                            activeTab={activeTab}
                            activeInsuranceCarrier={insuranceCarrier}
                            planDetails={planDetails}
                            setLoading={() => { }}
                            catchAll="false" />
                    </Tab>
                </Tabs>
            </div>
        </div>

        {/* Submit Carrier Modal Popup */}
        <Modal show={isSaveSiteSubmitted} backdrop="static" keyboard={false} size="lg" centered className="">
            <Modal.Header closeButton onClick={() => { setIsAcknowledged(false); setIsSaveSiteSubmitted(false) }}>
                <Modal.Title>{btnTitle} Carrier - Site Configuration for {selectedyear}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Checklist before submission</h5>
                <p>Make sure you have filled the necessary fields and reviewed the information.</p>
                <ListGroup className="modal-list">
                    {Object.keys(reviewStatuses || [])?.map((key: any, index: number) => {
                        return <ListGroup.Item> <img className="img-fluid" src={reviewStatuses[key]?.isReviewed ? GreenTick : RedCross} alt=" " />Verified {key}</ListGroup.Item>
                    })}
                </ListGroup>
                <div className="modal-check"><Form.Check checked={isAcknowledged}
                    disabled={Object.values(reviewStatuses).some(status => !status.isReviewed)} aria-label="option 1" className="form-check" onClick={() => { setIsAcknowledged(!isAcknowledged) }} /><span>I acknowledge that I have reviewed all the information</span></div>
            </Modal.Body>
            <Modal.Footer className="justify-content-start">
                <Button className="btn-secondary" disabled={!isAcknowledged} onClick={() => {
                    onSubmitOrApproveBtnClick(getBtnTitle(), Object.keys(reviewStatuses || []).map((key: string) => reviewStatuses[key].templateSheetBenefitId).join(','), true);
                }}>{btnTitle}</Button>
                <Button className="btn-outline-primary" onClick={() => { setIsSaveSiteSubmitted(false); setIsAcknowledged(false); }}>Cancel</Button>
            </Modal.Footer>
        </Modal>

        {/**Benefit COnfiguration */}
        <Modal show={isSaveBenefitSubmitted} backdrop="static" keyboard={false} size="lg" centered className="">
            <Modal.Header closeButton onClick={() => {  setIsSaveBenefitSubmitted(false); setIsBenefitAcknowledged(false);
            }}>
                <Modal.Title>{btnTitle} Carrier - Benefit Configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Checklist before submission</h5>
                <p>Make sure you have filled the necessary fields and reviewed the information.</p>
                <ListGroup className="modal-list">
                    {navArry?.map((key: IGroupNames, index: number) => {
                        return <ListGroup.Item> <img className="img-fluid" src={key.isVerified ? GreenTick : RedCross} alt=" " />Verified {key.groupName}</ListGroup.Item>
                    })}
                </ListGroup>
                <div className="modal-check"><Form.Check checked={isBenefitAcknowledged}
                    disabled={!(navArry?.every((e:IGroupNames)=>{
                        return e?.isVerified === true;
                    }))}
                    aria-label="option 1" className="form-check" onClick={() => { setIsBenefitAcknowledged(!isBenefitAcknowledged) }} /><span>I acknowledge that I have reviewed all the information</span></div>
            </Modal.Body>
            <Modal.Footer className="justify-content-start">
                <Button className="btn-secondary" disabled={!isBenefitAcknowledged} onClick={() => {
                    onSubmitOrApproveBtnClick(getBtnTitle(), templateBenefitSheetId.toString(),false);
                    setIsBenefitAcknowledged(false);
                }}>{btnTitle}</Button>
                <Button className="btn-outline-primary" onClick={() => { setIsSaveBenefitSubmitted(false);setIsBenefitAcknowledged(false) }}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </div >
}