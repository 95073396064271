import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Admin, Manager, SystemAdmin, UserRoleKey } from "../Constants/AppConstants";
import { Login } from "../components/Login/Login";

//  App context.
export const AppContext = React.createContext({});

// Use app context.
export const useAppContext = () => useContext(AppContext);

// App provider.
export const AppProvider = ({ children }: any) => {

    // Accounts.
    const { accounts, instance } = useMsal();

    // User name.
    const userName = accounts[0]?.name?.toLowerCase();

    // Is authenticated variable.
    const isAuthenticated = useIsAuthenticated();

    // State variable for rolescount for a user.
    const [rolesCount, setRolesCount] = useState(0);

    // State variable for accountcount for a user.
    const [accountsLength, setAccountsLength] = useState(0);

    // State variable for azure login details.
    const [azureLoginDetails, setAzureLoginDetails] = useState({});

    // State variable for all user roles.
    const [allUserRoles, setAllUserRoles] = useState<string[]>([]);

    // Function to handle role selection
    const handleRoleSelection = (selectedRole: string) => {
        let userAccountDetails: any = accounts[0];
        // Update the userAccountDetails with the selected role
        const updatedUserAccountDetails = {
            ...userAccountDetails,
            userRole: selectedRole,
        };
        localStorage.setItem(UserRoleKey, selectedRole);
        setAzureLoginDetails(updatedUserAccountDetails);
        setAccountsLength(1);

        if (rolesCount > 2) {
            window.location.href = "/";
        }
    };

    // Updates the azure login details.
    const updateazureLoginDetails = (azuredetails: any) => {
        setAzureLoginDetails(azuredetails);
    }

    // Gets the azure login details.
    const getAzureLoginDetails = () => {
        if (isAuthenticated) {
            let userAccountDetails: any = accounts[0];
            let userRoles: string[] = [];
            const groups = userAccountDetails?.idTokenClaims?.groups || [];
            const roleMappings = [
                { groupId: process.env.REACT_APP_OBT_MANAGER_ID?.toLowerCase(), role: "Manager" },
                { groupId: process.env.REACT_APP_OBT_ADMIN_ID?.toLowerCase(), role: "Admin" },
                { groupId: process.env.REACT_APP_OBT_SYSADMIN_ID?.toLowerCase(), role: "System Admin" }
            ];
            for (const mapping of roleMappings) {
                if (groups.find((x: any) => x.toLowerCase() === mapping.groupId)) {
                    userRoles.push(mapping.role);
                }
            }
            setAllUserRoles(userRoles);
            setAccountsLength(userRoles.length);
            setRolesCount(userRoles.length);
            if (userRoles?.length <= 1) {
                let userRole: string = "";
                if (userAccountDetails?.idTokenClaims?.groups?.find((x: any) => x.toLowerCase() === process.env.REACT_APP_OBT_MANAGER_ID?.toLowerCase())) {
                    userRole = Manager;
                }
                if (userAccountDetails?.idTokenClaims?.groups?.find((x: any) => x.toLowerCase() === process.env.REACT_APP_OBT_ADMIN_ID?.toLowerCase())) {
                    userRole = Admin;
                }
                if (userAccountDetails?.idTokenClaims?.groups?.find((x: any) => x.toLowerCase() === process.env.REACT_APP_OBT_SYSADMIN_ID?.toLowerCase())) {
                    userRole = SystemAdmin;
                }
                userAccountDetails = { ...userAccountDetails, userRole };
                localStorage.setItem(UserRoleKey, userRole);
                setAzureLoginDetails(userAccountDetails || {});
            }
        }
    };

    // Memoized value.
    const memoizedValue = useMemo(() => ({
        azureLoginDetails,
        rolesCount,
        accounts,
        instance,
        userName,
        isAuthenticated,
        updateazureLoginDetails,
        allUserRoles,
        handleRoleSelection
    }), [azureLoginDetails, rolesCount, updateazureLoginDetails, allUserRoles, handleRoleSelection]);

    // Computation logic on component mount and unmount page life cycle.
    useEffect(() => {
        getAzureLoginDetails();
    }, [])

    if (accountsLength > 1 && (localStorage.getItem(UserRoleKey)?.length ?? 0) <= 0) {
        return (
            <Login handleRoleSelection={(role: string) => handleRoleSelection(role)} userRoles={allUserRoles} />
        )
    }
    else {






        return (
            <AppContext.Provider
                value={memoizedValue}>
                {children}
            </AppContext.Provider>
        );
    }
};
