import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Review from "../../images/review.svg";
import Comment from "../../images/comment.svg";
import Info from "../../images/info.svg";
import PrimaryTick from "../../images/primary-tick.svg";
import SecondaryTick from "../../images/secondary-tick.svg";
import FutureSiteDefault from "../../images/future-site-default.svg";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Modal } from 'react-bootstrap';
import { ApprovedStatus, CardImage, CDNCardImagePath, CDNLogo, ClientLogo, CopySiteConfigData, DraftStatus, FailedStatus, getCurrentFormattedDate, GetQuestionsWithAnswers, getSelectedRole, GetSubDomain, MandatoryMessage, MyBenefitsURL, OnlyPublishForCY, PublishedStatus, ReviewedStatus, SaveSiteConfiguration, SubDomainQuestion, SubmittedStatus, SystemAdmin, year, futureYear, SaveSiteConfig } from '../../Constants/AppConstants';
import axios from 'axios';
import client from "../../utils/ApiClient";
import Scrollbars from 'rc-scrollbars';
import { ProgressIndicators } from "../ProgressIndicators/ProgressIndicators";
import { IFutureConfig, ReviewStatus } from '../../Interfaces/ReviewStatus';
import { Question } from '../../Interfaces/Question';
import { SaveSiteConfigRequest } from '../../Interfaces/SaveSiteConfigRequest';
import { GetKeyValueFromLocalStorage } from '../../Constants/LocalStorage';
import { FutureConfigIcon, AlertPopup } from '../../components/Common/CommonComponents';
import { useNavigate } from 'react-router-dom';
import { useCallbackContext } from '../../context/CallbackContext';
import { useDataContext } from '../../context/DataContext';
import FutureSite from "../../images/future-site.svg";


// Site config component.
export const SiteConfig = (props: any) => {


    // Scrollbar reference variable.
    const scrollbarsRef = useRef<any>(null);

    const navigate = useNavigate();

    // Context for call back from futuresiteconfig page.
    const { setSaveFutureConfig } = useCallbackContext();

    const { updateAlertPopup } = useDataContext();

    // State variable for message on submission of site configurations
    const [message, setMessage] = useState<string>(MandatoryMessage);

    // State variable for current answers length.
    const [currentAnswersLength, setCurrentAnswersLength] = useState<number>(0);

    // State variable for current questions length.
    const [currentQuestionsLength, setCurrentQuestionsLength] = useState<number>(0);

    // State variable for show mandatory message.
    const [showMandatoryMessage, setShowMandatoryMessage] = useState<boolean>(false);

    // State variable for show copy site config.
    const [showCopySiteConfig, setShowCopySiteConfig] = useState(false);

    // State variable for sheet with questions.
    const [sheetWithQuestions, setSheetWithQuestions] = useState<Record<string, Question[]>>({});

    // State variable for review statuses.
    const [reviewStatuses, setReviewStatuses] = useState<ReviewStatus>({});

    // State variable for loader.
    const [loader, setLoader] = useState(false);

    // State variable for current nav index.
    const [currentNavIndex, setCurrentNavIndex] = useState<number>(0);

    // State variable to store Only publish for current year question.
    const [onlyPublishForCYQuestion, setOnlyPublishForCYQuestion] = useState<any>(null);

    // State variable to show/hide dropdown.
    const [dropdownConfigurator, setDropdownConfigurator] = useState(false);

    // State variable to enable/disable site configuration.
    const [isSiteConfigEnabled, setIsSiteConfigEnabled] = useState(false);

    // State variable for sheet with questions for future.
    const [futureSheetWithQuestions, setFutureSheetWithQuestions] = useState<Record<string, Question[]>>({});

    // State variable for sheet with questions.
    const [currentSheetWithQuestions, setCurrentSheetWithQuestions] = useState<Record<string, Question[]>>({});

    // State variable for review statuses.
    const [isFutureConfigSection, setisFutureConfigSection] = useState<IFutureConfig[]>([]);

    const [HighlightFutureConfig, setHighlightFutureConfig] = useState(false);

    // Handles future toggle button.
    const handleToggle = (futureYearConfig: any,currentYearConfig:any,isToggle: boolean = false) => {
        const allFutureQuestions = Object.values(futureSheetWithQuestions).flat();
        const allCurrentQuestions = Object.values(currentSheetWithQuestions).flat();
        let combinedData = combineQuestionsWithAnswers(allFutureQuestions, allCurrentQuestions);
        futureYearConfig = Object.values(futureYearConfig).flat();
        currentYearConfig = Object.values(currentYearConfig).flat();
        if (futureYearConfig.length > 0  && currentYearConfig.length > 0)
            combinedData = combineQuestionsWithAnswers(futureYearConfig, currentYearConfig);
        if (!isToggle) {
            setDropdownConfigurator(!dropdownConfigurator);
            setHighlightFutureConfig(!HighlightFutureConfig);
            props.setIsButtonEnabled(!dropdownConfigurator);
            setIsSiteConfigEnabled(prev => !prev);
        }
        if (isToggle || !dropdownConfigurator) {
            seggregrateQuestionsToGroups(combinedData, true, true);
        } else {
            seggregrateQuestionsToGroups(allCurrentQuestions, true, false);
        }

    }


    // Handles the actvity popup close button/icon.
    const handleCopySiteConfig = () => {
        setShowCopySiteConfig(false);
    }

    // Handles the scroll naviagtion on the site configuration.
    const handleScroll = () => {
        if (scrollbarsRef.current) {
            const containerElement = scrollbarsRef.current.view;
            const sectionElements = containerElement.getElementsByClassName("benefit-content-section");
            for (let i = 0; i < sectionElements.length; i++) {
                const sectionElement = sectionElements[i];
                const rect = sectionElement.getBoundingClientRect();
                if (rect.top >= 0 && rect.top < window.innerHeight) {
                    setCurrentNavIndex(i);
                }
            }
        }
    };

    // Returns true if the question is effective from and to.
    const isExcludedQuestion = (question: any): boolean => {
        // Remove spaces from the questionName
        const sanitizedQuestionName = question?.question?.replace(/\s/g, '')?.toLowerCase() || '';
        return sanitizedQuestionName === "doyouwantplanoverrides?" || sanitizedQuestionName === "onlypublishforcurrentyear";
    };

    // Returns true if it is reviewed or approved or published.
    const isReviewedOrSubmittedOrApproved = (currentQuestion: any) => {
        return currentQuestion.every((question: any) =>
            question.versionStatus?.toString().toLowerCase() === ApprovedStatus.toLowerCase() ||
            question.versionStatus?.toString().toLowerCase() === SubmittedStatus.toLowerCase() ||
            question.versionStatus?.toString().toLowerCase() === ReviewedStatus.toLowerCase() ||
            question.versionStatus?.toString().toLowerCase() === PublishedStatus.toLowerCase()
        );
    }

    // Returns true if it is submitted or approved or published.
    const isSubmittedOrApproved = (currentQuestion: any) => {
        return currentQuestion.every((question: any) =>
            question.versionStatus?.toString().toLowerCase() === ApprovedStatus.toLowerCase() ||
            question.versionStatus?.toString().toLowerCase() === SubmittedStatus.toLowerCase() ||
            question.versionStatus?.toString().toLowerCase() === PublishedStatus.toLowerCase()
        );
    }

    // Returns true if it is approved or published.
    const isApprovedOrPublishedStatus = (currentQuestion: any) => {
        return currentQuestion.every((question: any) =>
            question.versionStatus?.toString().toLowerCase() === ApprovedStatus.toLowerCase() ||
            question.versionStatus?.toString().toLowerCase() === PublishedStatus.toLowerCase()
        );
    }

    // Returns true if any question has the same status.
    const isAnySameStatus = (currentQuestion: any, status: string): boolean => {
        return currentQuestion.some((question: any) =>
            question.versionStatus && question.versionStatus?.toString().toLowerCase() === status.toLowerCase()
        );
    }


    // Checks if the passed value matched the regex pattern.
    const isValueMatchedToRegexPattern = (regexPattern: string, value: string): boolean => {
        return new RegExp(regexPattern).test(value?.toLowerCase());
    }

    // Resets the regex value.
    const resetRegexValueOnQuestion = (question: any) => {
        // Shallow copy of the questions array.
        const questions = [...sheetWithQuestions[question.groupName]];

        // Find the index of the current question within the array
        const questionIndex = questions?.findIndex((question: Question) => question?.questionId === question?.questionId);

        if (questionIndex !== -1) {
            // Update the answerValue of the question at the found index
            questions[questionIndex].isValidRegexValue = true;
        }

        // Update the state with the modified array
        setSheetWithQuestions((prevState) => ({
            ...prevState,
            [question.groupName]: questions,
        }));
    }

    // Filters the data based on the passed effectiveYear.
    const filterQuestionsByYear = (sheetsWithQuestionCatchAllLevelResponse: any, year: number) => {
        let filteredData = sheetsWithQuestionCatchAllLevelResponse.data.filter((item: any) => item.benefitYear === year);
        return filteredData;
    };

    // Filters the isonlypublishforcydata.
    const filterandStoreisonlypublishforcydata = (sheetsWithQuestionCatchAllLevelResponse: any) => {
        let filteredData = sheetsWithQuestionCatchAllLevelResponse.filter((item: any) => item.question.toLowerCase() === OnlyPublishForCY.toLowerCase());
        setOnlyPublishForCYQuestion(filteredData || null)
        return filteredData;
    };

    // Proccess questions.
    const processQuestions = (filteredCurrentyearCatchAllResponse: any, isCurrentYear: boolean) => {
        let answeredValuesCount = 0;
        let totalQuestionsCount = 0;

        // Filter questions to exclude ineffective ones
        let questionsWithAnswers = filteredCurrentyearCatchAllResponse?.filter((question: any) => !isExcludedQuestion(question));

        // Calculate answeredValuesCount and totalQuestionsCount based on role
        if (getSelectedRole()?.toString()?.toLowerCase() !== SystemAdmin?.toString()?.toLowerCase()) {
            answeredValuesCount = getAnsweredFields(filteredQuestions(questionsWithAnswers));
            totalQuestionsCount = getTotalFields(filteredQuestions(questionsWithAnswers));
        } else {
            answeredValuesCount = getAnsweredFields(questionsWithAnswers);
            totalQuestionsCount = getTotalFields(questionsWithAnswers);
        }
        // Perform additional operations
        seggregrateQuestionsToGroups(questionsWithAnswers, isCurrentYear,false);

        if (isCurrentYear) {
            // Update lengths
            setCurrentAnswersLength(totalQuestionsCount - answeredValuesCount);
            setCurrentQuestionsLength(totalQuestionsCount);
        }
        return questionsWithAnswers;
    }


    // Handle and stores the data on option click.
    const handleValueChange = (currentQuestion: any, value: any, isDropDown: boolean) => {
        let isValidRegexAnswerValue: boolean = false;
        const groupName = currentQuestion?.groupName;

        // Shallow copy of the questions array.
        const questions = [...sheetWithQuestions[groupName]];

        // Find the index of the current question within the array
        const questionIndex = questions?.findIndex((question: Question) => question?.questionId === currentQuestion?.questionId);

        if (questionIndex !== -1) {
            // Update the answerValue of the question at the found index
            questions[questionIndex].answerValue = value;
            currentQuestion.answerValue = value;

            if (isDropDown) {
                if (questions[questionIndex]?.options?.split(',')?.includes(value)) {
                    isValidRegexAnswerValue = true;
                }
            }
            else {
                if (isValueMatchedToRegexPattern(questions[questionIndex]?.regexValidationPattern ?? '', value)) {
                    isValidRegexAnswerValue = true;
                }
            }

            setReviewStatuses(prevState => ({
                ...prevState,
                [groupName]: {
                    templateSheetBenefitId: currentQuestion?.templateSheetBenefitId,
                    isReviewed: false
                },
            }))

            props.updateReviewStatuses(groupName, {
                templateSheetBenefitId: currentQuestion?.templateSheetBenefitId,
                isReviewed: false,
            })

            props.setIsApprovedOrPublished(false);

            questions[questionIndex].isValidRegexValue = isValidRegexAnswerValue;
            currentQuestion.isValidRegexValue = isValidRegexAnswerValue;

            // Update the state with the modified array
            setSheetWithQuestions((prevState) => ({
                ...prevState,
                [groupName]: questions,
            }));

            if (currentQuestion.isValidRegexValue && isDropDown) {
                SaveSiteConfigWithAnswers(currentQuestion, false, false);
            }
        }
    };

    // Returns true if mandatory questions exists.
    const getMandatoryQuestions = (groupName: string, sheetWithQuestions: any) => {
        return sheetWithQuestions?.[groupName]?.filter((q: any) => q?.isMandate && !isExcludedQuestion(q));
    }

    // Function to combine questions with answers
    const combineQuestionsWithAnswers = (
        sheetsWithQuestionPlanLevelResponse: Question[],
        catchAllLevelQuestions: Question[]
    ): Question[] => {

        // Create a dictionary-like structure for non-empty plan level answers
        const planLevelQuestionsWithAnswers: Record<number, Question> = {};

        sheetsWithQuestionPlanLevelResponse?.forEach((question: Question) => {
            const { questionId, answerValue } = question;

            if (checkIsNotNullOrNotEmptyOrNotUndefined(answerValue)) {
                planLevelQuestionsWithAnswers[questionId] = question;
            }
        });

        // Update catchAllLevelQuestions with plan level answers
        return catchAllLevelQuestions.map((question: Question) => {
            const selectedStatus = Object.values(planLevelQuestionsWithAnswers).find(
                (x: Question) => x.templateSheetBenefitId === question.templateSheetBenefitId
            );
            const matchingAnswer = planLevelQuestionsWithAnswers[question.questionId] || {};
            return {
                ...question,
                ...matchingAnswer,
                versionStatus: selectedStatus?.versionStatus ?? question.versionStatus
            };
        });
    };

    const processAllResponse = (filteredCurrentYearData: any, filteredFutureYearData: any) => {
        let filteredFutureyearCatchAllResponselength = filteredFutureYearData.filter((x: any) => x.effectiveFrom !== null && x.answerValue !== null).length;

        if (filteredFutureyearCatchAllResponselength > 0) {
            props.setIsFutureSiteConfigAvailable(true);
        }
        let futureYearConfig=processQuestions(filteredFutureYearData, false);
        let currentYearConfig = processQuestions(filteredCurrentYearData, true);
        if (dropdownConfigurator)
            handleToggle(futureYearConfig, currentYearConfig,true)
    }

    // To check and update if the future config is available for the section.
    const updateFutureConfigSection = (groupName: string, groupedData: any) => {
        let isFutureConfigAvailableForGroup = groupedData.filter((x: any) => x.answerValue !== null).length > 0 ? true : false;
        // Update isFutureConfigSection state
        setisFutureConfigSection(prevState => {
            // Check if prevState is undefined or null (initial state check)
            if (!prevState) {
                return [{
                    groupName: groupName,
                    isFutureConfig: isFutureConfigAvailableForGroup,
                }];
            }

            // Check if groupName already exists in state to maintain uniqueness
            if (!prevState.find((item: IFutureConfig) => item.groupName === groupName)) {
                return [
                    ...prevState,
                    {
                        groupName: groupName,
                        isFutureConfig: isFutureConfigAvailableForGroup,
                    }
                ];
            }

            return prevState;
        });
    };

    // Handle the future config page.
    const handleFutureConfig = (question: any, key: any) => {
        let futureQuestion = Object.keys(futureSheetWithQuestions).length !== 0
            && filteredQuestions.length > 0
            && (futureSheetWithQuestions[key] || []).find((x: Question) => x.questionId === question.questionId);
        handleFutureConfigNavigation(question, futureQuestion);
    }

    // Function to handle navigation to the future site config page
    const handleFutureConfigNavigation = (question: any, futureQuestion: any) => {
        let futureSiteConfigProps = {
            activeInsuranceCarrier: props.activeInsuranceCarrier,
            planDetails: props.planDetails,
            selectedQuestion: question,
            futureSelectedQuestion: futureQuestion,
        }
        navigate('/FutureSiteConfig', {
            state: {
                ...futureSiteConfigProps
            }
        });

        const callbackFunction = async (data: any, isFutureConfig: boolean, requestData: any, IsRemoved: boolean) => {
            const result: boolean = await SaveSiteConfigWithAnswers(data, isFutureConfig, IsRemoved);
            if (!!result) {
                if (data.effectiveFrom && data.effectiveFrom.includes(year + 1)) {
                    if (IsRemoved) {
                        futureSiteConfigProps.futureSelectedQuestion.answerValue = null;
                        futureSiteConfigProps.futureSelectedQuestion.effectiveFrom = null;
                        futureSiteConfigProps.futureSelectedQuestion.modifyDate = null;
                        const { answerValue, effectiveFrom, modifyDate, ...remainingData } = data;
                        Object.assign(futureSiteConfigProps.futureSelectedQuestion, remainingData);
                    } else {
                        futureSiteConfigProps.selectedQuestion = requestData.selectedQuestion;
                        futureSiteConfigProps.futureSelectedQuestion = data;
                    }
                }
                else //(data.benefitYear === requestData.selectedQuestion.benefitYear)
                {
                    futureSiteConfigProps.selectedQuestion = data;
                    futureSiteConfigProps.futureSelectedQuestion = requestData.futureSelectedQuestion;
                }
                navigate('/FutureSiteConfig', {
                    state: {
                        ...futureSiteConfigProps
                    }
                });
            }
        };
        setSaveFutureConfig(() => callbackFunction);
    };

    // Combines and split all questions to groups.
    const seggregrateQuestionsToGroups = (sheetsQuestions: any, isCurrentYear: boolean, isToggleEnabled: boolean) => {
        const sheetNameOrder: { [key: string]: number } = {
            'Login': 1,
            'Administration': 2,
            'Health Representative': 3,
            'Benefits Tracker': 4,
            'Content Management': 5,
            'Flex': 6,
            'Order Management': 7,
            'Technical': 8,
        };

        const groupedData = sheetsQuestions?.reduce((groups: any, item: Question) => {
            const key = item?.groupName;
            if (key?.toString()?.toLowerCase().includes("technical")) {
                if (getSelectedRole()?.toString()?.toLowerCase() === SystemAdmin?.toString()?.toLowerCase()) {
                    if (key != null) {
                        if (!groups[key]) {
                            groups[key] = [];
                        }
                    }
                    groups[key].push(item);
                }
            }
            else {
                if (key != null) {
                    if (!groups[key]) {
                        groups[key] = [];
                    }
                }
                groups[key].push(item);
            }
            return groups;
        }, {});

        // Sort groups based on custom sheet name order
        const sortedGroups = Object.keys(groupedData).sort((a, b) => {
            const orderA = sheetNameOrder[a] || 9;
            const orderB = sheetNameOrder[b] || 9;
            return orderA - orderB;
        });
        let selectedYear = isSiteConfigEnabled ? year + 1 : year;
        // Rebuild the sorted grouped data object
        const sortedGroupedData: any = {};
        let isDraft: boolean = false;
        let isAtleastOneSubmitted: boolean = false;
        let isApprovedOrPublished: boolean = true;
        let isSubmitted: boolean = true;
        for (const groupName of sortedGroups) {
            if (!isCurrentYear) {
                updateFutureConfigSection(groupName, groupedData?.[groupName]);
            }
            const groupDetails: any = groupedData?.[groupName] && groupedData?.[groupName];
            const templateSheetBenefitId: string = groupDetails && groupDetails[0]?.templateSheetBenefitId;
            const isReviewedStatus: boolean = isReviewedOrSubmittedOrApproved(groupDetails);
            setReviewStatuses(prevState => ({
                ...prevState,
                [groupName]: {
                    templateSheetBenefitId: templateSheetBenefitId,
                    isReviewed: isReviewedStatus,
                    benefitYear: Number(selectedYear)
                },
            }))
            props.updateReviewStatuses(groupName, {
                templateSheetBenefitId: templateSheetBenefitId,
                isReviewed: isReviewedStatus,
                benefitYear: Number(selectedYear)
            })
            sortedGroupedData[groupName] = groupedData[groupName];
        }
        let groupedDataValues = Object.values(sortedGroupedData).flat()
        isSubmitted = isSubmitted && isSubmittedOrApproved(groupedDataValues);
        isApprovedOrPublished = isApprovedOrPublished && isApprovedOrPublishedStatus(groupedDataValues)
        isDraft = isDraft || isAnySameStatus(groupedDataValues, DraftStatus);
        isAtleastOneSubmitted = isAtleastOneSubmitted || isAnySameStatus(groupedDataValues, SubmittedStatus);
        if (isToggleEnabled) {
            setSheetWithQuestions(sortedGroupedData);
            props.setIsApprovedOrPublished(isApprovedOrPublished);

            props.setIsDraft(isDraft);

            props.setIsSubmitted(isSubmitted);

            props.setIsAtleastOneSubmitted(isAtleastOneSubmitted);
        }
        else if (isCurrentYear) {
            props.setIsApprovedOrPublished(isApprovedOrPublished);

            props.setIsDraft(isDraft);

            props.setIsSubmitted(isSubmitted);

            props.setIsAtleastOneSubmitted(isAtleastOneSubmitted);
            setSheetWithQuestions(sortedGroupedData);
            setCurrentSheetWithQuestions(sortedGroupedData);
        }
        else {
            setFutureSheetWithQuestions(sortedGroupedData);
        }
    };

    // Gets the fields left over.
    const getAnsweredFields = (sheetsResponse: any): number => {
        return sheetsResponse?.filter((d: any) => d?.answerValue !== ""
            && d?.answerValue != null && d?.questionName !== "effectivefromdate"
            && d?.questionName !== "effectivetodate")?.length;
    }

    // Gets the total fields length.
    const getTotalFields = (sheetsResponse: any): number => {
        return sheetsResponse?.filter((d: any) => d?.questionName !== "effectivefromdate"
            && d?.questionName !== "effectivetodate")?.length;
    }

    // Checks if the input value is string or empty or undefined.
    const checkIsNotNullOrNotEmptyOrNotUndefined = (inputValue: any): boolean => {
        return inputValue != null && inputValue !== '' && inputValue !== undefined
    }

    // Filter out items where groupName includes "technical"
    const filteredQuestions = (questionsWithAnswers: any) => questionsWithAnswers?.filter((d: any) => {
        return d?.groupName?.toLowerCase().includes("technical") === false;
    });

    // Fetches the sheet questions and benefits.
    const fetchSheetQuestionsAndBenefits = async (shouldShowLoader: boolean) => {
        if (shouldShowLoader) {
            setLoader(true);
        }
        try {
            if (props.planDetails?.insuranceHealthPlanID > 0) {
                const getSheetsWithQuestionPlanLevelURL =  `${GetQuestionsWithAnswers}/2/${props.activeInsuranceCarrier?.insuranceCarrierId}/${props.planDetails?.insuranceHealthPlanID}`;
                const getSheetsWithQuestionCatchAllLevelURL = `${GetQuestionsWithAnswers}/2/${props.activeInsuranceCarrier?.insuranceCarrierId}/undefined`;
                const [sheetsWithQuestionPlanLevelResponse, sheetsWithQuestionCatchAllLevelResponse] = await Promise.all([
                    client.get(getSheetsWithQuestionPlanLevelURL),
                    client.get(getSheetsWithQuestionCatchAllLevelURL),
                ]);

                let filteredCurrentyearCatchAllResponse = filterQuestionsByYear(sheetsWithQuestionCatchAllLevelResponse, year);

                let filteredFutureyearCatchAllResponse = filterQuestionsByYear(sheetsWithQuestionCatchAllLevelResponse, year + 1);

                let filteredCurrentyearPlanLevelResponse = filterQuestionsByYear(sheetsWithQuestionPlanLevelResponse, year);

                filterandStoreisonlypublishforcydata(filteredCurrentyearPlanLevelResponse);

                let filteredFutureyearPlanLevelResponse = filterQuestionsByYear(sheetsWithQuestionPlanLevelResponse, year + 1);

                let combineQuestionsWithAnswersForCurrentYear = combineQuestionsWithAnswers(filteredCurrentyearPlanLevelResponse, filteredCurrentyearCatchAllResponse);

                let combineQuestionsWithAnswersForFutureYear = combineQuestionsWithAnswers(filteredFutureyearPlanLevelResponse, filteredFutureyearCatchAllResponse);

                processAllResponse(combineQuestionsWithAnswersForCurrentYear, combineQuestionsWithAnswersForFutureYear);
            }
            else {
                const getSheetsWithQuestionCatchAllLevelURL = `${GetQuestionsWithAnswers}/2/${props.activeInsuranceCarrier?.insuranceCarrierId}/undefined`;

                let sheetsWithQuestionCatchAllLevelResponse = await client.get(getSheetsWithQuestionCatchAllLevelURL);

                let filteredCurrentYearCatchAllResponse = filterQuestionsByYear(sheetsWithQuestionCatchAllLevelResponse, year);

                filterandStoreisonlypublishforcydata(filteredCurrentYearCatchAllResponse);

                let filteredFutureYearCatchAllResponse = filterQuestionsByYear(sheetsWithQuestionCatchAllLevelResponse, year + 1);

                processAllResponse(filteredCurrentYearCatchAllResponse, filteredFutureYearCatchAllResponse);
            }
            setTimeout(() => {
                setLoader(false);
            },2000)
        }
        catch (error) {
            setLoader(false);
            console.log(error);
        }
    }

    // Gets the valid regex value for a text input.
    const getIsValidRegexValueForTextInput = (question: any) => {
        return question?.isValidRegexValue != null && question?.isMandate && !question?.isValidRegexValue
    }

    // Render the question content.
    const renderQuestionContent = (question: any) => {
        let isDisabled = Object.keys(futureSheetWithQuestions).length !== 0 && futureSheetWithQuestions[question.groupName]?.find((x: Question) => {
            return x.questionId === question.questionId; // Adjust condition as per your requirement
        })?.answerValue !== null;
        return <Row>
            <Col xs={7} xxl={6}>
                <FloatingLabel label=" ">
                    <Form.Control
                        type="text"
                        placeholder=" "
                        onBlur={() => { handleSaveForQuestionsWithTextInput(question) }}
                        onChange={(event) => handleValueChange(question, event.target.value, false)}
                        value={isAnsweredValueEmptyNullUndefined(question.answerValue) ? question.answerValue : ""}
                        style={getIsValidRegexValueForTextInput(question) ? { borderColor: '#dc3545' } : {}}
                        disabled={isSiteConfigEnabled || isDisabled}
                    />
                    {
                        question.isValidRegexValue != null && !question.isValidRegexValue ? (
                            <span className="error-message">{question?.errorMessage}</span>
                        ) : <></>}
                </FloatingLabel>
            </Col>
            <Col xs={5} xxl={6}>
                {(question.question?.toLowerCase() === CardImage?.toLowerCase() && question.answerValue != null && question.answerValue !== '') ? <a className="preview custom-link" target="_blank" href={`${CDNCardImagePath}${question.answerValue}`}>Preview card image</a> : <></>}
                {(question.question?.toLowerCase() === ClientLogo?.toLowerCase() && question.answerValue != null && question.answerValue !== '') ? <a className="preview custom-link" target="_blank" href={`${CDNLogo}${question.answerValue}.png`}>Preview logo</a> : <></>}
            </Col>
        </Row>
    };

    // Sets the error message on subdomain already exists for other carrier.
    const setErrorMessageOnSubDomainExists = (errorMessage: string, question: Question) => {
        // Shallow copy of the questions array.
        const questions = [...sheetWithQuestions[question.groupName]];

        // Find the index of the current question within the array
        const questionIndex = questions?.findIndex((q) => q?.questionId === question?.questionId);

        if (questionIndex !== -1) {
            // Update the answerValue of the question at the found index
            questions[questionIndex].isValidRegexValue = false;
            questions[questionIndex].errorMessage = errorMessage;
        }

        // Update the state with the modified array
        setSheetWithQuestions((prevState) => ({
            ...prevState,
            [question.groupName]: questions,
        }));
    }

    // Checks the existence of a subdomain URL.
    const checkSubDomainUrlExistence = (question: Question) => {
        try {
            const apiUrl = `https://www.whatsmydns.net/api/details?server=qrjnpgnj&type=A&query=${question.answerValue}.${MyBenefitsURL}`;
            client.get(apiUrl)
                .then((response) => {
                    if (response.status && response.data && response.data.data[0] && response.data.data[0].response.length > 0) {
                        SaveSiteConfigWithAnswers(question, false, false);
                    }
                    else if ((response.status && response.data && response.data.data[0] && response.data.data[0].response.length === 0)) {
                        setErrorMessageOnSubDomainExists("Domain not exists in DNS server", question);
                    }
                }).catch((error) => {
                    setErrorMessageOnSubDomainExists("Domain not exists in DNS server", question);
                });
        } catch (error) {
            setErrorMessageOnSubDomainExists("Domain not exists in DNS server", question);
        }
    };

    // Gets the status of the sub domain already exists for other carriers.
    const GetIsSubDomainAlreadyExists = (question: Question) => {
        try {
            const apiUrl = `${GetSubDomain}${props.activeInsuranceCarrier?.insuranceCarrierId}/${question?.answerValue?.trim()}/${year}`;
            client.get(apiUrl)
                .then((response) => {
                    if ((response.data.resultExists === false)) {
                        checkSubDomainUrlExistence(question);
                    }
                    else {
                        setErrorMessageOnSubDomainExists("Subdomain already exists for other carrier", question);
                    }
                }).catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    // Checks and return if the resultarray is equals answerArray or not.
    const isEquivalentAnswer = (resultArray: string[], answerArray: string[]): boolean => {
        if (resultArray.length !== answerArray.length) return false;

        const sortedResultArray = [...resultArray].sort();
        const sortedAnswerArray = [...answerArray].sort();

        return sortedResultArray.every((value, index) => value === sortedAnswerArray[index]);
    }

    // Updates ans saves the filtered question.
    const updateFilteredQuestion = (filteredQuestion:any,resultArray:any) => {
        filteredQuestion = {
            ...filteredQuestion,
            effectiveFrom: getCurrentFormattedDate(),
            answerValue: JSON.stringify(resultArray)
        };
        SaveSiteConfigWithAnswers(filteredQuestion, false, false);
    }

    // Handle Only publish for current year question.
    const handlePublishOnlyForCY = (question: any, isRemoved: boolean = false) => {
        let filteredQuestion = onlyPublishForCYQuestion.find((x: any) => x.groupName === question.groupName);
        let answerValue = filteredQuestion.versionStatus && filteredQuestion.versionStatus.toLowerCase() !== null
            && filteredQuestion.versionStatus.toLowerCase() !== PublishedStatus.toLowerCase() && filteredQuestion.versionStatus.toLowerCase() !== ApprovedStatus.toLowerCase() && filteredQuestion.versionStatus.toLowerCase() !== FailedStatus.toLowerCase()
            ? filteredQuestion.answerValue : '';
        let resultArray: string[] = [];
        if (answerValue === '' || answerValue === null) {
            resultArray = [question.publishJsonNode];
        } else {
            resultArray = JSON.parse(answerValue);
            if (Array.isArray(resultArray)) {
                const uniqueArray = Array.from(new Set(resultArray));
                if (!uniqueArray.includes(question.publishJsonNode)) {
                    uniqueArray.push(question.publishJsonNode);
                }
                resultArray = uniqueArray;
            }
        }
        if (answerValue !== '' && answerValue !== null && isRemoved) {
            resultArray = resultArray?.filter((x: any) => x !== question.publishJsonNode);
        }
        if (answerValue === '' || answerValue === null)
            updateFilteredQuestion(filteredQuestion, resultArray);
        if (answerValue !== '' && answerValue !== null && !isEquivalentAnswer(resultArray, JSON.parse(answerValue)))
            updateFilteredQuestion(filteredQuestion, resultArray);
    }

    // Saves the site configuration.
    const SaveSiteConfigWithAnswers = async (question: any, futureConfig: boolean, isRemoved: boolean): Promise<any> => {
        if (!isRemoved)
            localStorage.removeItem("RemoveMessage");
        let isSucessResp = false;
        if (question != null && question !== undefined) {
            const effectiveDate = question.effectiveFrom && question.effectiveFrom.includes(year + 1) ? getCurrentFormattedDate(new Date(2025, 0, 1)) : getCurrentFormattedDate();
            const saveSiteConfigRequest: SaveSiteConfigRequest = {
                insuranceCarrierID: props.activeInsuranceCarrier?.insuranceCarrierId,
                insuranceHealthPlanID: props.planDetails?.insuranceHealthPlanID?.toString() ?? '',
                benefitYear: question.benefitYear,
                templateSheetBenefitID: question?.templateSheetBenefitId,
                effectiveFrom: effectiveDate.toString(),
                effectiveTo: getCurrentFormattedDate(new Date(2099, 11, 31)).toString(),
                questionID: question?.questionId,
                answerValue: question?.answerValue,
                userName: GetKeyValueFromLocalStorage("username") ?? '',
                isRemoved: isRemoved,
            }
            if (futureConfig || isRemoved) {
                if (question.publishJsonNode !== null && question.publishJsonNode !== '')
                    handlePublishOnlyForCY(question, isRemoved);
            }
            await client.post(SaveSiteConfiguration, saveSiteConfigRequest)
                .then(async () => {
                    if (futureConfig) {
                        handlePublishOnlyForCY(question);
                    }
                    updateAlertPopup(true);
                    if (reviewStatuses[question.groupName]?.isReviewed && question.benefitYear !== futureYear) {
                        await props.changeStatusToDraft(question?.groupName, [question?.templateSheetBenefitId].join(','), question.benefitYear);
                    }
                    resetRegexValueOnQuestion(question);
                    isSucessResp = true;
                })
                .catch((error) => {
                    fetchSheetQuestionsAndBenefits(false);
                    updateAlertPopup(false);
                    console.log(error);
                })
        }
        return isSucessResp;
    }

    // Returns true if the string belong to not applicable type; else false.
    const isBelongsToNotApplicable = (subDomain: string) => {
        return (subDomain === "n/a" || subDomain === "na" ||
            subDomain === "noapplicable" || subDomain === "notapplicable");
    }

    // Handles the save of the questions with text input.
    const handleSaveForQuestionsWithTextInput = (question: any) => {
        if (question?.attributeCode?.toString()?.toUpperCase() === "SUBDOMAIN") {
            if (question.isValidRegexValue) {
                if (isBelongsToNotApplicable(question?.answerValue?.trim()?.toString()?.toLowerCase())) {
                    SaveSiteConfigWithAnswers(question, false, false);
                }
                else {
                    GetIsSubDomainAlreadyExists(question);
                }
            }
        }
        else {
            if ((question.isValidRegexValue && question?.answerValue?.trim()?.toString()?.length > 0) || question?.answerValue?.trim()?.toString()?.length === 0) {
                SaveSiteConfigWithAnswers(question, false, false);
            }
        }
    };

    // Handles the copy functionality of SiteConfig for a carrier.
    const handleCopySiteConfigData = async () => {
        props.setLoading(true);
        try {
            const response = await axios.post(
                `${CopySiteConfigData}/${props.activeInsuranceCarrier?.insuranceCarrierId}/${props.activeInsuranceCarrier?.benefitYear}/Site/${GetKeyValueFromLocalStorage("username") ?? ''}`
            );
            // If the response status is 200, the request was successful
            if (response.status === 200) {
                await fetchSheetQuestionsAndBenefits(true);
                handleCopySiteConfig();
                props.setLoading(false);
            } else {
                handleCopySiteConfig();
                props.setLoading(false);
            }
        } catch (error) {
            handleCopySiteConfig();
            props.setLoading(false);
        }
    };

    // Handles the copy button.
    const handlesCopyButton = () => {
        handleCopySiteConfigData();
    }

    // Gets the percentage for the carrier answered questions.
    const getPercentageForCarrier = (answeredValuesCount: number, totalQuestionsCount: number): number => {
        var mathRoundValue = Math.round((answeredValuesCount / totalQuestionsCount) * 100)
        return mathRoundValue;
    }

    // Sets the visibility of the mandatory message modal popup.
    const setMandatoryMessageVisibility = (mandatoryState: boolean) => {
        setShowMandatoryMessage(mandatoryState);
    }

    // Returns false if the value is null or empty or undefined.
    const isAnsweredValueEmptyNullUndefined = (answerValue: any) => {
        return answerValue != null && answerValue !== "" && answerValue !== undefined;
    }

    // Updates the state variable for review statuses.
    const setStatesOfReviewStatus = (groupName: string, templateSheetBenefitId: string, isChecked: boolean, selectedYear: number = year) => {
        setReviewStatuses(prevState => ({
            ...prevState,
            [groupName]: {
                templateSheetBenefitId: templateSheetBenefitId,
                isReviewed: isChecked,
                benefitYear: Number(selectedYear)
            },
        }))
    }

    // Function to update questions' status in the sheet
    const updateQuestionsStatus = (prevState:any, groupName:string, status:string) => {
        if (prevState[groupName]) {
            const updatedQuestions = prevState[groupName].map((question:any) => ({
                ...question,
                versionStatus: status,
            }));
            return {
                ...prevState,
                [groupName]: updatedQuestions,
            };
        }
        return prevState;
    };

    // Updates the review/draft status based on the selected year
    const updateData = (groupName: string, selectedYear: number, status: string) => {
        if (selectedYear === year) {
            setCurrentSheetWithQuestions(prevState => {
                const updatedState = updateQuestionsStatus(prevState, groupName, status);
                return updatedState;
            });
        } else if (selectedYear === year + 1) {
            setFutureSheetWithQuestions(prevState => {
                const updatedState = updateQuestionsStatus(prevState, groupName, status);
                return updatedState;
            });
        }
    };

    // Sets the current group status to reviewed
    const setCurrentGroupStatusToReviewed = (groupName: string, templateSheetBenefitId: string, isChecked: boolean, selectedYear: number) => {
        props.setReviewStatuses(
            groupName,
            {
                templateSheetBenefitId: templateSheetBenefitId,
                isReviewed: isChecked,
                benefitYear: Number(selectedYear),
            }
        );

        const status = isChecked ? "reviewed" : "draft";
        updateData(groupName, selectedYear, status);
    };


    // Gets the template sheet benefit id from the questions array.
    const getTemplateSheetBenefitId = (questions: any): string => {
        return questions && questions[0]?.templateSheetBenefitId
    }

    // Check if Mandatory Questions are Answered for the Specified Group
    const checkMandatoryQuestionsForGroup = (groupName: string, isChecked: boolean) => {
        let selectedYear = dropdownConfigurator ? year + 1 : year;
        const mandateQuestions = getMandatoryQuestions(groupName, sheetWithQuestions);
        const isSomeInValidRegexValues = mandateQuestions?.filter((mq: any) => mq.isValidRegexValue != null)?.some((mq: any) => !mq.isValidRegexValue);
        const isAllMandatoryQuestionsAnswered = mandateQuestions?.every((q: any) => isAnsweredValueEmptyNullUndefined(q?.answerValue));
        if (sheetWithQuestions && sheetWithQuestions?.[groupName]?.length > 0) {
            if (isAllMandatoryQuestionsAnswered && mandateQuestions?.length > 0 && !isSomeInValidRegexValues) {
                const templateSheetBenefitId: string = getTemplateSheetBenefitId(mandateQuestions);
                setStatesOfReviewStatus(groupName, templateSheetBenefitId, isChecked, selectedYear);
                setCurrentGroupStatusToReviewed(groupName, templateSheetBenefitId, isChecked, selectedYear);
                props.setIsApprovedOrPublished(false);
            }
            else if (mandateQuestions?.length === 0) {
                const templateSheetBenefitId: string = getTemplateSheetBenefitId(sheetWithQuestions[groupName]);
                setStatesOfReviewStatus(groupName, templateSheetBenefitId, isChecked, selectedYear);
                setCurrentGroupStatusToReviewed(groupName, templateSheetBenefitId, isChecked, selectedYear);
                props.setIsApprovedOrPublished(false);
            }
            else {
                setMessage(MandatoryMessage);
                setMandatoryMessageVisibility(true);
            }
        }
    }

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        fetchSheetQuestionsAndBenefits(true);
    }, []);

    useEffect(() => {
        fetchSheetQuestionsAndBenefits(false);
    }, [props.refreshSiteConfigurations])


    return (
        <>
            <AlertPopup message={SaveSiteConfig} />
                <div className={`${loader ? 'loading-skeleton' : ''}`}>

                    <Col xl={10}>
                        <div className="configuration-page">
                            <div className="benefit-section hide-scroll">
                                <Scrollbars
                                    autoHide
                                    autoHideTimeout={100}
                                    autoHideDuration={200}
                                    thumbMinSize={80}
                                    ref={scrollbarsRef}
                                    onScroll={handleScroll}
                                >
                                    {loader ? (
                                        Array.from({ length: 7 }).map((_, index) => (
                                            <React.Fragment >
                                                <div className="benefit-title-section position-sticky">
                                                    <h4>Login</h4>
                                                    <div className="benefit-title-right">
                                                        <div className="input-box">
                                                            <Form.Check aria-label="option 1" className="form-check" />
                                                            <span>Review Later</span>
                                                            <img className="img-fluid ms-1" src={Review} alt=" " />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="benefit-content-section" id={`section-${index}`}>
                                                    {Array.from({ length: 5 }).map((_, index) => (
                                                        <div className="question-main"><div className="question" >
                                                            <div className="d-flex flex-row align-items-center">
                                                                <h6>Require the member to register for the MyBenefits portal prior to login?</h6>
                                                                <sup>*</sup>
                                                                <img className="img-fluid align-self-start ms-2" src={Info} alt=" " title={index?.toString()} />
                                                            </div>
                                                            <div className="question-content">
                                                                <Button className={`btn-outline active`}>No</Button>
                                                                <Button className={`btn-outline active`}>Yes</Button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        Object.keys(sheetWithQuestions || [])?.map((key: any, index: number) => (
                                            <div key={index} id={`section-${key}`}>
                                                <div className="main-question-section">
                                                    <div className="benefit-title-section position-sticky">
                                                        <h4>{key} ({sheetWithQuestions[key]?.filter((question: any) => !isExcludedQuestion(question))?.length})</h4>
                                                        <div className="benefit-title-right">
                                                            {/*{!isSiteConfigEnabled ?*/}
                                                                <div className="input-box">
                                                                    <Form.Check aria-label="option-check" className="form-check" checked={reviewStatuses[key]?.isReviewed || false} onChange={(event) => {
                                                                        checkMandatoryQuestionsForGroup(key, event.target.checked);
                                                                    }} />
                                                                <span>
                                                                    {(() => {
                                                                        const selectedYear = dropdownConfigurator ? year + 1 : year;
                                                                        return reviewStatuses[key]?.isReviewed
                                                                            ? `Reviewed ${selectedYear}`
                                                                            : `Review ${selectedYear}`;
                                                                    })()}
                                                                </span>
                                                                <img className="img-fluid ms-1" src={Review} alt=" " />
                                                            </div>
                                                        {/*        :<></>}*/}
                                                        </div>
                                                    </div>
                                                    <div className="benefit-content-section">
                                                        {((sheetWithQuestions && sheetWithQuestions[key]) || [])?.filter(sq => !isExcludedQuestion(sq.question)).map((question: any, index: any) => {
                                                            const questionType = question?.questionType?.toString()?.toLowerCase();
                                                            let isDisabled = Object.keys(futureSheetWithQuestions).length !== 0 && futureSheetWithQuestions[key]?.find((x: Question) => {
                                                                return x.questionId === question.questionId; 
                                                            })?.answerValue !== null;
                                                            return (
                                                                <div className={isDisabled && HighlightFutureConfig ? "question-main feature-config-available" : "question-main"}>
                                                                    <div className="question" key={index}>
                                                                    <div className='questions-inner'>
                                                                        <h6>
                                                                            {question.question}
                                                                            {question.isMandate ? <sup>*</sup> : <></>}
                                                                            {question?.toolTip?.length > 0 ? <span className="show-popup ms-1">
                                                                                <img className="img-fluid" src={Info} alt=" " />
                                                                                <span className="hover-popup bottom">{question?.toolTip}</span>
                                                                            </span>
                                                                                : <></>}
                                                                        </h6>
                                                                            {isDisabled ? (<div className="future-site-active" onClick={HighlightFutureConfig ? (e:any) => {e.preventDefault() }:() => {
                                                                            handleFutureConfig(question, key);
                                                                            }}>
                                                                                {HighlightFutureConfig ?
                                                                                    <span className='future-site-date'>From Jan 1, {year + 1}</span> : <></>}
                                                                            <FutureConfigIcon
                                                                                isHoverAlignedLeft={true}
                                                                                />
                                                                        </div>
                                                                        ) :
                                                                           !HighlightFutureConfig && question.question?.toLowerCase() !== SubDomainQuestion.toLowerCase() &&
                                                                            <span className='future-site show-popup' onClick={() => {
                                                                                handleFutureConfig(question, key);
                                                                            }}>
                                                                                <img className="img-fluid" src={FutureSiteDefault} />
                                                                            </span>

                                                                        }
                                                                    </div>
                                                                    {questionType === 'dropdown' ? (
                                                                        <div className="question-content">
                                                                            {question?.options?.toString()?.split(',')?.map((option: any, index: any) => (
                                                                                <Button
                                                                                    key={index}
                                                                                    className={`btn-outline ${question.answerValue === option ? 'active' : ''}`}
                                                                                    onClick={() => handleValueChange(question, option, true)} disabled={isSiteConfigEnabled || isDisabled}>
                                                                                    {option}
                                                                                </Button>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        renderQuestionContent(question)
                                                                    )}
                                                                    <span className="question-number d-none">{question.sheetColumn}</span>
                                                                    <a className="question-comment d-none" href=" ">
                                                                        <img className="img-fluid" src={Comment} alt=" " />
                                                                    </a>
                                                                </div></div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </Scrollbars>
                            </div>
                            <div className="right-configurations">
                                {isFutureConfigSection?.some((x: any) => x.isFutureConfig === true) &&
                                    <div>
                                        <p>Show Future Config as of</p>
                                        <div className='show-feature-config'>
                                            <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={dropdownConfigurator}
                                            onChange={() => { handleToggle ('','',false)}}
                                            />
                                            <Dropdown className='dropdown-arrow'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="rounded-pill m-0 btn-outline" disabled={!dropdownConfigurator}>
                                                    <span>{year+1}</span>
                                                    {/*    <img className="img-fluid arrow ms-1" src={DownArrowBlack} alt=" " />*/}
                                                </Dropdown.Toggle>
                                                {/*    {dropdownConfigurator ?*/}
                                                {/*        <Dropdown.Menu defaultValue={selectedYear}>*/}
                                                {/*            <Dropdown.Item onClick={handleDropdownChange}>{year + 1}</Dropdown.Item>*/}
                                                {/*            <Dropdown.Item onClick={handleDropdownChange}>{year}</Dropdown.Item>*/}
                                                {/*        </Dropdown.Menu> : <></>}*/}
                                            </Dropdown>
                                        </div>
                                    </div>
                                }
                                <div className="sticky-nav hide-scroll">
                                    <ul>
                                        {loader ? (
                                            Array.from({ length: 7 }).map((_, index) => (
                                                <li className={index === currentNavIndex ? "active" : ''} style={{ cursor: 'pointer' }}>
                                                    <span className="me-2">{index}</span>
                                                </li>
                                            ))) : (
                                            Object.keys(sheetWithQuestions || [])?.map((key: any, index: number) => (
                                                <li
                                                    className={index === currentNavIndex ? "active" : ''}
                                                    style={{ cursor: 'pointer' }}
                                                    key={key}
                                                    onClick={() => {
                                                        const sectionElement = document.getElementById(`section-${key}`);
                                                        if (sectionElement) {
                                                            sectionElement.scrollIntoView({ behavior: "smooth" });
                                                        }
                                                    }}>
                                                    <span className="me-2">{key}</span>
                                                    {index === currentNavIndex ?
                                                        reviewStatuses[key]?.isReviewed ? (
                                                            <img
                                                                className="img-fluid"
                                                                src={SecondaryTick}
                                                                alt="Reviewed"
                                                            />
                                                        ) : <></>
                                                        : reviewStatuses[key]?.isReviewed ? (
                                                            <img
                                                                className="img-fluid"
                                                                src={PrimaryTick}
                                                                alt="Reviewed"
                                                            />
                                                        ) : <></>}
                                                    {
                                                        isFutureConfigSection.filter((x: any) => x.groupName === key)[0]?.isFutureConfig ? < FutureConfigIcon isHoverAlignedLeft={false} /> : <></>
                                                    }

                                                </li>
                                            ))
                                        )}
                                    </ul>
                                </div>
                            <div className="fields-timer">
                                    <ProgressIndicators percentage={getPercentageForCarrier(currentAnswersLength, currentQuestionsLength)} />
                                    <span>
                                        {currentAnswersLength} fields left
                                    </span>
                            </div>
                            <div className="sticky-nav hide-scroll">
                                <table>
                                    <tr>
                                        <td><img className="dropdown-arrow dropdown" src={FutureSiteDefault} /></td>
                                        <td> &nbsp; {futureYear} Configuration not available</td>
                                    </tr>
                                    <br />
                                    <tr>
                                        <td><img className="dropdown-arrow " src={FutureSite} /></td>
                                        <td> &nbsp; {futureYear} Configuration available</td>
                                    </tr>
                                </table>
                            </div>

                            </div>
                        </div>
                    </Col>
                    <Col xl={3}>
                        {(props.planDetails?.insuranceHealthPlanID === undefined || props.planDetails?.insuranceHealthPlanID <= 0) && props.activeInsuranceCarrier?.benefitYear !== year ?
                            <div className="content-controls d-none">
                                <h5>Content Controls</h5>
                                <p>{`Copy site configuration from ${year} benefit year`}</p>
                                <Button className="btn-outline-primary btn-sm" onClick={(e: any) => { e.stopPropagation(); setShowCopySiteConfig(true) }}>Copy</Button>
                            </div> : <></>
                        }
                    </Col>

                </div >

            {/* Show review submission message  */}
            <Modal show={showMandatoryMessage} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton onClick={() => { setMandatoryMessageVisibility(false) }}>
                    <Modal.Title className="justify-content-center">Review Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{message}</h5>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button className="btn-secondary" onClick={() => { setMandatoryMessageVisibility(false) }}>Ok</Button>
                </Modal.Footer>
            </Modal>


            {/* Content Controls Modal Popup */}
            <Modal show={showCopySiteConfig} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton onClick={handleCopySiteConfig}>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Copying the site configuration from {props.activeInsuranceCarrier?.benefitYear - 1} will replace current progress if data exists.</h5>
                    {/*<p>Your progress in site configuration will be replaced with default values and you cannot undo this action.</p>*/}
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button className="btn-secondary" onClick={handlesCopyButton}>Continue to copy</Button>
                    <Button className="btn-outline-primary" onClick={handleCopySiteConfig}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
