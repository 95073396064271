import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Interface for props.
interface IProps {
    percentage: number;
}


// Exports the spinner component.
export const ProgressIndicators = (props: IProps) => {
    return <div className='progress'>
        <CircularProgressbar value={props.percentage} strokeWidth={50}
            styles={buildStyles({
                rotation: 360,
                strokeLinecap: "butt"
            })} />
    </div>
}
