import Button from "react-bootstrap/Button";
import "./HomeInfo.scss";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export const HomeInfo = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const initializeSignIn = () => {
    if (
      !isAuthenticated &&
      instance &&
      instance.getAllAccounts()?.length <= 0
    ) {
      return instance?.loginRedirect();
    }
  };
  return (
    <>
      <section className="home-page">
        <div className="info-section d-flex justify-content-center text-center">
          <div className="info-inner-section">
            <h1 style={{ color: "#fff" }}>
              Effortless Onboarding With NationsBenefits{" "}
              <span style={{ color: "#fff" }}>Onboarding Tool</span>
            </h1>
            <p style={{ color: "#fff" }}>
              Streamline your Onboarding Process with our user-friendly tool and
              access our wide variety of technology-based solutions that go
              beyond original Medicare to help health plan members make their
              desired health goals a reality.
            </p>
            {!isAuthenticated && (
              <Button
                className="btn-secondary"
                role="button"
                onClick={() => {
                  initializeSignIn();
                }}
                style={{ color: "#fff" }}
              >
                Login
              </Button>
            )}
            <Button
              className="btn-outline-primary"
              role="button"
              href="/Features"
            >
              View Features
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};
