import { Login } from "../Login/Login";

export const ContentManagement = (props: any) => {

    const AuthLogin = [{
        Heading: '1 a. Login',
        Purpose: 'The &ldquo;Login&rdquo; feature is implemented to allow a member to securely access everything from their Personal Information to many Benefit-related features provided by NationsBenefits.',
        DefaultFeatureBehavior: 'Our Login feature works in the most familiar way! When a Member attempts to login, we verify the credentials entered and authenticate their entry.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];
    const LoginNations = [{
        Heading: '1 a i) Login Nations',
        Purpose: 'The "Login" functionality serves a crucial purpose by enabling members to securely access a comprehensive range of features, including their Personal Information and an extensive array of Benefit-related services offered by NationsBenefits.',
        DefaultFeatureBehavior: "Our Login feature operates with utmost efficiency and adherence to security standards. When a member initiates the login process, we meticulously validate the entered credentials, ensuring a robust authentication mechanism that guarantees a seamless and secure entry into the Benefits Pro.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/LoginNations.png",
        NameOfImagePng1: "Login Nations"
    }];

    const PreLoginPortalDescription = [{
        Heading: '1 a ii) Pre&#45;Login Portal Description',
        Purpose: 'This feature enhances the user experience by displaying a prominent yellow banner on the header of the login page. The banner effectively communicates the exclusive shopping opportunity for products on the NB 1.0/OTC portal.',
        DefaultFeatureBehavior: 'By default, this feature is enabled for all carriers, ensuring that every user can benefit from the enhanced messaging on the login page.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const CarrierInfoIcon = [{
        Heading: '1 a iii) Carrier info icon',
        Purpose: 'The purpose of this feature is to offer users the ability to customize the display of the client info icon in the Benefits Pro application according to their preferences.',
        DefaultFeatureBehavior: "By default, this feature will not be available for this carrier. However, users can request to enable this feature on an individual basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/Carrierinfoicon.png",
        NameOfImagePng1: "Carrier info icon",
    }];

    const HideClientLogoBeforeLogin = [{
        Heading: '1 a iv) Hide Client Logo Before Login',
        Purpose: 'The purpose of the "Pre-login Client Logo Concealer" feature in the NB 1.0/OTC portal is to provide a seamless and professional user experience by temporarily hiding the client logo before the login process for clients and insurance carriers. By concealing the client logo during the pre-login stage, the feature aims to create a neutral and standardized interface that doesn&#39;t bias, or influence users based on specific branding. This helps to establish a consistent and unbiased environment for users as they engage with the portal, allowing them to focus solely on the authentication process without any distracting visual elements. ',
        DefaultFeatureBehavior: 'By default, this feature will not be available for this carrier. However, users can request to enable this feature on an individual basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/HideClientLogoBeforeLogin.png",
        NameOfImagePng1: "Hide Client Logo Before Login",
    }];

    const ClientCustomBranding = [{
        Heading: '1 a v) Client Custom Branding',
        Purpose: 'The purpose of this feature is to enhance the login experience in the NB 1.0/OTC portal by allowing users to personalize their accounts with a custom image. This feature enables users to display a unique image of their choice alongside their login credentials, adding a touch of personalization and identity to the login process.',
        DefaultFeatureBehavior: 'By default, this feature will not be available for this carrier. However, users can request to enable this feature on an individual basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/ClientCustomBranding.png",
        NameOfImagePng1: "Client Custom Branding",
    }];

    const HideActivateCardOptionLogin = [{
        Heading: '1 a vi) Hide Activate Card Option on Login',
        Purpose: 'The card activation feature ensures a secure and reliable process for confirming the receipt of your new card and verifying its ownership, thereby offering protection against credit card fraud.<br/><br/>This feature enhances user experience by providing customization options to display a dedicated activation button on the login page for clients/insurance carriers in the NB 1.0/OTC portal.<br/><br/>To activate your new benefit card, you will be required to enter the card number and your date of birth. This feature offers the flexibility to activate your card through convenient channels such as online banking, mobile banking, or by contacting the card provider directly.',
        DefaultFeatureBehavior: 'By default, this feature will not be available for this carrier. However, users can request to enable this feature on an individual basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/HideActivateCardOptiononLogin.png",
        NameOfImagePng1: "Hide Activate Card Option on Login",
    }];

    const PhoneNumber = [{
        Heading: '1 a vii) Phone Number',
        Purpose: 'The Phone Number pertains to the business contact point utilized for interacting with a company. This feature enables customizable display options for the phone number in three specific areas within the NB 1.0 / OTC Portal. The purpose of the Phone Number configuration is to provide a designated point of contact for business-related communications. Users can conveniently reach out to the company through the displayed phone number.',
        DefaultFeatureBehavior: 'By default, the phone number is displayed within the application to assist users who encounter issues during the login or registration process. However, it is important to note that this feature is not automatically provided to clients or insurance carriers. Instead, access to the phone number will be granted solely upon request.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/PhoneNumber.png",
        NameOfImagePng1: "Phone Number",
    }];

    const MemberAuthentication = [{
        Heading: '1 a viii) Member Authentication',
        Purpose: 'The Login Configuration Options comprise a set of credential combinations that result in variations in the login process. To ensure an optimal user experience for your clients, we have developed multiple Login Templates. This empowers you to select the most suitable template based on your organizational policies.',
        DefaultFeatureBehavior: "Currently, we offer three distinct Login Templates for you to choose from. Each template is designed to cater to specific needs and preferences. The available Login Templates are as follows:<ul class='list-numaric'><li>Nations Standard Template:<ul><li>Credential Combination: Member ID + Password & Registration</li><li>Additional Functionality: Register action available</li></ul></li> <li>Wex Login Template:<ul><li>Credential Combination: Member ID + Date of Birth </li></ul></li> <li> Incomm Login Template:<ul><li>Credential Combination: Incomm Benefit Card Number + Date of Birth</li></ul></li></ul>Please note that the &#34;Register&#34; action is exclusively available within the &#34;Nations Standard&#34; Template.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/MemberAuthentication.png",
        NameOfImagePng1: "Member Authentication",
    }];

    const DisplayMessageOnLoginTemplate = [{
        Heading: '1 a ix) Display message on login template',
        Purpose: 'This functionality enables carriers to present a customized message on the login template, providing users with a customized communication experience upon login.',
        DefaultFeatureBehavior: "By default, this feature will not be available for this carrier. However, users can request to enable this feature on an individual basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/DisplayMessageonLoginTemplate.png",
        NameOfImagePng1: "Display message on login template",
    }];

    const PlaceholderText = [{
        Heading: '1 a x) Placeholder Text',
        Purpose: 'Placeholder Text refers to a visual cue designed to guide and prompt users to input the appropriate information into the login fields',
        DefaultFeatureBehavior: "When a field is selected, the Placeholder text will seamlessly transition to the top-left corner of the text field, creating an active state. This ensures that users are consistently informed about the expected input within the text field. Please refer to the accompanying image for a visual representation of how Placeholders are employed in our system.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/PlaceholderText.png",
        NameOfImagePng1: "Placeholder Text",
    }];

    const MaximumLengthOfMemberID = [{
        Heading: '1 a xi) Maximum Length of Member ID',
        Purpose: 'The "Maximum Member ID Length" feature establishes a predetermined character limit for the Member ID field within the login template. This ensures consistency and data integrity in the login process.',
        DefaultFeatureBehavior: "By default, this feature is not activated for all clients/insurance carriers. However, upon explicit client request, it can be selectively enabled on a per-client basis, thereby providing a customized login experience that aligns with their specific requirements.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/MaximumLengthofMemberID.png",
        NameOfImagePng1: "Maximum Length of Member ID",
    }];

    const LoginInformationHelpText = [{
        Heading: '1 a xii) Login Information Help Text',
        Purpose: 'The Member ID Login Tooltip feature empowers users to personalize the information displayed in an accompanying information icon positioned next to the Member ID field.',
        DefaultFeatureBehavior: "By default, this configuration remains inactive for all clients/insurance carriers. However, upon explicit client request, it can be selectively enabled on a per-client basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const CustomizableTextForPreLoginInformationIcon = [{
        Heading: '1 a xiii) Customizable text for pre-login information icon',
        Purpose: 'To provide access to change the text in an information icon before login in NB 1.0/ OTC portal.',
        DefaultFeatureBehavior: "By default, this configuration remains inactive for all clients/insurance carriers. However, upon explicit client request, it can be selectively enabled on a per-client basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const CustomizableHeroBannerHeadingForPreLogin = [{
        Heading: '1 a xiv) Customizable Hero Banner Heading for Pre-Login',
        Purpose: '',
        DefaultFeatureBehavior: "",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const ProviderHelpContact = [{
        Heading: '1 a xv) Provider Help contact',
        Purpose: 'To provide access to change the text in an information icon before login in NB 1.0/ OTC portal.',
        DefaultFeatureBehavior: "By default, this configuration remains inactive for all clients/insurance carriers. However, upon explicit client request, it can be selectively enabled on a per-client basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/ProviderHelpContact.png",
        NameOfImagePng1: "Provider Help Contact",
    }];

    const DisplayFAQ = [{
        Heading: '1 a xvi) Display FAQ',
        Purpose: 'The purpose of this feature is to provide a comprehensive repository of Frequently Asked Questions (FAQs) on the NB 1.O portal. Users can access this section to find answers to commonly asked questions and gain a better understanding of various aspects related to the application.',
        DefaultFeatureBehavior: "By default, this configuration remains inactive for all clients/insurance carriers. However, upon explicit client request, it can be selectively enabled on a per-client basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/DisplayFAQ.png",
        NameOfImagePng1: "Display FAQ",
    }];

    const CustomDisclaimerOnDayDelivery = [{
        Heading: '1 a xvii) Custom Disclaimer On 2 Day Delivery',
        Purpose: 'The Custom Disclaimer on 2-Day Delivery feature allows users to customize a message that is displayed along with the estimated 2-day delivery timeframe on the login page image within the NB 1.0 portal.<br/><br/>Example: For Fallon Health, the two-day delivery policy may be subject to potential disruptions caused by unforeseen events such as adverse weather conditions, carrier delays, or national and local emergencies.',
        DefaultFeatureBehavior: "By default, this feature is not provided to clients/insurance carriers. It is available solely upon specific request.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/CustomDisclaimerOn2DayDelivery.png",
        NameOfImagePng1: "Custom Disclaimer On 2 Day Delivery",
    }];

    const UserRegistration = [{
        Heading: '1 a xviii) User Registration ',
        Purpose: 'User registration refers to the process through which individuals become registered users of a website, program, or other system. Registered users provide specific credentials, such as a username or email address, along with a password, to authenticate their identity. This process, commonly known as logging in, allows users to access system features and services. The registration feature offers the option to display or hide the registration button until the user logs in, ensuring the protection of their personal information. Additionally, Private Domain Registration shields user information from telemarketers, spammers, and bots.',
        DefaultFeatureBehavior: "By default, the system includes a dedicated Register page in the Benefits Pro accessible from the login box or through the following link: <a href=&#34;https://[carrier].nationsbenefits.com&#34;>https://[carrier].nationsbenefits.com</a>. Please replace &#34;Carrier&#34; with the name of your specific carrier, resulting in a personalized URL like <a href=&#34;https://Atena.nationsbenefits.com&#34;>https://Atena.nationsbenefits.com</a>.<br/><br/>For select carriers, the option for user registration may not be available by default and can be provided upon request. The default Benefits Pro registration page is designed with a generic layout, while the Registrations'` feature empowers the registration page with a much-needed makeover. Users can preview their insurance card number by clicking the info icon next to the member ID.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/UserRegistration.png",
        NameOfImagePng1: "User Registration",
    }];

    const MemberMustBeRegisteredToLogin = [{
        Heading: '1 a xix) Member must be registered to login',
        Purpose: 'This feature establishes a mandatory registration process for accessing the NB 1.O portal, thereby ensuring that only registered users can log in and utilize its functionalities.',
        DefaultFeatureBehavior: "By default, this feature is not included as part of the standard offering to the client/insurance carrier. However, it can be made available upon specific request, enabling organizations to tailor their portal access requirements as per their unique needs.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/MemberMustbeRegisteredtoLogin.png",
        NameOfImagePng1: "Member must be registered to login",
    }];

    const AutoLogin = [{
        Heading: '1 a xx) Auto Login',
        Purpose: 'When users try to log in to a website with LINE (After registration) Login, they must enter their registered member id/ dob and password into a dialog that is displayed in the browser. However, in certain situations, this process can be skipped when a user is redirected to the login dialog. This is called auto-login.<br/><br/>This feature will be allowing the users to redirect to the storefront of the portal after successful registration by skipping the login page.',
        DefaultFeatureBehavior: "By default, this feature will not be offered for this carrier. Only on a request basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const DisplayTollFreeNumber = [{
        Heading: '1 a xxi) Display TFN (Toll Free Number)',
        Purpose: 'The purpose of the Toll-Free Number feature in the NB 1.0/OTC/MEP portal is to provide clients and insurance carriers with easy access to a dedicated phone line. By displaying the Toll-Free Number prominently in the footer, users can quickly reach out for assistance, clarifications, or support regarding the portal&#39;s functionalities, insurance policies, or any related inquiries. This feature aims to enhance customer experience and ensure efficient communication between the portal users and the support team.',
        DefaultFeatureBehavior: "This feature will not be available by default for this carrier. On a case-by-case basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const HideTheBBBCertificate = [{
        Heading: '1 a xxii) Hide the BBB Certificate',
        Purpose: 'When a website has a valid certificate, it means that a certificate authority has verified that the web address belongs to that organization. In a market crowded with businesses vying for customers, BBB Accreditation provides consumers with the assurance that they are dealing with an ethical and vetted company. Apply for BBB Accreditation to demonstrate to customers that your company operates in an honest and ethical manner.<br/><br/>Based on this flag system, the BBB certificate in the page&#39;s footer should be displayed or hidden.',
        DefaultFeatureBehavior: "This feature will not be available by default for this carrier. On a case-by-case basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/hidebbb.png",
        NameOfImagePng1: "Hide the BBB Certificate",
    }];

    const CustomDisclaimer = [{
        Heading: '1 a xxiii) Custom Disclaimer',
        Purpose: 'The carriers will be able to provide any unique disclaimer under this configuration, and it will appear in the footer of the Benefits Pro.',
        DefaultFeatureBehavior: "",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/customdisclaimer.png",
        NameOfImagePng1: "Custom Disclaimer",
    }];

    const CustomTrademark = [{
        Heading: '1 a xxiv) Custom Trademark',
        Purpose: 'This configuration will allow the carriers to provide any custom trademarks to display on the footer of Benefits Pro.',
        DefaultFeatureBehavior: "",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/CustomTrademark.png",
        NameOfImagePng1: "Custom Trademark",
    }];

    const CivilRightsDisclaimer = [{
        Heading: '1 a xv) Civil Rights Disclaimer',
        Purpose: 'By using this configuration, the carriers will be able to supply a unique civil rights disclaimer in the Benefits Pro footer.',
        DefaultFeatureBehavior: "This feature will not be available by default for this carrier. On a case-by-case basis.",
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/CivilRightsDisclaimer.png",
        NameOfImagePng1: "Civil Rights Disclaimer",
    }];




















    const Register = [{
        Heading: '1 b. Register',
        Purpose: 'A registered user is a user of a website, program, or other system who has previously registered. Registered users normally provide some sort of credentials (such as a username or e-mail address, and a password) to the system in order to prove their identity. This is known as logging in. This feature will allow you to <b>display or hide the registration button before logging in</b> and also Protect Your Information. Private Domain Registration masks your information from telemarketers, spammers and bots.',
        DefaultFeatureBehavior: 'By default, OTC comes with its own Register page accessible from the login box or this link: <a class="custom-link" href="https://carrier.nationsbenefits.com" target="_blank">https://carrier.nationsbenefits.com</a> Replace &ldquo;Carrier&rdquo; with your carrier name. For example, <a class="custom-link" href="https://Atena.nationsbenefits.com" target="_blank">https://Atena.nationsbenefits.com</a>. <br><br>For a few carriers, this will NOT be offered to the client/insurance carrier. Only on a request basis. As you may notice, the default OTC registration page is quite generic, and this RegistrationMagic feature powers the registration page with a much- needed makeover. The info icon beside the member id allows you to preview the insurance card number.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    //const AutoLogin = [{
    //    Heading: '1 b i) Allow auto Login',
    //    Purpose: 'When users try to log in to a website with LINE(After registration)Login, they must enter their registered member id/ dob and password into a dialog that is displayed in the browser. However, in certain situations, this process can be skipped when a user is redirected to the login dialog. This is called auto-login.<br><br>This feature will be allowing the users to <b>redirect to the storefront of the portal after successful registration by skipping the login page</b>',
    //    DefaultFeatureBehavior: 'By default, this feature will not be offered for this carrier. Only on a request basis.',
    //    Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
    //    Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
    //    Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
    //    NameOfImagePng1: "Site with Logo",
    //    NameOfImagePng2: "Site without Logo",
    //    NameOfImagePng3: "Site with white Logo"
    //}];

    const CivilRightDisclaimer = [{
        Heading: '2 a. Civil Right Disclaimer',
        Purpose: "A disclaimer should include information about liability issues specific to the content of your website. A disclaimer should warn users of any potential risks of using your site, and state that you are not responsible should those risks occur. The content in a disclaimer will vary depending on your site's activities.<br><br>To <b>show a custom disclaimer in the footer post login</b> to the NB 1.0 portal.",
        DefaultFeatureBehavior: 'By default, this feature will not be offered for this carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/CivilRightsDisclaimer.png",
        NameOfImagePng1: "Civil Right Disclaimer",
    }];

    //const CustomTrademark = [{
    //    Heading: '2 b. CustomTrademark',
    //    Purpose: "A trademark can be any word, phrase, symbol, design, or a combination of these things that identifies your goods or services. It's how customers recognize you in the marketplace and distinguish you from your competitors. To <b>display the customized Trademark in the footer</b> in NB 1.0 portal.",
    //    DefaultFeatureBehavior: 'By default, this feature will not be offered for this carrier. Only on a request basis.',
    //    Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
    //    Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
    //    Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
    //    NameOfImagePng1: "Site with Logo",
    //    NameOfImagePng2: "Site without Logo",
    //    NameOfImagePng3: "Site with white Logo"
    //}];

    const RemoveBBB = [{
        Heading: '2 c. BBB Accreditation',
        Purpose: "If a website has a valid certificate, it means that a certificate authority has taken steps to verify that the web address actually belongs to that organization<br><br>In a market saturated with companies vying for customers, BBB Accreditation gives consumers confidence that they're dealing with an ethical and vetted business. Apply for BBB Accreditation and show customers that your company operates honestly and with integrity.<br><br>Based on this <b>flag system should show/hide the BBB certificate in the footer of the page</b>.",
        DefaultFeatureBehavior: 'By default, this will NOT be offered to the client/insurance carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const MetaBankPrivacyPolicy = [{
        Heading: '2 d. MetaBank Privacy Policy',
        Purpose: "A privacy policy is a document that explains how an organization handles any customer, client or employee information gathered in its operations.<br><br>This feature <b>enables the users to see the MetaBank Privacy Policy on the footer</b> of the NB 1.0/ OTC portal. This particular feature is currently enabled for the carriers Alignment and BCBSRI.",
        DefaultFeatureBehavior: 'By default, this will NOT be offered to the client/insurance carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const SMSNotifications = [{
        Heading: '3 a. SMS Notifications',
        Purpose: "An SMS notification is a short text message that businesses or governmental organizations send to people to inform them about news, updates, emergencies, etc., that are relevant at a given time. This feature enables the user to select the SMS mode as the contact preference in order to receive order updates.The system sends updates regarding an order on the mobile number registered by the user.The contact preference can be set on the Payment page during the checkout process and on the My Profile page.",
        DefaultFeatureBehavior: 'By default, this feature will not be offered for this carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const EmailNotifications = [{
        Heading: '3 b. Email Notifications',
        Purpose: "A notification email relays an important message to a business's users or customers. The content doesn't promote but informs. Like transactional emails, these notifications result from an existing business relationship between the company and the recipient, though customer action may not always be the cue.<br><br>This feature enables the user to select the Email mode as the contact preference in order to receive order updates. The system sends updates regarding an order on the registered Mail ID. The contact preference can be set on the Payment page during the checkout process and on the My Profile page.<br><br>And also it enables the members to set their preference to Email on the Registration page or the Payment page or the My Profile page to receive important communications regarding order updates, password updates, additional account information and promotions via email on their registered Email ID.",
        DefaultFeatureBehavior: 'By default, this feature will not be offered for this carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const SurveyEnabled = [{
        Heading: '3 c. Survey Enabled',
        Purpose: "Website surveys or <b>'Web Intercept Surveys'</b> are a means of gathering both qualitative and quantitative user feedback on your website or products directly from the users. It may include a set of questions that are either open-ended or close-ended or both.<br><br>This feature will <b>enable the survey</b> in the NB 1.0/ OTC portal.<br>A perfect satisfaction survey feature is used after completing the registration & successful order placement.",
        DefaultFeatureBehavior: 'By default, this feature will not be offered for this carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const PdfCatalog = [{
        Heading: '4 a. Product Catalog',
        Purpose: "A catalogue is a list or record of items.<br><br>It is sometimes spelled catalog, which is often the preferred spelling in the U.S.<br><br>It commonly refers to a list of things being offered, such as items for sale or courses at a school.",
        DefaultFeatureBehavior: 'By default, this feature will not be offered for this carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const DownloadCatalog = [{
        Heading: '4 a i). Download Catalog',
        Purpose: "Product catalogs are tremendously useful marketing and sales assets that organize all your product information in one place.<br><br> Since its main objective is to categorize product information, a product catalog is useful to not just buyers but decision-makers, retailers, marketers and sales reps.<br><br>To <b>enables the user to to see the Download Catalog option through of the portal (pre & post login)</b> for the clients/insurance carriers in NB 1.0/ OTC portal.",
        DefaultFeatureBehavior: 'By default, this will NOT be offered to the client/insurance carrier. Only on a request basis.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    const ProductListing = [{
        Heading: '2a. Product Listing',
        Purpose: 'A &ldquo;Product Listing&rdquo; refers to the way a collection of products/items are displayed in a screen. Product Listings act as search/browse tools for users to easily locate, identify and compare products ultimately simplifying the selection process.',
        DefaultFeatureBehavior: 'Members can scroll through the page or use the search and/or filters to quickly narrow down onto a product or a category of products effectively and efficiently.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide3.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide3.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide3.png",
        NameOfImagePng1: "Product Listing",
        NameOfImagePng2: "Product Listing",
        NameOfImagePng3: "Product Listing"
    }];




    const SiteLogin = [{
        Heading: '1 Login',
        Purpose: 'The &ldquo;Login&rdquo; feature is implemented to allow a member to securely access everything from their Personal Information to many Benefit-related features provided by NationsBenefits.',
        DefaultFeatureBehavior: 'Our Login feature works in the most familiar way! When a Member attempts to login, we verify the credentials entered and authenticate their entry.',
        Imagepng1: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng2: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        Imagepng3: "https://nationscdn.azureedge.net/nb-container/OnBoarding/slide2.png",
        NameOfImagePng1: "Site with Logo",
        NameOfImagePng2: "Site without Logo",
        NameOfImagePng3: "Site with white Logo"
    }];

    switch (props.AppKey) {
        case "AuthLogin":
            return <Login constforlogin={AuthLogin} />
        case "LoginNations":
            return <Login constforlogin={LoginNations} />
        case "PreLoginPortalDescription":
            return <Login constforlogin={PreLoginPortalDescription} />
        case "CarrierInfoIcon":
            return <Login constforlogin={CarrierInfoIcon} />
        case "HideClientLogoBeforeLogin":
            return <Login constforlogin={HideClientLogoBeforeLogin} />
        case "ClientCustomBranding":
            return <Login constforlogin={ClientCustomBranding} />
        case "HideActivateCardOptionLogin":
            return <Login constforlogin={HideActivateCardOptionLogin} />
        case "PhoneNumber":
            return <Login constforlogin={PhoneNumber} />
        case "MemberAuthentication":
            return <Login constforlogin={MemberAuthentication} />
        case "DisplayMessageOnLoginTemplate":
            return <Login constforlogin={DisplayMessageOnLoginTemplate} />
        case "PlaceholderText":
            return <Login constforlogin={PlaceholderText} />
        case "MaximumLengthOfMemberID":
            return <Login constforlogin={MaximumLengthOfMemberID} />
        case "LoginInformationHelpText":
            return <Login constforlogin={LoginInformationHelpText} />
        case "CustomizableTextForPreLoginInformationIcon":
            return <Login constforlogin={CustomizableTextForPreLoginInformationIcon} />
        case "CustomizableHeroBannerHeadingForPreLogin":
            return <Login constforlogin={CustomizableHeroBannerHeadingForPreLogin} />
        case "ProviderHelpContact":
            return <Login constforlogin={ProviderHelpContact} />
        case "DisplayFAQ":
            return <Login constforlogin={DisplayFAQ} />
        case "CustomDisclaimerOnDayDelivery":
            return <Login constforlogin={CustomDisclaimerOnDayDelivery} />
        case "UserRegistration":
            return <Login constforlogin={UserRegistration} />
        case "MemberMustBeRegisteredToLogin":
            return <Login constforlogin={MemberMustBeRegisteredToLogin} />
        case "AutoLogin":
            return <Login constforlogin={AutoLogin} />
        case "DisplayTollFreeNumber":
            return <Login constforlogin={DisplayTollFreeNumber} />
        case "HideTheBBBCertificate":
            return <Login constforlogin={HideTheBBBCertificate} />
        case "CustomDisclaimer":
            return <Login constforlogin={CustomDisclaimer} />
        case "CustomTrademark":
            return <Login constforlogin={CustomTrademark} />
        case "CivilRightsDisclaimer":
            return <Login constforlogin={CivilRightsDisclaimer} />

        case "AuthRegister":
            return <Login constforlogin={Register} />
        case "AuthAutoLogin":
            return <Login constforlogin={AutoLogin} />
        case "DisclaimerCivil":
            return <Login constforlogin={CivilRightDisclaimer} />
        case "DisclaimerTradeMark":
            return <Login constforlogin={CustomTrademark} />
        case "DisclaimerAcreditation":
            return <Login constforlogin={RemoveBBB} />
        case "DisclaimerMetaBank":
            return <Login constforlogin={MetaBankPrivacyPolicy} />
        case "NotificationsSMS":
            return <Login constforlogin={SMSNotifications} />
        case "NotificationsEmail":
            return <Login constforlogin={EmailNotifications} />
        case "NotificationsSurvey":
            return <Login constforlogin={SurveyEnabled} />
        case "ProductCatalog":
            return <Login constforlogin={PdfCatalog} />
        case "ProductDownload":
            return <Login constforlogin={DownloadCatalog} />

        //case 112:
        //    return <Login constforlogin={ProductListing} />

        default:
            return <Login constforlogin={AuthLogin} />
    }
}