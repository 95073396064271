/* eslint-disable jsx-a11y/anchor-is-valid */
import Scrollbars from 'rc-scrollbars';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Plus from "../../images/plus-blue.svg";
import Timer from "../../images/timer.svg";
import NoBenefits from "../../images/no-benefits.png";
import Dots from "../../images/dots.svg";
import NoBenefitResults from "../../images/no-benefits.svg";
import BBB from "../../images/BBB.svg";
import { FloatingLabel, Modal } from 'react-bootstrap';
import { GetSheetsWithQuestions, GetCommonBenefitsForInsuranceCarrier, AddBenefitURL, GetBenefitsOnCarrierAndPlanLevel, GetAnswersBasedOnCarrierIdAndBenefitYear, groupNames, DateRelated, GetAnswersBasedOnCarrierIdAndBenefitYearBenefit, ApprovedStatus, SubmittedStatus, questionModalConstants, InsertRule, year, UpdateRule, HA2BenDetails, SaveBenefitConfig, HA2Benefit, DraftStatus, Submit, Approve, EditErrorMessageForHA2, BenefitSourceTemplateId, SaveErrorMessageForHA2, PairPrice, SheetName, ObjectType, DisplayName, Pair, Unit, TemplateName, PricingmanagerURL, HAPMTemplates, RequestFrom, VersionNumber } from '../../Constants/AppConstants';
import axios from 'axios';
import client from "../../utils/ApiClient";
import { SheetWithQuestions } from '../../Interfaces/SheetWithQuestions';
import { BenefitAnswers } from '../../Interfaces/BenefitAnswers';
import AddBenefitModal from './AddBenefitModal';
import OTC from "../../images/store.svg";
import BathroomSafety from "../../images/bathroom-safity.svg";
import PERS from "../../images/pers.svg";
import Meals from "../../images/meals.svg";
import HearingAid from "../../images/hearing.svg";
import Flex from "../../images/flex.svg";
import Activity from "../../images/activity-tracker.svg";
import Rewards from "../../images/reward.svg";
import Transportation from "../../images/transportation.svg";
import Care from "../../images/care.svg";
import Dental from "../../images/dental.svg";
import Vision from "../../images/vision.svg";
import PrimaryTick from "../../images/primary-tick.svg";
import { BenefitAndSheetName, Template } from '../../Interfaces/BenefitAndSheetName';
import { IEditQuestionData, IQuestionsData, ISaveResponse } from '../../Interfaces/ItemRules';
import EditIcon from "../../images/edit.svg";
import QuestionsModal from './Modals/QuestionsModal';
import { IGroupNames } from '../../Interfaces/ReviewStatus';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';
import { AlertPopup, ApprovedIcon, InProgressIcon, getBenefitDate } from '../Common/CommonComponents';
import { useDataContext } from '../../context/DataContext';
import ExternalBlue from "../../images/external-blue.svg";
//import { v4 as uuidv4 } from 'uuid';

// Dictionary type for benefit and sheet names.
type DictionaryEntry = {
    [key: string]: BenefitAndSheetName;
};

// Dictionary type for dictionary.
type BenefitDictionary = { [key: number]: boolean };

// Benefit answers dictionary.
type BenefitAnswersDictionary = { [key: number]: BenefitAnswers[] };

// Benefit config component.
export const BenefitConfig = (props: any) => {  

    // Scrollbar reference variable.
    const scrollbarsRef = useRef<any>(null);

    // State variable to show activity popup.
    const [showAddBenefit, AddBenefit] = useState(false);

    // State variable for common  benefits
    const [commonBenefitsforCarrier, setCommonBenefitsforCarrier] = useState();

    // State variable for setting carrier and plan level benefits.
    const [carrierAndPlanLevelBenefits, setCarrierAndPlanLevelBenefits] = useState<any[]>();

    //List of benefits
    const [listOfBenefits, setListOfBenefits] = useState<any[]>();

    // State variable for benefit answers.
    const [benefitAnswers, setBenefitAnswers] = useState<BenefitAnswersDictionary>();

    //Data Plan Id
    const [dataPlanId, setDataPlanId] = useState<number>(0);
    // State variable for skeleton loader on benefit level.
    const [skeletonLoaderOnBenefitLevel, setSkeletonLoaderOnBenefitLevel] = useState<boolean>(false);

    // State variable for sheet with questions.
    const [sheetWithQuestions, setSheetWithQuestions] = useState();

    const [effectiveStartDate, setEffectiveStartDate] = useState<string>();
    const [effectiveEndDate, setEffectiveEndDate] = useState<string>();


    // State variable for template level data.
    const [templateLevelData, setTemplateLevelData] = useState<SheetWithQuestions[]>();

    // State variable to show activity popup.
    const [showAddCatalog, AddCatalog] = useState(false);

    // State variable for current nav index.
    const [currentNavIndex, setCurrentNavIndex] = useState<number>(0);

    // State variable for current index.
    const [currentBenefitIndex, setCurrentBenefitIndex] = useState<string>();

    // State variable for benefits dictionary.
    const [benefitsDictionary, setBenefitsDictionary] = useState<BenefitDictionary>();

    // State variable for loader.
    const [loader, setLoader] = useState(true);
    //Displays Item RUle PopUp
    const [addItemRule, setAddItemRule] = useState(false);

    const { updateAlertPopup } = useDataContext();

    //list of Item Rules

    //selected group Name 
    const [groupName, setGroupName] = useState<string>('');


    const [isEdit, setIsEdit] = useState(false);

    const [listOfBenefitsHasIC,setlistOfBenefitsHasIC] =useState<string[]>();


    const [finalQuestions, setFinalQuestions] = useState<IQuestionsData[] | null>(null);

    const [editData, setEditData] = useState<IEditQuestionData>();

    const [approvedResponse, setApprovedResponse] = useState<ISaveResponse[]|null>(null);

    //Data Answers
    const [dataAnswers, setDataAnswers] = useState<any>();

    const { instance } = useMsal();
    const accounts = instance.getAllAccounts();
    const userName = accounts[0]?.name;

    // Handles the actvity popup close button/icon.
    const handleActivityClose = () => {
        AddBenefit(false);
    }

    // Handles the add catalog close.
    const handleAddCatalogClose = () => {
        AddCatalog(false);
    }
    const BenefitsIconsURL = process.env.REACT_APP_BENEFITS_ICONS_URL;
    const getQuestionAndAnswers = async () => {
        const requestData = {
            InsuranceCarrierId: props?.activeInsuranceCarrier?.insuranceCarrierId,
            InsuranceHealthPlanName: encodeURIComponent(props?.planDetails?.healthPlanName),
            TemplateSheetBenefitId: props?.templateBenefitSheetId,
            BenefitYear: props?.activeInsuranceCarrier?.benefitYear,
            GroupName: groupName
        };

        try {
            const response = await axios.get(`${GetAnswersBasedOnCarrierIdAndBenefitYearBenefit}`, {
                params: requestData
            });

            if (response.data && response.data.length > 0) {
                let data: IQuestionsData[] = response.data;
                setApprovedResponse(null);
                setFinalQuestions(data);
                effectiveDate();
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        if (groupName)
            getQuestionAndAnswers()
    }, [groupName])

    const effectiveDate = () => {
        let effectiveStartDate = '';
        let effectiveEndDate = '';
        (((sheetWithQuestions && sheetWithQuestions[DateRelated?.benefitDetails]) || (sheetWithQuestions && sheetWithQuestions[HA2BenDetails])) || [])?.map((value: any, index: any) => {
            if (value?.question === DateRelated?.benefitEffectiveDate) {
                const answerValue = benefitAnswers && benefitAnswers[value?.questionId]?.length > 0 ?
                    benefitAnswers[value?.questionId]?.[0]?.answerValue : '';
                effectiveStartDate = answerValue;

            }
            if (value?.question === DateRelated?.benefitExpirationDate) {
                const answerValue = benefitAnswers && benefitAnswers[value?.questionId]?.length > 0 ?
                    benefitAnswers[value?.questionId]?.[0]?.answerValue : '';
                effectiveEndDate = answerValue;
            }

        });
        setEffectiveStartDate(effectiveStartDate);
        setEffectiveEndDate(effectiveEndDate);

    }
    const sortCatalogArray = (qsns: any) => {
        if (qsns?.length > 0) {
            const parentQsns = qsns.filter((v: any) => v?.parentQuestionID === 0);

            let array: any[] = [];

            parentQsns?.map((value: any) => {
                let parentanswerValue = hasAnswer(value);

                if (!!parentanswerValue) {
                    array.push(value);

                    const childArray = qsns.filter(
                        (p: any) => p?.parentQuestionID === value?.questionId && hasAnswer(p)
                    );

                    if (childArray.length > 0) {
                        array = array.concat(childArray);
                    }


                    childArray?.map((c: any) => {
                        let subchild: any = [];

                        subchild = qsns.filter(
                            (k: any) => k?.parentQuestionID === c?.questionId && hasAnswer(k)
                        );

                        if (subchild?.length > 0) {
                            array = array.concat(subchild || []);
                        }
                    });
                }
            });

            return array;
        }
    };



    const hasAnswer = (qsn: any) => {
        const answerValue = benefitAnswers && benefitAnswers[qsn?.questionId]?.length > 0 ?
            benefitAnswers[qsn?.questionId]?.[0]?.answerValue : '';
        if (!!answerValue && answerValue.length > 0) return true;

        else return false;

    }

    // Benefits dictionary.
    const BenefitsDictionary: DictionaryEntry = {
        "HA": { BenefitIcon: HearingAid, SheetName: 'HA' } as BenefitAndSheetName,
        "OTC": { BenefitIcon: OTC, SheetName: 'Supplemental Benefits - Direct' } as BenefitAndSheetName,
        "Meals": { BenefitIcon: Meals, SheetName: 'Meals' } as BenefitAndSheetName,
        "PERs": { BenefitIcon: PERS, SheetName: 'Supplemental Benefits WEX-Incom' } as BenefitAndSheetName,
        "CARE": { BenefitIcon: Care, SheetName: '' } as BenefitAndSheetName,
        "Transportation": { BenefitIcon: Transportation, SheetName: 'Transportation - Direct' } as BenefitAndSheetName,
        "Flex": { BenefitIcon: Flex, SheetName: 'Supplemental Benefits - Flex' } as BenefitAndSheetName,
        "Activity Tracker": { BenefitIcon: Activity, SheetName: 'Supplemental Benefits - Direct' } as BenefitAndSheetName,
        "Bathroom Safety": { BenefitIcon: BathroomSafety, SheetName: 'Supplemental Benefits WEX-Incom' } as BenefitAndSheetName,
        "Rewards": { BenefitIcon: Rewards, SheetName: 'Supplemental Benefits - Flex' } as BenefitAndSheetName,
        "DEN": { BenefitIcon: Dental, SheetName: 'Supplemental Benefits - Flex' } as BenefitAndSheetName,
        "Vision": { BenefitIcon: Vision, SheetName: 'Supplemental Benefits - Flex' } as BenefitAndSheetName,
        "Blood Pressure Monitors": { BenefitIcon: BBB, SheetName: 'Supplemental Benefits - Flex' } as BenefitAndSheetName,
        "Hearing": { BenefitIcon: HearingAid, SheetName: 'Supplemental Benefits - Flex' } as BenefitAndSheetName,
    };

    // Handles the scroll naviagtion on the site configuration.
    const handleScroll = () => {
        if (scrollbarsRef.current) {
            const containerElement = scrollbarsRef.current.view;
            const sectionElements = containerElement.getElementsByClassName("benefit-content-section");
            for (let i = 0; i < sectionElements.length; i++) {
                const sectionElement = sectionElements[i];
                const rect = sectionElement.getBoundingClientRect();
                if (rect.top >= 0 && rect.top < window.innerHeight) {
                    setCurrentNavIndex(i);
                }
            }
        }
    };

    // Checks if the unit price is configured for the level or not.
    const isPairPriceConfigured = (value: any) => {
        let isPairPriceConfigured =false;
        if (value.question.includes(PairPrice) && value.sheetName.toLowerCase() === HA2Benefit.toLowerCase()) {
            const filteredBenefitData: any = benefitAnswers && benefitAnswers[value?.questionId];
            isPairPriceConfigured = filteredBenefitData && filteredBenefitData[0].answerValue !== null && filteredBenefitData[0].answerValue !== '' ? true : false;
        }
        return isPairPriceConfigured;
    }

    // Handles the edit click of the new HA2 sheet in Benefit config.
    const handleEditClick = (questionID: number, property: string, value: any) => {
        if (value === true) {
            // Update benefitAnswers with valid state when value is true
            setBenefitAnswers(prevAnswers => {
                const updatedAnswers = { ...prevAnswers };
                if (updatedAnswers[questionID]) {
                    updatedAnswers[questionID] = updatedAnswers[questionID].map(answer => ({
                        ...answer,
                        [property]: value,
                    }));
                }
                return updatedAnswers;
            });
            return;
        }

        // Process when value is not true
        const filteredBenefitData:any = benefitAnswers && benefitAnswers[questionID];

        const question = filteredBenefitData[0]?.question.replace(Pair, Unit);

        const unitPriceData :any = (sheetWithQuestions && sheetWithQuestions[filteredBenefitData?.[0]?.groupName] || [])
            .find((x: any) => x.question.toLowerCase() === question?.toLowerCase());

        let unitPriceQuestion: any = benefitAnswers && benefitAnswers[unitPriceData?.questionId];
        let isValid = true;
        if (unitPriceQuestion) {
            const trimmedValue = value.trim();
            if (value.includes(' ') || trimmedValue !== value || !value) {
                isValid = false;
            } else {
                const numericValue = Number(trimmedValue);
                if (isNaN(numericValue) || !Number.isInteger(numericValue)) {
                    isValid = false;
                }
            }
        }

        effectiveDate();

        // Update benefitAnswers with the new value and validation state
        setBenefitAnswers(prevAnswers => {
            const updatedAnswers = { ...prevAnswers };
            if (updatedAnswers[questionID]) {
                updatedAnswers[questionID] = updatedAnswers[questionID].map(answer => ({
                    ...answer,
                    [property]: value,
                    isValidAnswer: isValid
                }));
            }
            return updatedAnswers;
        });
    };

    const returnSelectedQuestion = (questionId:number) => {
        return benefitAnswers && benefitAnswers[questionId]?.length > 0 &&
            benefitAnswers[questionId]
    }

     //Function to transform the benefit answers
    const transformBenefitAnswers = () => {
        if (benefitAnswers) { // Ensure benefitAnswers is defined
            const transformedArray = Object.keys(benefitAnswers).flatMap(key =>
                benefitAnswers[parseInt(key, 10)].map(answer => ({
                    DataPlanID: 0,
                    QuestionID: answer.questionID,
                    AnswerValue: answer.answerValue ?? '',
                    IsActive: true,
                    CreateUser: userName,
                    CreateDate: new Date().toISOString(),
                    ModifyUser: userName,
                    ModifyDate: new Date().toISOString(),
                    VersionNumber: VersionNumber,
                }))
            );

            // Update state with transformed answers
            return transformedArray;
        }
    };

    // Handles the benefitconfig save or update for the HA2 benefit.
    const handleOnBlur = (questionId: number) => {
        const selectedQuestion: any = benefitAnswers?.[questionId]?.[0];

        if (!selectedQuestion) return;

        const { isValidAnswer, versionStatus } = selectedQuestion;

        if (isValidAnswer) {
            const status = versionStatus?.toLowerCase();
            status === ApprovedStatus.toLowerCase() ? handleBenefitSave(questionId) : handleUpdate(questionId);
        }
    };

    // Handles the benefitconfig update for the HA2 benefit.
    const handleUpdate = (questionId: number) => {
        const selectedData: any = benefitAnswers && benefitAnswers[questionId]?.length > 0 &&
            benefitAnswers[questionId]?.[0];
        let updateData = {
            dataPlanID: selectedData?.dataPlanID,
            questionID: selectedData.questionID,
            answerValue: selectedData.answerValue,
            isActive: true,
            createUser: userName,
            createDate: getBenefitDate(),
            modifyUser: userName,
            modifyDate: getBenefitDate(),
            versionNumber: VersionNumber,
            dataAnswerID: selectedData.dataAnswerID
        }
        client.post(UpdateRule, [updateData])
            .then((response) => {
                if (response && response.status === 200) {
                    updateAlertPopup(true);
                    if (selectedData.versionStatus.toLowerCase() === SubmittedStatus.toLowerCase())
                        props.changeStatusToDraft(DraftStatus, props?.templateBenefitSheetId.toString(), props.activeInsuranceCarrier?.benefitYear);
                }
            })
            .catch((error) => {
                console.error(EditErrorMessageForHA2, error)
            })
            .finally(() => {
                getBenefitAnswersAtCarrierAndPlanLevel(props?.templateBenefitSheetId);
            })
    }

    // Handles the benefitconfig save for the HA2 benefit.
    const handleBenefitSave = (questionId:number) => {
        const effectiveFromDate = props.activeInsuranceCarrier?.benefitYear === year ? moment(new Date(), 'MM/DD/YYYY').format('YYYY-MM-DD')
            : moment(effectiveStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
        let saveData = {
            InsuranceCarrierID: props.activeInsuranceCarrier?.insuranceCarrierId,
            InsuranceHealthPlanID: props.planDetails?.insuranceHealthPlanID,
            TemplateSheetBenefitID: props?.templateBenefitSheetId,
            EffectiveFrom: effectiveFromDate,
            EffectiveTo: moment(effectiveEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            CreateUser: userName,
            BenefitYear: props.activeInsuranceCarrier?.benefitYear,
            SourceTemplateId: BenefitSourceTemplateId,
            DataAnswersTypes: transformBenefitAnswers()
        }
        client.post(InsertRule, saveData)
            .then((response) => {
                if (response && response.status === 200 && response.data === 1)
                    updateAlertPopup(true);
                else if (response.data === 0) {
                    getBenefitAnswersAtCarrierAndPlanLevel(props?.templateBenefitSheetId);
                    handleUpdate(questionId);
                }
            })
            .catch((error) => {
                console.error(SaveErrorMessageForHA2, error)
            })
            .finally(() => {
                getBenefitAnswersAtCarrierAndPlanLevel(props?.templateBenefitSheetId);
            })
    }

    // Questions with grouped data.
    const setSheetWithQuestionsGroupedData = (index: string, sheetName: string, benefitName: string, templateData: any) => {
        setCurrentBenefitIndex(index);
        const groupedData = templateData && templateData?.filter((f: any) => f.sheetName === sheetName && f.benefitName === benefitName)
            .reduce((groups: any, item: any) => {
                const key = item.groupName;
                if (key != null && key !== '') {
                    if (!groups[key]) {
                        groups[key] = [];
                    }
                    groups[key].push(item);
                }

                return groups;

            }, {});
        if (sheetName.toLowerCase() === HA2Benefit.toLowerCase()) {
            if (groupedData && typeof groupedData === 'object') {
                const sortedKeys = Object.keys(groupedData).sort();
                const sortedGroupedData: any = {};
                sortedKeys.forEach((key) => {
                    sortedGroupedData[key] = groupedData[key].sort((a: any, b: any) => a.questionId - b.questionId);
                });
                setSheetWithQuestions(sortedGroupedData);
            }
        } else {
            setSheetWithQuestions(groupedData);
        }
        const groups = Object.keys(groupedData || []);
        const itemToMove = groupNames.catalogRule;

        const indexToInsertAfter = groups.indexOf(groupNames.catalog);

        if (indexToInsertAfter !== -1) {
            const indexToMove = groups.indexOf(itemToMove);

            if (indexToMove !== -1) {
                // Remove "Item Rules" from its current position
                groups.splice(indexToMove, 1);

                // Insert "Item Rules" after "Catalog"
                groups.splice(indexToInsertAfter, 0, itemToMove);
            }
        }
        const itemRuleToMove = groupNames.itemRule;
        const indexToInsertAfterItem = groups.indexOf(groupNames.catalogRule);
        if (indexToInsertAfterItem !== -1) {
            const indexRuleToMove = groups.indexOf(itemRuleToMove);
            if (indexRuleToMove !== -1) {
                groups.splice(indexRuleToMove, 1);
                groups.splice(indexToInsertAfterItem, 0, itemRuleToMove);
            }
        }
        let data: IGroupNames[] = []
        groups.forEach(element => {
            data.push({
                groupName: element,
                isVerified: (element === groupNames.itemRule || element === groupNames.catalogRule) ? false : true
            })

        });
        if (sheetName.toLowerCase() === HA2Benefit.toLowerCase()) {
            data = data.sort((a: IGroupNames, b: IGroupNames) => a.groupName.localeCompare(b.groupName));
        }
        props.setNavArray(data);
    }

    // Adds the benefits at plan level.
    const AddBenefitsAtPlanLevel = () => {
        const addBenefitRequest = {
            insuranceCarrierId: props.insuranceCarrier?.insuranceCarrierId,
            insuranceHealthPlanName: encodeURIComponent(props.planDetails?.healthPlanName),
            templateSheetBenefitID: Object.keys(benefitsDictionary || [])
        }
        client.post(AddBenefitURL, addBenefitRequest)
            .then(() => {
                handleActivityClose();
                fetchSheetQuestionsAndBenefits();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    // Gets the answers at the carrier and plan level.
    const getBenefitAnswersAtCarrierAndPlanLevel = (templateSheetBenefitId: number) => {
        const requestData = {
            InsuranceCarrierId: props.activeInsuranceCarrier?.insuranceCarrierId,
            InsuranceHealthPlanName: encodeURIComponent(props.planDetails?.healthPlanName),
            TemplateSheetBenefitId: templateSheetBenefitId,
            BenefitYear: props.activeInsuranceCarrier?.benefitYear
        };
        axios.get(GetAnswersBasedOnCarrierIdAndBenefitYear, {
            params: requestData
        }).then((response: any) => {
            if (response.data && response.data?.length > 0) {
                setDataAnswers(response.data);
                // Adding isEditEnabled to each answer
                const enrichedData = response.data.map((item: any) => ({
                    ...item,
                    isEditEnabled: false,
                    isValidAnswer:true
                }));
                const groupedAnswers = enrichedData.reduce((groups: any, item: any) => {
                    const key = item.questionID;
                    if (key != null) {
                        if (!groups[key]) {
                            groups[key] = [];
                        }
                        groups[key].push(item);
                    }
                    return groups;
                }, {});
                // Sets the benefit answers.
                setBenefitAnswers(groupedAnswers);
            }
        }).
            catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (benefitAnswers) {
            let isItemRule: boolean = checkWhetherQuestionsAnsweredOrNot(groupNames.itemRule);
            let catalogRule: boolean = checkWhetherQuestionsAnsweredOrNot(groupNames.catalogRule);
            props.setNavArray((nav: IGroupNames[]) => {
                if (nav?.length > 0) {
                    //let mergedChildIds = [groupNames.itemRule,groupNames.catalogRule];
                    nav = nav.map(response => {
                        if (response.groupName === groupNames.itemRule) {
                            return { ...response, isVerified: isItemRule };
                        }
                        else if (response.groupName === groupNames.catalogRule) {
                            return { ...response, isVerified: catalogRule };
                        }
                        return response;
                    });
                    return nav;
                }
            })
        }
    }, [benefitAnswers])

    // State variable to hold all the active templates.
    const [templates, setTemplates] = useState<Template[]>([]); 

    // State variable to hold the templateid associate with the benefit.
    const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);

    useEffect(() => {    
        const fetchTemplates = async () => {
            const today = new Date();
            const effectiveDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;

            try {
                const response = await fetch(
                    `${HAPMTemplates}${encodeURIComponent(effectiveDate)}`
                );
                if (response.status === 200) {
                    const result = await response.json();
                    setTemplates(result);
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
            } 
        };
        fetchTemplates();
    }, []);

  

    // Finds the matching element related to HA2
    const findFirstMatchingElement = (data: any) => {
        if (data && typeof data === ObjectType) {
            for (const key of Object.keys(data)) {
                const value = data[key];
                if (Array.isArray(value) && value.length > 0) {
                    if (SheetName in value[0]) {
                        return value[0]; 
                    }
                }
            }
        }
        return undefined;
    };

    useEffect(() => {
        if( listOfBenefits && listOfBenefitsHasIC && props?.templateBenefitSheetId){

            let selectedBenefitForApproval:boolean =  listOfBenefitsHasIC?.includes(listOfBenefits?.filter((e:any)=>{
               return e.templateSheetBenefitId === props?.templateBenefitSheetId
            })[0].benefitName?.toLowerCase())?true:false;

           if(selectedBenefitForApproval){
            let isVerified:boolean =listOfBenefits.filter((e:any)=>{
                return  listOfBenefitsHasIC?.includes(e?.benefitName?.toLowerCase());
            })?.every((e:any)=>{
                return  e.versionStatus?.toLowerCase() === props.getBtnTitle()?.toLowerCase()
             });
            props?.setIsBenefitSubmitted(isVerified);
            }
           else{
            props?.setIsBenefitSubmitted(true);
        }

       }
        if (dataAnswers) {
            if (sheetWithQuestions && sheetWithQuestions[HA2BenDetails] && Array.isArray(sheetWithQuestions[HA2BenDetails])) {
                const selectedSheet = findFirstMatchingElement(sheetWithQuestions);
                if (selectedSheet && selectedSheet.sheetName.toLowerCase() === HA2Benefit.toLowerCase()) {
                    const isDraft = dataAnswers[0].dataPlanID && dataAnswers[0].dataPlanID !== 0 && dataAnswers[0].versionStatus.toLowerCase() === DraftStatus.toLowerCase();
                    const isSubmit = dataAnswers[0].dataPlanID && dataAnswers[0].dataPlanID !== 0 && dataAnswers[0].versionStatus.toLowerCase() === SubmittedStatus.toLowerCase();

                    if (isDraft && props.btnTitle.toLowerCase() === Submit.toLowerCase()) {
                        props.setIsBenefitSubmitted(false);
                    } else if ((isDraft || isSubmit) && props.btnTitle.toLowerCase() === Approve.toLowerCase()) {
                        props.setIsBenefitSubmitted(false);
                    }
                }
            }
            else if (dataAnswers.filter((e: any) => e.sourceTemplateID === questionModalConstants.sourceTemplateId && e.dataAnswerID > 0).length === 0) {
                props.setIsBenefitSubmitted(true);
            }
        }
    }, [listOfBenefits,props?.templateBenefitSheetId,listOfBenefitsHasIC,dataAnswers])

    const checkWhetherQuestionsAnsweredOrNot = (groupName: string) => {
        let isVerified = true;
        ((sheetWithQuestions && sheetWithQuestions[groupName] || []))?.filter((value: any, index: any) => {
            return value?.parentQuestionID === 0;
        }).map((value: any, index: any) => {
            if (value?.isMandate) {
                const answerValue = benefitAnswers && benefitAnswers[value?.questionId]?.length > 0 ?
                    benefitAnswers[value?.questionId]?.[0]?.answerValue : '';
                if (!!!answerValue)
                    isVerified = false;
            }
        });
        return isVerified;
    }

    // Sets the current benefit and questions related to it.
    const setCurrentBenefitAndQuestion = (index: string, benefit: any) => {
        props?.setTemplateBenefitSheetId(benefit?.templateSheetBenefitId);
        setSkeletonLoaderOnBenefitLevel(true);
        setTimeout(() => {
            setCurrentNavIndex(0);
            setSheetWithQuestionsGroupedData(index, benefit?.sheetName, benefit?.benefitName, templateLevelData);
            getBenefitAnswersAtCarrierAndPlanLevel(benefit?.templateSheetBenefitId);
            setSkeletonLoaderOnBenefitLevel(false);
        }, 1000)
    }

    // Adds the benefits to the collection.
    const addBenefitToCollection = (benefit: any) => {
        const benefitTemplateId = benefit?.templateSheetBenefitId
        const updatedBenefitsDictionary = { ...benefitsDictionary };
        if (updatedBenefitsDictionary.hasOwnProperty(benefitTemplateId)) {
            delete updatedBenefitsDictionary[benefitTemplateId];
        } else {
            updatedBenefitsDictionary[benefitTemplateId] = true;
        }
        setBenefitsDictionary(updatedBenefitsDictionary);
    }

    // State variable to hold the templatename associated with the benefit.
    const [templateName, settemplateName] = useState(null);

    // Render the question content.
    const renderQuestionContent = (value: any, index: any, answerValue: any) => {
        const isDisplayNameQuestion = value.question === TemplateName && value.sheetName.toLowerCase() === HA2Benefit.toLowerCase();
        if (isDisplayNameQuestion && answerValue?.length > 0 && templateName !== "" && templateName === null) {
            settemplateName(answerValue);
            redirectToPricingManager(answerValue);
        }
        const effectiveDate = getBenefitDate(props.activeInsuranceCarrier?.benefitYear);
        return value?.questionType?.toString()?.toLowerCase() === "dropdown" ? (
            <div className="question-content">
                {value?.options?.toString().length > 0 ?
                    value?.options?.toString()?.split(',')?.map((value: any, index: any) => (
                        <Button key={index} className={`btn-outline ${answerValue?.length > 0 && answerValue?.toString()?.toLowerCase() == value?.toString()?.trim()?.toLowerCase() ? 'active' : 'disabled'}`}>{value}</Button>
                    )) :
                    <Row>
                        <Col xs={7} xxl={6}>
                            <FloatingLabel label={value?.question}>
                                <Form.Control type="text" placeholder=" " readOnly value={answerValue} />
                            </FloatingLabel>
                            <div>
                                {isDisplayNameQuestion && selectedTemplateId ? (
                                    <div className='question-comment opacity-100'>
                                        {(() => {                                         
                                          
                                            const url = `${PricingmanagerURL}/external/viewitems?requestFrom=${RequestFrom}&templateId=${encodeURIComponent(selectedTemplateId)}&carrierId=${props.activeInsuranceCarrier?.insuranceCarrierId}&healthPlanId=${props.planDetails?.insuranceHealthPlanID}&EffectiveDate=${encodeURIComponent(effectiveDate)}`;
                                            return (
                                                <a href={url} target="_blank" rel="noopener noreferrer" className="custom-link"><img className="img-fluid me-2" src={ExternalBlue} alt=" " />Preview Items</a>
                                            );
                                        })()}
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    </Row>}
            </div>
        ) : <></>;
    }
    // It will redirects to the pricing manager
    const redirectToPricingManager = (templateName: any) => {  
        const foundTemplate = templates?.find(
            (template) => template?.templateName === templateName
        );
        if (foundTemplate) {
            setSelectedTemplateId(foundTemplate?.templateId);
        } else {
            console.log(`Template with name "${templateName}" not found.`);
        }
    
    }
    // Fetches the sheet questions and benefits.
    const fetchSheetQuestionsAndBenefits = async () => {
        setLoader(true);
        try {
            const insuranceCarrierId = encodeURIComponent(props.activeInsuranceCarrier?.insuranceCarrierId);
            const healthPlanName = encodeURIComponent(props.planDetails?.healthPlanName);
            const benefitYear = encodeURIComponent(props.activeInsuranceCarrier?.benefitYear);
            const getSheetsUrl = `${GetSheetsWithQuestions}/1`;
            const getCarrierAndPlanLevelBenefitsUrl = `${GetBenefitsOnCarrierAndPlanLevel}${insuranceCarrierId}&healthPlanName=${healthPlanName}&benefitYear=${benefitYear}`;
            const getCommonBenefitsUrl = `${GetCommonBenefitsForInsuranceCarrier}`;

            const [sheetsResponse, commonBenefitsResponse, carrierAndBenefitLevelBenefits] = await Promise.all([
                client.get(getSheetsUrl),
                client.get(getCommonBenefitsUrl),
                client.get(getCarrierAndPlanLevelBenefitsUrl)
            ]);
            setListOfBenefits(carrierAndBenefitLevelBenefits.data);

            // Your data
            const listOfQuestions: any[] = sheetsResponse.data;

            // Step 1: Group questions by BenefitName
            const groupedByBenefit: { [key: string]: any[] } = listOfQuestions.reduce((acc, question) => {
             const {  benefitName, groupName } = question;

                // Initialize an array for the BenefitName if it doesn't exist
                if (!acc[benefitName]) {
                    acc[benefitName] = [];
                }

                // Push the question into the array
                acc[benefitName].push(question);

                return acc;
            }, {} as { [key: string]: SheetWithQuestions[] }); // Use type assertion here

            // Step 2: Filter questions where GroupName is either "Catalog Detail" or "ItemRules"
            const filteredBenefits: string[] = [];
            for (const benefitName in groupedByBenefit) {
                const questions = groupedByBenefit[benefitName];
                const hasCatalogDetailOrItemRules = questions.some((question) => question.groupName?.toLowerCase() === groupNames.catalogRule?.toLowerCase() || question.groupName?.toLowerCase() === groupNames.itemRule?.toLowerCase());

                if (hasCatalogDetailOrItemRules) {
                    filteredBenefits.push(benefitName?.toLowerCase());
                }
            }
            setlistOfBenefitsHasIC(filteredBenefits);
            //-------

            // Sets the sheet level questions and answers.
            setTemplateLevelData(sheetsResponse.data);




            // Groups the data at the specific carrier and benefit level.
            if (carrierAndBenefitLevelBenefits && carrierAndBenefitLevelBenefits?.data?.length > 0) {
                const groupedDataForCarrierAndBenefitLevel = carrierAndBenefitLevelBenefits.data.reduce((groups: any, item: any) => {
                    const key = item.categoryType;
                    if (key != null) {
                        if (!groups[key]) {
                            groups[key] = [];
                        }
                        groups[key].push(item);
                    }
                    return groups;
                }, {});

                // Sets the sheet questions based on the benefits level.
                const sheetWithQuestionsRequest = groupedDataForCarrierAndBenefitLevel[Object.keys(groupedDataForCarrierAndBenefitLevel || [])[0]][0];
                setSheetWithQuestionsGroupedData(0 + sheetWithQuestionsRequest?.categoryType, sheetWithQuestionsRequest?.sheetName, sheetWithQuestionsRequest?.benefitName, sheetsResponse.data);
                setCarrierAndPlanLevelBenefits(groupedDataForCarrierAndBenefitLevel);
                props?.setTemplateBenefitSheetId(sheetWithQuestionsRequest?.templateSheetBenefitId);
                // Gets the benefit answers for the template identifier.
                getBenefitAnswersAtCarrierAndPlanLevel(sheetWithQuestionsRequest?.templateSheetBenefitId);
            }

            // Filtered common benefits.
            const filteredCommonBenefits = carrierAndBenefitLevelBenefits && carrierAndBenefitLevelBenefits?.data?.length > 0 ?
                commonBenefitsResponse.data.filter((cbr: any) =>
                    !(carrierAndBenefitLevelBenefits?.data).some((cpb: any) => cpb.templateSheetBenefitId === cbr.templateSheetBenefitId)) :
                commonBenefitsResponse?.data;

            // Group the data for benefits at overall carrier level.
            const groupedData = filteredCommonBenefits && filteredCommonBenefits?.length > 0 &&
                filteredCommonBenefits.reduce((groups: any, item: any) => {
                    const key = item.categoryType;
                    if (key != null) {
                        if (!groups[key]) {
                            groups[key] = [];
                        }
                        groups[key].push(item);
                    }
                    return groups;
                }, {});

            setCommonBenefitsforCarrier(groupedData);

            setLoader(false);
        }
        catch (error) {
            setLoader(false);
            console.log(error);
        }
    }

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        fetchSheetQuestionsAndBenefits();
    }, [props.refreshSiteConfigurations]);

    // EditItemRule_ParentAndChildData_Questions_And_Answers 
    const EditItemRuleParentQuestions = (parentQuestionID: number, key: string) => {

        let questionsData = (sheetWithQuestions && sheetWithQuestions[key]);
        let requiredData: { questionID: number; answerValue: string, dataAnswerId: number }[] = [];
        let editArray = (questionsData || [])?.map((value: any, index: any) => {
            let y = {
                questionID: 0,
                answerValue: '',
                dataAnswerId: 0

            }
            if (value?.questionId === parentQuestionID || value?.parentQuestionID === parentQuestionID) {

                const answerValue: any = benefitAnswers && benefitAnswers[value?.questionId]?.length > 0 &&
                    benefitAnswers[value?.questionId]?.[0]?.answerValue?.length > 0 && benefitAnswers[value?.questionId]?.[0];

                if (!!answerValue?.answerValue) {
                    y.questionID = value?.questionId;
                    y.answerValue = answerValue?.answerValue
                    y.dataAnswerId = answerValue?.dataAnswerID;
                    if (value?.questionId !== parentQuestionID) {

                        let subCHildQuestions = (questionsData || [])?.filter((subChild: any, index: any) => {
                            return subChild.parentQuestionID === value?.questionId;
                        })
                        if (subCHildQuestions.length > 0) {
                            subCHildQuestions.map((e: any, index: any) => {
                                const childanswerValue: any = benefitAnswers && benefitAnswers[e?.questionId]?.length > 0 &&
                                    benefitAnswers[e?.questionId]?.[0]?.answerValue?.length > 0 && benefitAnswers[e?.questionId]?.[0];
                                requiredData.push({
                                    questionID: e?.questionId,
                                    answerValue: childanswerValue?.answerValue,
                                    dataAnswerId: childanswerValue?.dataAnswerID,

                                })
                            })
                        }
                    }

                    requiredData.push(y);
                }

            }
        })

        setIsEdit(true);
        setEditData({ parentQuestionID: parentQuestionID, editData: requiredData });
        setAddItemRule(true);
        setGroupName(key)


    }
    const [itemOrCatalogData, setItemOrCatalogData] = useState<ISaveResponse[]|null>(null);
    const getCatalogOrItemDetails =(key:string)=>{
        let questionsData = (sheetWithQuestions && sheetWithQuestions[key]);
        let allquestions:ISaveResponse[] =[];

        (questionsData || [])?.map((value: any, index: any) => {
            const answerValue = benefitAnswers && benefitAnswers[value?.questionId]?.length > 0 ?
                benefitAnswers[value?.questionId]?.[0]?.answerValue : '';
            let requiredData:ISaveResponse;
            if(!!answerValue){
                requiredData = {
                    dataAnswerID:0,
                    dataPlanID:0,
                    questionID: value?.questionId,
                    answerValue: answerValue,
                    isActive: true,
                    createUser: userName,
                    createDate: getBenefitDate(),
                    modifyUser: userName,
                    modifyDate: getBenefitDate(),
                    versionNumber: "VS1",

                };
                allquestions.push(requiredData);

            }

        });
        setItemOrCatalogData(allquestions);

    }
    return (
        <>
            <AlertPopup message={SaveBenefitConfig} />

            <div className="benefit-config main-content">
                {loader ?
                    <Row className="loading-skeleton">
                        <Col xs={3}>
                            <h5 className="mb-1">Benefits</h5>
                            <a className="custom-link add-benefit text-decoration-none mb-4"></a>
                            <h5 className="mb-1">Flex Benefits</h5>
                            <ul className="benefit-details">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </Col>
                        <Col xs={9}>
                            <div className="configuration-page">
                                <div className="benefit-section hide-scroll">
                                    <div className="benefit-title-section">
                                        <h4>HA Benefit Details</h4>                                       
                                    </div>
                                    <div className="benefit-content-section">
                                        {
                                            Array.from({ length: 10 }).map((value, index) => (
                                                <div className="question" key={index}>
                                                    <h6>Benefit Effective Date</h6>
                                                    <Row>
                                                        <Col xs={5}>
                                                            <Form.Control type="text" placeholder=" " />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}                                  
                                    </div>
                                </div>
                                <div className="sticky-nav hide-scroll">
                                    <ul>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    :
                    (Object.keys(carrierAndPlanLevelBenefits || []).length > 0 ?
                        (<Row>
                            <Col xl={3}>
                                <h5>Benefits</h5>
                                <a className='custom-link add-benefit text-decoration-none mb-4 d-none' onClick={() => { AddBenefit(true) }}>
                                    <img className="img-fluid me-2" src={Plus} alt=" " />
                                    <span>Add New Benefit</span>
                                </a>
                                <a className='custom-link add-benefit text-decoration-none mb-4 d-none' onClick={() => { AddCatalog(true) }}>
                                    <img className="img-fluid me-2" src={Plus} alt=" " />
                                    <span>Add New Catalog</span>
                                </a>
                                <div className="benefits">
                                    <Scrollbars className="benefits-list"
                                        autoHide
                                        autoHideTimeout={100}
                                        autoHideDuration={200}
                                        thumbMinSize={80}
                                        autoHeight
                                        autoHeightMin={0}
                                        autoHeightMax={565}
                                    >
                                        {Object.keys(carrierAndPlanLevelBenefits || [])?.map((key: any, index: number) => (
                                            <>
                                                <h5>{key}</h5>
                                                <ul className="benefit-details">
                                                    {((carrierAndPlanLevelBenefits && carrierAndPlanLevelBenefits[key]) || [])?.map((x: any, index: any) => (
                                                        <li key={x?.benefitType}>
                                                            <a className={`${index + key === currentBenefitIndex ? 'active' : ''}`} onClick={() => setCurrentBenefitAndQuestion(index + key, x)}>
                                                                <span className="benefits-inner-list">
                                                                {listOfBenefitsHasIC?.includes(x?.benefitName?.toLowerCase()) && x?.versionStatus?.toLowerCase() === ApprovedStatus?.toLowerCase() &&<ApprovedIcon/>}
                                                                {listOfBenefitsHasIC?.includes(x?.benefitName?.toLowerCase()) && x?.versionStatus?.toLowerCase() === SubmittedStatus?.toLowerCase() &&<InProgressIcon/>}
                                                                    <span className="benefit-icon">
                                                                        {x.imageNames !== undefined ? (
                                                                            <img className="img-fluid" src={BenefitsIconsURL + x?.imageNames + ".svg"} alt=" " />
                                                                        ) : (
                                                                            <img className="img-fluid" src="" alt=" " />
                                                                        )}
                                                                    </span>

                                                                    <span className="text-truncate" title={x?.benefitName ? x?.benefitName : x?.benefitType } style={{ maxWidth: '200px' }} >{x?.benefitName ? x?.benefitName : x?.benefitType}</span>
                                                                </span>

                                                                <span className="benefit-pride d-none">
                                                                    <span className="price-options text-truncate">$100 | Quarterly</span>
                                                                    <img className="img-fluid benefitaction" src={Dots} alt=" " />
                                                                </span>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ))}                                        
                                    </Scrollbars>
                                </div>
                                <div className="no-results d-none">
                                    <img className="img-fluid mb-3" src={NoBenefits} alt=" " />
                                    <h6>No benefits added</h6>
                                    <p>Please add the benefits to configure</p>
                                </div>
                            </Col>
                            <Col xl={9} className={skeletonLoaderOnBenefitLevel ? `loading-skeleton` : ''}>
                                <div className="configuration-page">                                 
                                    <div className="benefit-section hide-scroll">
                                        <Scrollbars
                                            autoHide
                                            autoHideTimeout={100}
                                            autoHideDuration={200}
                                            thumbMinSize={80}
                                            ref={scrollbarsRef}
                                            onScroll={handleScroll}
                                        >
                                            {Object.keys(sheetWithQuestions || [])?.map((key: any, index: number) => {
                                                if (key === groupNames.itemRule || key === groupNames.catalogRule)
                                                    return
                                                return (<div key={index} id={`section-${key}`}>
                                                    <div className={` ${key === groupNames.itemRule ? "d-none" : "benefit-title-section position-sticky"}`} >
                                                        <h4>{key}</h4>
                                                        <div className="benefit-title-right">
                                                        </div>
                                                    </div>
                                                    <div className="benefit-content-section">
                                                        {((sheetWithQuestions && sheetWithQuestions[key]) || [])?.map((value: any, index: any) => {
                                                            const selectedData:any = returnSelectedQuestion(value?.questionId);
                                                            const answerValue = selectedData ? selectedData?.[0]?.answerValue : '';
                                                            const isEditIcon = isPairPriceConfigured(value);
                                                            const isEnableEdit = selectedData && selectedData.length > 0 ? selectedData[0]?.isEditEnabled ?? false : false;
                                                            const isAnswerValid = selectedData && selectedData.length > 0 ? selectedData[0]?.isValidAnswer ?? true : false;
                                                            return (<div className='question-main'><div className="question" key={index}>
                                                                <h6>{value.question}</h6>
                                                                {value?.questionType?.toString()?.toLowerCase() === "numeric" || value?.questionType?.toString()?.toLowerCase() === 'text' ||
                                                                    value?.questionType?.toString()?.toLowerCase() === "textbox" ||
                                                                    value?.questionType?.toString()?.toLowerCase() === '' || value?.questionType?.toString()?.toLowerCase() === "upload"
                                                                    || value?.questionType?.toString()?.toLowerCase() === "" ?
                                                                    <Row>
                                                                        <Col xs={7} xxl={6}>
                                                                            <FloatingLabel label="">
                                                                                <Form.Control type="text" disabled={!isEnableEdit}
                                                                                    style={!isAnswerValid && isEnableEdit  ? { borderColor: '#dc3545' } : {}}
                                                                                    onChange={(e: any) => isEnableEdit && handleEditClick(value.questionId, 'answerValue', e.target.value)}
                                                                                    placeholder=" " value={answerValue}
                                                                                    onBlur={() => {
                                                                                        if (isEnableEdit) {
                                                                                            handleOnBlur(value.questionId);
                                                                                        }
                                                                                    }} />
                                                                                {!isAnswerValid && isEnableEdit ? (
                                                                                    <span className="error-message">{selectedData && selectedData[0]?.errorMessage}</span>
                                                                                ) : null}
                                                                            </FloatingLabel>
                                                                            {(isEditIcon || (!isEditIcon && !isAnswerValid) && isEnableEdit) ?
                                                                                <div className='question-comment opacity-100'>
                                                                                    <img
                                                                                        className="img-fluid cursor-pointer"
                                                                                        src={EditIcon}
                                                                                        alt=""
                                                                                        onClick={() => {
                                                                                            handleEditClick(value.questionId, 'isEditEnabled', true);
                                                                                        }}
                                                                                    />
                                                                                </div> : <></>}
                                                                        </Col>
                                                                    </Row>
                                                                    : renderQuestionContent(value, index, answerValue)
                                                                }
                                                                <span className="question-number">{value.sheetColumn}</span>
                                                                <a className="question-comment" href=" ">
                                                                </a>
                                                            </div></div>
                                                            );
                                                            //}
                                                        })}
                                                    </div>

                                                    {key?.toLowerCase() === groupNames.catalog?.toLowerCase() &&
                                                        <div className="benefit-title-section position-sticky" id={`section-${groupNames?.catalogRule}`} >
                                                            <h4>{groupNames?.catalogRule}</h4>
                                                        </div>
                                                    }
                                                    {
                                                        key?.toLowerCase() === groupNames.catalog?.toLowerCase() &&
                                                        <div className="benefit-content-section">
                                                            {((sheetWithQuestions && sortCatalogArray(sheetWithQuestions && sheetWithQuestions[groupNames?.catalogRule])) || [])?.map((value: any, index: any) => {
                                                                const answerValue = benefitAnswers && benefitAnswers[value?.questionId]?.length > 0 ?
                                                                    benefitAnswers[value?.questionId]?.[0]?.answerValue : '';
                                                                if (!!answerValue) {
                                                                    return (<div className="question-main"><div className="question" key={index}>
                                                                        <h6>{value.question}</h6>
                                                                        {value?.questionType?.toString()?.toLowerCase() === "numeric" || value?.questionType?.toString()?.toLowerCase() === 'text' ||
                                                                            value?.questionType?.toString()?.toLowerCase() === "textbox" ||
                                                                            value?.questionType?.toString()?.toLowerCase() === '' || value?.questionType?.toString()?.toLowerCase() === "upload"
                                                                            || value?.questionType?.toString()?.toLowerCase() === "" ?
                                                                            <Row>
                                                                                <Col xs={7} xxl={6}>
                                                                                    <FloatingLabel label="">
                                                                                        <Form.Control type="text" readOnly placeholder=" " value={answerValue} />
                                                                                    </FloatingLabel>
                                                                                </Col>
                                                                            </Row>
                                                                            : renderQuestionContent(value, index, answerValue)
                                                                        }

                                                                        {/* <a className="question-comment" href=" "> */}
                                                                                                                                                  {value?.parentQuestionID === 0 &&
                                                                            <div className='question-comment opacity-100'>
                                                                                {value?.question?.toString()?.toLowerCase().trim() !== questionModalConstants?.questionNameforpurseslot ? (
                                                                                    <img
                                                                                        className="img-fluid"
                                                                                        src={EditIcon}
                                                                                        alt=""
                                                                                        onClick={() => {
                                                                                            EditItemRuleParentQuestions(value.questionId, groupNames?.catalogRule);
                                                                                        }}
                                                                                    />
                                                                                ) : null}
                                                                            </div>}
                                                                        {/*<img className="img-fluid" src={Comment} alt=" " />*/}
                                                                        {/* </a> */}
                                                                        <span className='question-number'>Catalog Item</span>
                                                                    </div>
                                                                    </div>
                                                                    );
                                                                }
                                                            })}
                                                            <a className='custom-link add-benefit text-decoration-none' onClick={() => { getCatalogOrItemDetails(groupNames.itemRule);effectiveDate(); setIsEdit(false); setAddItemRule(true); setGroupName(groupNames.catalogRule) }}>
                                                                <img className="img-fluid me-2" src={Plus} alt=" " />
                                                                <span>Add New Catalog Rule</span>
                                                            </a>
                                                        </div>
                                                    }
                                                    {
                                                        key?.toLowerCase() === groupNames.catalog?.toLowerCase() &&
                                                        <div className="benefit-title-section position-sticky" id={`section-${groupNames?.itemRule}`} >
                                                            <h4>{groupNames?.itemRule}</h4>

                                                        </div>
                                                    }
                                                    {
                                                        key?.toLowerCase() === groupNames.catalog?.toLowerCase() &&
                                                        <div className="benefit-content-section">
                                                            {((sheetWithQuestions && sortCatalogArray(sheetWithQuestions && sheetWithQuestions[groupNames?.itemRule])) || [])?.map((value: any, index: any) => {
                                                                const answerValue = benefitAnswers && benefitAnswers[value?.questionId]?.length > 0 ?
                                                                    benefitAnswers[value?.questionId]?.[0]?.answerValue : '';
                                                                if (!!answerValue) {
                                                                    return (<div className="question-main"><div className="question" key={index}>
                                                                        <h6>{value.question}</h6>
                                                                        {value?.questionType?.toString()?.toLowerCase() === "numeric" || value?.questionType?.toString()?.toLowerCase() === 'text' ||
                                                                            value?.questionType?.toString()?.toLowerCase() === "textbox" ||
                                                                            value?.questionType?.toString()?.toLowerCase() === '' || value?.questionType?.toString()?.toLowerCase() === "upload"
                                                                            || value?.questionType?.toString()?.toLowerCase() === "" ?
                                                                            <Row>
                                                                                <Col xs={7} xxl={6}>
                                                                                    <FloatingLabel label="">
                                                                                        <Form.Control type="text" readOnly placeholder=" " value={answerValue} />
                                                                                    </FloatingLabel>
                                                                                </Col>
                                                                            </Row>
                                                                            : renderQuestionContent(value, index, answerValue)
                                                                        }
                                                                        <span className="question-number">{value.sheetColumn}</span>
                                                                        {/* <a className="question-comment" href=" "> */}
                                                                            {value?.parentQuestionID == 0 && <div className='question-comment opacity-100'><img className="img-fluid edit-icon" src={EditIcon} alt=" " onClick={() => {effectiveDate();getCatalogOrItemDetails(groupNames.catalogRule); EditItemRuleParentQuestions(value.questionId, groupNames?.itemRule) }} /></div>}
                                                                        {/*<img className="img-fluid" src={Comment} alt=" " />*/}
                                                                        {/* </a> */}
                                                                        <span className='question-number'>Item Rule</span>
                                                                    </div></div>
                                                                    );
                                                                }
                                                            })}
                                                            <a className='custom-link add-benefit text-decoration-none' onClick={() => {getCatalogOrItemDetails(groupNames.catalogRule); effectiveDate(); setIsEdit(false); setAddItemRule(true); setGroupName(groupNames.itemRule) }}>
                                                                <img className="img-fluid me-2" src={Plus} alt=" " />
                                                                <span>Add New Item Rule</span>
                                                            </a>
                                                        </div>
                                                    }
                                                </div>)
                                            })}
                                        </Scrollbars>
                                    </div>
                                    <div className="right-configurations">
                                        <div className="sticky-nav hide-scroll">
                                            <ul>
                                                {props.navArry?.map((value: IGroupNames, index: number) => {

                                                    return (
                                                        <li className={index === currentNavIndex ? "active" : ''} style={{ cursor: 'pointer' }} key={value?.groupName} onClick={() => {

                                                            const sectionElement = document.getElementById(`section-${value?.groupName}`);
                                                            if (sectionElement) {
                                                                sectionElement.scrollIntoView({ behavior: "smooth" });
                                                                // Add an event listener to check if the scrolling animation is complete
                                                                const scrollCheckInterval = setInterval(() => {
                                                                    clearInterval(scrollCheckInterval); // Clear the interval
                                                                    setCurrentNavIndex(index);

                                                                }, 1000); // Check every 100 milliseconds
                                                            }
                                                        }}>
                                                            <span className="me-2">{value?.groupName}</span>
                                                            <img className="img-fluid" src={PrimaryTick} alt=" " />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="fields-timer d-none">
                                            <img className="img-fluid timer me-2" src={Timer} alt=" " />
                                            <span>6 fields left</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>)
                        :
                        (<Row>
                            <Col xs={3}>
                                <div className="no-results">
                                    <img className="img-fluid mb-3" src={NoBenefitResults} alt=" " />
                                    <h6>No benefits configured</h6>
                                    <p>Please add benefits to configure</p>
                                </div>
                            </Col>
                        </Row>)
                    )
                }
            </div>

            <AddBenefitModal
                addBenefitToCollection={addBenefitToCollection}
                AddBenefitsAtPlanLevel={AddBenefitsAtPlanLevel}
                showAddBenefit={showAddBenefit}
                benefitsDictionary={benefitsDictionary}
                commonBenefitsforCarrier={commonBenefitsforCarrier}
                handleActivityClose={handleActivityClose}
                benefitsDictionaryItems={BenefitsDictionary}
            />

            <Modal show={showAddCatalog} backdrop="static" keyboard={false} size="xl" centered className="add-catalog">
                <Modal.Header closeButton onClick={handleAddCatalogClose}>
                    <Modal.Title>Add New Catalog</Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-carrier">
                    <h5>Flex Benefit</h5>
                    <Row className="g-3">
                        <Col xs={5}>
                            <Button className="btn-outline">
                                <div className="input-box">
                                    <Form.Check aria-label="option 1" className="form-check" />Catalog1_Full_English_Paramount
                                </div>
                            </Button>
                        </Col>
                        <Col xs={5}>
                            <Button className="btn-outline">
                                <div className="input-box">
                                    <Form.Check aria-label="option 1" className="form-check" />Catalog2_AB_Spanish_Paramount
                                </div>
                            </Button>
                        </Col>
                        <Col xs={5}>
                            <Button className="btn-outline">
                                <div className="input-box">
                                    <Form.Check aria-label="option 1" className="form-check" />Catalog3_Full_Chinese_Paramount
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button className="btn-secondary" onClick={handleAddCatalogClose}>Add Catalog</Button>
                    <Button className="btn-outline-primary" onClick={handleAddCatalogClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            {finalQuestions && finalQuestions?.length>0 && <QuestionsModal
                addItemRule={addItemRule}
                setAddItemRule={setAddItemRule}
                finalQuestions={finalQuestions}
                inusuranceCarrierID={props.activeInsuranceCarrier?.insuranceCarrierId}
                insuranceHealthPlanID={props.planDetails?.insuranceHealthPlanID}
                benefitYear={props.activeInsuranceCarrier?.benefitYear}
                dataPlanId={dataPlanId}
                isEdit={isEdit}
                fetchSheetQuestionsAndBenefits={fetchSheetQuestionsAndBenefits}
                editData={editData}
                templateBenefitSheetId={props?.templateBenefitSheetId}
                effectiveStartDate={effectiveStartDate}
                effectiveEndDate={effectiveEndDate}
                getQuestionAndAnswers={getQuestionAndAnswers}
                groupName={groupName}
                approvedResponse={approvedResponse}
                setApprovedResponse={setApprovedResponse}
                itemOrCatalogData={itemOrCatalogData}
            />}
        </>
    )
}
