import React from 'react';
import { Carousel } from 'react-bootstrap';
import CarouselPrev from "../../../../images/prev.svg";
import CarouselNext from "../../../../images/next.svg";
import ReactHtmlParser from 'html-react-parser';

export const Login = (props: any) => {

    return (<>
        <div className='title-features'>
            <div className='features-left d-flex align-items-center'>
                <h2 title={props['constforlogin'][0]?.Heading}>{ReactHtmlParser(props['constforlogin'][0]?.Heading)}</h2>
                <span className='nations-tag'>Nations</span>
            </div>
            {/* <AppFeatureActivity /> */}
        </div>
        {
            props['constforlogin'][0]?.Question != undefined
                ?
                <>
                    <div className="site-question content-margin">
                        <h6>Question on Site Configuration Template</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.Question)}</p>
                    </div>
                </>
                : null
        }
        
        {
            props['constforlogin'][0]?.Purpose != undefined
                ?
                <>
                    <div className="purpose content-margin">
                        <h6>Purpose</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.Purpose)}</p>
                    </div>
                </>
                : null
        }
        {
            props['constforlogin'][0]?.DefaultFeatureBehavior != undefined
                ?
                <>
                    <div className="behavior content-margin">
                        <h6>Default Feature Behavior</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.DefaultFeatureBehavior)}</p>
                    </div>
                </>
                : null
        }

        {
            props['constforlogin'][0]?.AvailableLoginTemplates != undefined
                ?
                <>
                    <div className="login-template content-margin">
                        <h6>NationsBenefits available login templates</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.AvailableLoginTemplates)}</p>
                    </div>
                </>
                : null
        }
        {
            props['constforlogin'][0]?.OptIn != undefined
                ?
                <>
                    <div className="opt-in mb-4">
                        <h6>Opt-In</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.OptIn)}</p>
                    </div>
                </>
                : null
        }

        {
            props['constforlogin'][0]?.Steps != undefined
                ?
                <>
                    <div className="steps content-margin">
                        <h6>Steps</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.Steps)}</p>
                    </div>  
                </>
                : null
        }
        {
            props['constforlogin'][0]?.PreRequisites != undefined
                ?
                <>
                    <div className="requisites mb-4">
                        <h6>Pre-Requisites</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.PreRequisites)}</p>
                    </div>  
                </>
                : null
        }
        {
            props['constforlogin'][0]?.URLStructure != undefined
                ?
                <>
                    <div className="url-structure content-margin">
                        <h6>URL Structure</h6>
                        <p>{ReactHtmlParser(props['constforlogin'][0]?.URLStructure)}</p>
                    </div>
                </>
                : null
        }

        <a href="#" className='custom-link vedio-button' target='_blank'>
            <span>Watch an overview video (1.a)</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className='ms-2'>
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#646F7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 8L16 12L10 16V8Z" stroke="#646F7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </a>
        {
            props['constforlogin'][0]?.Imagepng1 != undefined && props['constforlogin'][0]?.Imagepng2 != undefined && props['constforlogin'][0]?.Imagepng3 != undefined
                ?
                <>
                    <Carousel className="custom-slider" indicators={true} prevIcon={<img className="img-fluid" src={CarouselPrev} alt=" " />} nextIcon={<img className="img-fluid" src={CarouselNext} alt=" " />}>
                        <Carousel.Item>
                            <img className="img-fluid slide-image" src={props['constforlogin'][0]?.Imagepng1} alt="Third slide" />
                            <Carousel.Caption>
                                <h6>{ReactHtmlParser(props['constforlogin'][0]?.NameOfImagePng1)}</h6>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="img-fluid slide-image" src={props['constforlogin'][0]?.Imagepng2} alt="Third slide" />
                            <Carousel.Caption>
                                <h6>{ReactHtmlParser(props['constforlogin'][0]?.NameOfImagePng2)}</h6>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="img-fluid slide-image" src={props['constforlogin'][0]?.Imagepng3} alt=" " />
                            <Carousel.Caption>
                                <h6>{ReactHtmlParser(props['constforlogin'][0]?.NameOfImagePng3)}</h6>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </> :
                props['constforlogin'][0]?.Imagepng1 != undefined && props['constforlogin'][0]?.Imagepng2 != undefined
                ?
                <>
                        <Carousel className="custom-slider" indicators={true} prevIcon={<img className="img-fluid" src={CarouselPrev} alt=" " />} nextIcon={<img className="img-fluid" src={CarouselNext} alt=" " />}>
                        <Carousel.Item>
                            <img className="img-fluid slide-image" src={props['constforlogin'][0]?.Imagepng1} alt=" " />
                            <Carousel.Caption>
                                <h6>{ReactHtmlParser(props['constforlogin'][0]?.NameOfImagePng1)}</h6>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="img-fluid slide-image" src={props['constforlogin'][0]?.Imagepng2} alt=" " />
                            <Carousel.Caption>
                                <h6>{ReactHtmlParser(props['constforlogin'][0]?.NameOfImagePng2)}</h6>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </>
                    : props['constforlogin'][0]?.Imagepng1 == undefined ? <> </> :
                    <div className='custom-slider'>
                    <img className="img-fluid slide-image" src={props['constforlogin'][0]?.Imagepng1} alt=" " />
                </div>
        }
    </>
    );
}